import React, {ReactNode, MouseEventHandler} from 'react'
import '../../assets/styles/Containers.css'

interface Props {
    className?: string | "flex flex-row items-center justify-center h-[80%] w-[80%] mt-10 space-x-2 bg-gray-50 rounded-2xl shadow-lg px-5"
    onClick?: MouseEventHandler<HTMLDivElement>
    onMouseEnter?: MouseEventHandler<HTMLDivElement>
    onMouseLeave?: MouseEventHandler<HTMLDivElement>
    children: ReactNode
}

const CustomCard = ({
    className,
    onClick,
    onMouseEnter,
    onMouseLeave,
    children
} : Props) => {
    return (
        <div className={className + " customCard"} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {children}
        </div>
    )
}

export default CustomCard
