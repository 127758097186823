import React, { useEffect } from 'react'
import back_arrow from '../assets/Back.png'
import { useNavigate } from 'react-router-dom'


interface Props {
    route : any
}

const BackButton = ({route} : Props) => {

  const navigate = useNavigate();

  const screenWidth = window.innerWidth
  const screenHeight = window.innerHeight;

  const marginTop = screenHeight <= 864 ? 'top-[11%]' : 'top-[9%]';
  const marginLeft = screenWidth >= 1025 ? 'ml-[3%]' : 'ml-[5%]'

  return (
    <div className={`absolute w-[80%] h-[5%] ${marginTop} ${marginLeft}`} >
          <img src={back_arrow} className='w-12 h-10 cursor-pointer' onClick={() => navigate(route)}/>
    </div>
  )
}

export default BackButton