import React, { useState } from 'react'
import backArrow from '../../assets/back-arrow.png'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { User } from '../../classes/model/User'
import { IChangePasswordResponse } from '../../classes/interface/IPassword'
import ChangePasswordModal from '../modals/ChangePasswordModal'
import OtpVerification from '../modals/OtpVerification'
import Password from '../password/Password'
import StandardContainer from '../container/StandardContainer'
import CustomCard from '../container/CustomCard'
import Spinner from '../spinner/Spinner'
import Index from '../modals/Index'
import useEnterKey from '../../classes/EnterFunction'

const ChangePassword = () => {

    const navigate = useNavigate()
    const user = new User()

    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')

    const [isShowOtpModal, setIsShowOtpModal] = useState(false)
    const [isShowModal, setIsShowModal] = useState(false)
    const [status, setStatus] = useState(false)
    const [message, setMessage] = useState<any>('')
    const [page, setPage] = useState('')
  

    const [isShowSpinner, setIsShowSpinner] = useState(false)


    const handleChangePasswordRequest = async () => {
        setIsShowSpinner(true)

        if(newPassword === confirmNewPassword){
          const response: any = await user.changePassword({
            old_password: currentPassword,
            password: newPassword,
            password_confirmation: confirmNewPassword,
          }, "updatePassword_endpoint")
  
          console.log("change password response", response)

          if (response.message === 'success') {
              setMessage(response?.data?.message)
              setStatus(response.success)
              setPage('/dashboard')
              setIsShowModal(true)
          } else {

            if(response.message.old_password[0] === "Invalid password"){
              setMessage("Current password is incorrect")
              setIsShowModal(true)
            }else{
              setMessage(response.message.password[0])
              setIsShowModal(true)
            }
          }
        }else{
          setMessage("The password and password confirmation must match.")
          setIsShowModal(true)

        }
        setIsShowSpinner(false)

    }

    useEnterKey(() => handleChangePasswordRequest());

  return (
    <StandardContainer className='flex flex-col items-center bg-gray-100 w-full'>

      <div className='flex flex-row items-center rounded-xl bg-blue-600 text-white w-[60%] space-x-5 mt-5'>
        <img src={backArrow} className='ml-5 cursor-pointer' onClick={() => navigate('/password')}/>
        <p className='text-xl font-semibold'>Change Password</p>
      </div>

      <CustomCard className='flex flex-col bg-white rounded-xl w-[60%] px-[3rem] h-[47%] mt-[3%]'>
        <div className='w-[100%] mt-5 mb-5'>
          <Password setCurrentPassword={setCurrentPassword} setNewPassword={setNewPassword} setConfirmNewPassword={setConfirmNewPassword} isEmail={false} setEmail='' onEnter={handleChangePasswordRequest}/>
        </div>

      {newPassword && confirmNewPassword ? 
          isShowSpinner ? <div className='flex items-center justify-center mb-2'><Spinner/></div> : 
          <button className='bg-blue-600 text-white h-10 w-[15%] rounded-xl hover:bg-blue-700 mb-[5%] ml-auto mr-auto' onClick={handleChangePasswordRequest}>Verify</button>
        : null
      }
        
      </CustomCard>

      {isShowModal ? <Index closeModal={() => setIsShowModal(false)} page={page} status={status} message={message} email=""/> : null}
    </StandardContainer>
  )
}

export default ChangePassword
