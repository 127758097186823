import { IOTP, IResendOTP } from "../../interface/IOTP";
import { IForgotPassword, IResetPassword, IChangePassword } from "../../interface/IPassword";
import { requests } from "../Api";
import { endpoints } from "../Endpoint";

const USER_UPDATE_PASSWORD_ENDPOINT = process.env.REACT_APP_RDP_API_USER_UPDATE_PASSWORD_ENDPOINT as string
const PASSWORD_CHANGE_ENDPOINT = process.env.REACT_APP_RDP_API_PASSWORD_CHANGE_ENDPOINT as string
const PASSWORD_RESET_ENDPOINT = process.env.REACT_APP_RDP_API_PASSWORD_RESET_ENDPOINT as string
const REACT_APP_RDP_API_RESEND_OTP_ENDPOINT = process.env.REACT_APP_RDP_API_RESEND_OTP_ENDPOINT as string

export class Password extends requests {
    //token = JSON.parse(localStorage.getItem("api_token") || '{}')

    async changePassword({ old_password, password, password_confirmation }: IChangePassword, request : any) {

        const endpoint = endpoints(request, "POST", true, null);

        const data: IChangePassword = {
            old_password: old_password,
            password: password,
            password_confirmation: password_confirmation
        }

        //let response = await this.postRequest(USER_UPDATE_PASSWORD_ENDPOINT, data, this.token);
        let response = await this.postRequest(endpoint, data, request);
        
        return response;
        /*if (response?.message == 'success') {
            return response
        } else {
            return false
        }*/
    }

    async resetPassword({ email, new_password, password_reset_code, new_password_confirmation }: IResetPassword, request : any) {

        const endpoint = endpoints(request, "POST", true, null);
        console.log('endpoint', endpoint)

        const data: IResetPassword = {
            email: email,
            new_password: new_password,
            password_reset_code: password_reset_code,
            new_password_confirmation: new_password_confirmation
        }

        console.log(data)

        //let response = await this.postRequest(PASSWORD_CHANGE_ENDPOINT, data, '');
        let response = await this.postRequest(endpoint, data, request);
        console.log('postrequest', response)

        return response;
    }

    async forgotPassword({ email, source }: IForgotPassword, request : any) {

        const endpoint = endpoints(request, "POST", true, null);

        const data: IForgotPassword = {
            email: email,
            source: source
        }

        //let response = await this.postRequest(PASSWORD_RESET_ENDPOINT, data, this.token)

        let response = await this.postRequest(endpoint, data, request)
        return response;
    }

    async validateOtp(otp : any, request : any): Promise<any> {
        console.log('validate otp endpoint')

        const endpoint = endpoints(request, "POST", true, null);

        const data: IOTP = {
            otp: otp,
            type : "web"
        }

        console.log('data: IOTP', data)

        //const response = await this.postRequest(REACT_APP_RDP_API_CODE_VALIDATION_ENDPOINT, data);
        const response = await this.postRequest(endpoint, data, request);
        return response
       
    }

    async resendOTP(request : any, action : any, isPasswordChange : boolean){

        console.log('request', request)

        const endpoint = endpoints(request, "POST", true, null);

        console.log('resendOTP', endpoint)

        const data : IResendOTP = {
            type : 'web',
            ...(isPasswordChange ? { action: action } : {}),
            
        }

        console.log("data", data)

        //let response = await this.postRequest(REACT_APP_RDP_API_RESEND_OTP_ENDPOINT, data, this.token)
        let response = await this.postRequest(endpoint, data, request)
        return response;

    }
}