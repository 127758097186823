import Pusher from 'pusher-js';

const APP_KEY = process.env.REACT_APP_PUSHER_APP_KEY
const APP_CLUSTER = process.env.REACT_APP_PUSHER_APP_CLUSTER

const pusher = new Pusher(`${APP_KEY}`, {
  cluster: `${APP_CLUSTER}`,
  forceTLS: true,
});

export default pusher;