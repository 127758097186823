import React, { useEffect, useState } from 'react'
import CustomButton from '../buttons/CustomButton'

import { Case } from '../../classes/model/Case';
import AvailableTime from './AvailableTime';
import { User } from '../../classes/model/User';
import moment from 'moment';
import { LinearPath } from '../../classes/requests/symptom_checker/LinearPath'
import AppointmentCalendar from './AppointmentCalendar';
import { getParsedDataFromLocalStorage } from '../../classes/DataParser';
import CallbackRequestModal from '../modals/CallbackRequestModal';
import Index from '../modals/Index';
import Spinner from '../spinner/Spinner';
import ProgressBar from './ProgressBar';
import back_arrow from '../../assets/Back.png'

import StandardContainer from '../container/StandardContainer'
import CustomCard from '../container/CustomCard'
import ImageVariables from '../../global/ImageVariables'
import { Link, useNavigate } from 'react-router-dom';
import BackButton from '../BackButton';
import { deleteAllFiles, getAllFiles } from './db';

const SetAppointment = () => {
  const navigate = useNavigate();
  const localCallDate = getParsedDataFromLocalStorage('callbackRequest_callDate')
  const localCallTime = getParsedDataFromLocalStorage('callbackRequest_callTime')

  const [availableDays, setAvailableDays] = useState<any[]>([])
  const [slot, setSlot] = useState<any>([])
  const [selectedDate, setSelectedDate] = useState(localCallDate || '')
  const [selectedTime, setSelectedTime] = useState<any>(localCallTime || '')

  const [minimumDate, setMinimumDate] = useState<any>()
  const [maximumDate, setMaximumDate] = useState<any>()
  //const [files, setFiles] = useState<File[]>([])
  const [files, setFiles] = useState<any[]>([])
  const [dbFiles, setDbFiles] = useState<any[]>([])
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showSuccessModal, setShowSuccesModal] = useState(false)
  const [message, setMessage] = useState<any>('')
  const [page, setPage] = useState('')
  const [showSpinner, setShowSpinner] = useState(false)
  const [coc, setCoc] = useState(false)

  const caseRequest = new Case()
  const verificationRequest = new User();

  const user = JSON.parse(localStorage.getItem('user') || '{}')

  const concern = JSON.parse(localStorage.getItem('callbackRequest_concern') || '{}')
  const method = JSON.parse(localStorage.getItem('callbackRequest_type') || '{}')
  const loa : any = localStorage.getItem('loa');
  const hasRefreshed  =  JSON.parse(localStorage.getItem('hasRefreshed') || 'false')

  useEffect(() => {

    //retrieveImages();
    //retrieveLocalFiles();

    const fetchFiles = async () => {
      const files = await getAllFiles();
      console.log("db files", files)
      setDbFiles(files);
    };


    const getAvailableSlot = async () => {

      const days: any = await caseRequest.getSlot('getDays_Slot_endpoint')
      setAvailableDays(days)

      let counter = 0

      {Object.keys(days).map((item : any) => {

        if(counter === 0){

          const mindate = new Date(days[item][0].value)
          const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
          const newMinimumDate = new Date(mindate.getTime() - oneDayInMilliseconds);

          setMinimumDate(newMinimumDate)
          counter++
        }else{

          const maxdate = days[item][days[item].length -1]
          setMaximumDate(new Date(maxdate.value))
        }
      })}
    }

    getAvailableSlot();
    fetchFiles();

  }, [])


  const retrieveImages = async () => {

    const blobUrls = getParsedDataFromLocalStorage<any>('localPreviews')

    const urlList: string[] = [];
    const filenames : any[] = [] ;

    blobUrls?.map((item : any) => {


        if(Array.isArray(item)){
          item.map((url) => {
            urlList.push(url.previewUrl)
            filenames.push(url.fileName)
          })
        }else{
          urlList.push(item.previewUrl)
          filenames.push(item.fileName)
        }
    })

    const base64StringsList = await convertBlobUrlToBase64(urlList);
    const imageFiles = base64StringsList?.map((image : any, index : any) => {
      
      const blob = base64ToBlob(image);
      return new File([blob], filenames[index], { type: blob.type });
    });


    setFiles(imageFiles);

  }
    

    const convertBlobUrlToBase64 = async (blobUrls: (string | string[])[]): Promise<string[]> => {

      // Flatten the array to handle nested arrays
      const flattenedUrls = blobUrls.flat();


      // Validate that all elements are strings
      const validUrls = flattenedUrls.filter((url): url is string => typeof url === 'string');
    
      const base64Strings: string[] = await Promise.all(
        validUrls.map(async (url) => {
          try {
            // Fetch the Blob from the URL
            const response = await fetch(url);
            const blob = await response.blob();
    
            // Convert Blob to Base64
            const base64String = await new Promise<string>((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result as string);
              reader.onerror = () => reject(new Error('Failed to convert Blob to Base64'));
              reader.readAsDataURL(blob);
            });

            return base64String;
          } catch (error) {
            console.error(`Error processing Blob URL ${url}:`, error);
            return ''; 
          }
        })
      );
    
      return base64Strings;
    };

 
  const base64ToBlob = (base64: string, contentType = 'image/png') => {

      const byteCharacters = atob(base64.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: contentType });
  };


  const handleVerificationRequest = async () => {

    if(user.insurance_provider == "") {
         getInsuranceDetails()
         const user = JSON.parse(localStorage.getItem('user') || '{}')
    }

    const response: any = await verificationRequest.validateProvider({
      provider: user.insurance_provider,
      member_id: user.insurance_number,
      user: user
    }, 'validateProvider_endpoint')


    return response
  }

  const getInsuranceDetails = async () => {
      var user = JSON.parse(localStorage.getItem('user') || '{}')

      const response: any = await verificationRequest.getUserDetails(user.id, 'getUserDetails_endpoint')

      if(user != null) {

          if(user.insurance_number == "") {
             user.insurance_number = response.user.insurance_number
          }

          if(user.insurance_provider == "") {
             user.insurance_provider = response.user.insurance_provider
          }

          localStorage.setItem("user", JSON.stringify(user))
      }

  }

  const setFormData = (loa : string) => {

    const formData = new FormData();

    const dateTimeFormat = moment(
      `${selectedDate} ${selectedTime}:00:00`
    ).format('YYYY-MM-DD HH:mm:ss')

    let session_id = localStorage.getItem('sessionId') ? localStorage.getItem('sessionId') : ""

    const data : any = {
      case_origin: "web-app",
      health_concern: concern,
      name_of_company: user.patient.company_name,
      patient_id: user.patient.id,
      patient_insurance_model: user.insurance_provider,
      preferred_datetime: dateTimeFormat,
      preferred_method: method,
      provider_name: user.insurance_provider,
      source: "web-app",
      coc: coc ? 1 : 0,
      loa : loa,
      session_id: session_id
    }

    
    dbFiles.forEach((file) => {
      formData.append('file[]', file.fileData);
    });
    

    Object.keys(data).forEach( (key : any) => {
      formData.append(key, data[key]);
    })

    return formData;

  }


  const SetCallbackRequest = async () => {
    let verifiedLoa = "";
    let data;

    setShowSpinner(true)

    if(!validateFields()){

        if(loa === undefined || loa === ""){

          const verificationResponse = await handleVerificationRequest()

          if(verificationResponse.message !== "success" ){
            setMessage(verificationResponse.message)
            setShowErrorModal(true)
            return;
          }

          verifiedLoa = verificationResponse.data.details.loa
        }

        if(verifiedLoa !== ""){
         data = setFormData(verifiedLoa);
        }else{
          data = setFormData(loa);
        }

        const response = await caseRequest.setAppointment(data, "setAppointment_endpoint")

        if(response.message === 'success'){
          await deleteAllFiles();
          let responseData = response.data
          if(Object.hasOwn(responseData, 'consultations') && localStorage.getItem('sessionId') != null) {
            const linearPathRequest = new LinearPath()
            const symptomCheckerResponse = await linearPathRequest.updateCaseHistory({
                session_id: localStorage.getItem('sessionId'),
                'case_consultation_id': responseData.consultations[0].id
            }, "update_case_history_endpoint")
            localStorage.setItem("sessionId", "")
          }
          setShowSuccesModal(true)
        }else{
          setPage('/dashboard')
          setMessage(response.message.message)
          setShowErrorModal(true)
        }
    }else{
      setMessage('Please select preffered date and time')
      setShowErrorModal(true)
    }
    setShowSpinner(false)

  }

  const validateFields = () : boolean => {
    return selectedTime.trim() === '';
  };



  return (
      <StandardContainer>
        
      <BackButton route='/concern'/>

      <div className='flex flex-col items-center justify-center w-full'>
        <p className='text-4xl text-blue-500 font-bold w-[80%]'>Schedule Your Appointment</p>

        <CustomCard className={`flex flex-row px-5 py-[3%] items-center justify-center w-[80%] lg:w-[80%] md:w-3/4 mt-10 space-x-2 bg-gray-50 rounded-2xl shadow-md`}>

          <div className='flex flex-col justify-center items-center mt-5 h-full w-full'>

          <div className='flex w-[80%] mx-auto items-center'>
              <ProgressBar stage={3} request="" coc={false}/>
            </div>

          <div className='flex flex-col items-center justify-center space-x-7 w-full'>

            <p className='text-2xl font-semibold text-blue-500'>Choose your preferred appointment date and time</p>


            <div className='flex flex-row justify-center items-center mt-5 w-[60%]'>

              <div className='flex flex-1 justify-center ml-3 mr-3 max-w-[20rem]'>
                <AppointmentCalendar setSlot={setSlot} setSelectedDate={setSelectedDate} minimumDate={minimumDate} maximumDate={maximumDate} selectedDate={selectedDate}/>
              </div>

              <div className='flex flex-1 justify-center mr-0 lg:mr-10 lg:w-full md:mr-0 ml-1 max-w-[20rem]'>
                <AvailableTime time={slot} selectedTime={selectedTime} setSelectedTime={setSelectedTime} selectedDate={selectedDate}/>
              </div>

            </div>

            <div className='flex flex-row items-center justify-center space-x-2 mt-5'>
              <input type='checkbox' className='h-5 w-5' onClick={(event : any) => setCoc(event.target.checked)} onKeyDown={(e) => { if (e.key === "Enter") { SetCallbackRequest() } }}/>
              <p className='text-blue-500 text-base' >I agree to Medgate's </p> <Link
                className='text-blue-500 hover:border-b hover:border-blue-500'
                to={'https://medgate.ph/medgate-code-of-conduct/'}>
                Patient Code of Conduct.
              </Link>
            </div>

          </div>

          { coc  ?

          showSpinner ? <Spinner /> :
          <div className='flex flex-row items-center justify-center space-x-5 mt-10'>
            <button className='rounded-full dark-blue-bg h-12 w-40 text-white font-semibold hover:bg-blue-600' onClick={SetCallbackRequest}>Set Schedule</button>
          </div>  : null }
        </div>
      </CustomCard>
      { showSuccessModal ?  <CallbackRequestModal name={user?.patient.first_name} closeModal={() => setShowSuccesModal(false)} page='/dashboard' callbackType={method} callDate={selectedDate} callTime={selectedTime}/> : null}
      { showErrorModal ? <Index message={message} status={undefined} closeModal={() => setShowErrorModal(false)} page={page} email=''/> : null}
    </div>
    </StandardContainer>
  )
}

export default SetAppointment
