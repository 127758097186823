import React, { useEffect, useState, MouseEventHandler, useRef } from 'react'
import { OtherRequest } from '../../classes/model/OtherRequest'
import CustomCard from '../container/CustomCard'

interface Props {

  hotlines : any
  closeModal: () => void
  onMouseEnter?: MouseEventHandler<HTMLDivElement>
}

const Hotline = ({hotlines, closeModal, onMouseEnter} : Props) => {

  const modalRef = useRef<HTMLDivElement>(null);

    // Close modal if a click happens outside the modal
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        closeModal();
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);


  return (
    <div className='flex ml-[65%] mt-[5%] absolute inset-0 overflow-y-auto h-[90%] w-[55%]' onMouseEnter={onMouseEnter} onMouseLeave={closeModal}>
    <div className='flex items-center px-4 py-8 w-[40%]' onMouseLeave={closeModal}>
      <CustomCard className='relative w-full max-w-lg p-4 bg-white rounded-md shadow-lg shadow-gray-700' key="hotlines-bg" onMouseLeave={closeModal}>
        <div className='flex flex-col justify-center mb-3' onMouseEnter={onMouseEnter} onMouseLeave={closeModal} key="hotlines-modal">
            {/*<p className='ml-auto mr-[5%] pl-2 cursor-pointer text-gray-200 bg-gray-400 rounded-full w-6 h-6' onClick={closeModal}>x</p>*/}
            {hotlines?.landlines?.map((item : any) => (
              <div key={"hotlines-container" + item.label} className='flex flex-row lg:flex-row md:flex-col sm:flex-col ml-[10%] items-start text-blue-600 space-y-3'>
                <p key={"hotlines-p" + item.label} className='flex flex-1 font-semibold text-lg mt-3'>{item.label}:</p>
                {item.numbers.map((number : any) => (
                    <div key={"hotlines-a-container" + number} className='flex-1 text-lg'>
                        <a key={"hotlines-a" + number} className='border-b border-blue-500 text-lg' href={`tel:+63${number.slice(1)}`} >{number}</a>
                    </div>
                ))}
              </div>
            ))}

          {hotlines?.mobile?.map((item : any) => (
              <div key={"hotlines-container" + item.label} className='flex flex-row lg:flex-row md:flex-col sm:flex-col ml-[10%] items-start text-blue-600 space-y-3'>
                <p key={"hotlines-p2" + item.label} className='flex flex-1 font-semibold text-lg mt-3'>{item.label}:</p>
                <div key={"hotlines-container2" + item.label} className='flex flex-col flex-1'>
                  {item.numbers.map((number : any) => (
                      <div key={"hotlines-a-container" + number} className='flex-1 text-lg'>
                        <a key={"hotlines-a" + number} className='border-b border-blue-500 text-lg' href={`tel:+63${number.slice(1)}`} >{number}</a>
                      </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </CustomCard>
      </div>
    </div>
  )
}

export default Hotline
