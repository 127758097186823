// Validation for casing, number, length, and special characters
export const validate = {
    min: (min: number, value: number): boolean => value >= min,

    hasLowerCase(value: string): boolean {
        let lowerCasePattern = new RegExp("^(?=.*[a-z]).+$")
        return lowerCasePattern.test(value)
    },

    hasUpperCase(value: string): boolean {
        let upperCasePattern = new RegExp("^(?=.*[A-Z]).+$");
        return upperCasePattern.test(value)
    },

    hasNumber(value: string): boolean {
        let numberPattern = new RegExp("^(?=.*[\\d]).+$");
        return numberPattern.test(value)
    },

    hasSpecialChar(value: string): boolean {
        let specialCharPattern = new RegExp("^(?=.*[-+_!@#$%^&*.,?]).+$");
        return specialCharPattern.test(value)
    }
}