import React, { MouseEventHandler, useEffect, useState } from 'react'
import SCButton from './SCButton'
import SCQuestionItem from './SCQuestionItem'

interface Props {
    language: string,
    data: any[],
    onSelect: (
        index: number,
        question: string,
        answer: string,
        isTerminator: number
    ) => void
}

const SCQuestionnaire = ({ language,
    data,
    onSelect
    } : Props) => {

    return (

        <div className="flex flex-col items-center justify-center">
            {
                data.map((item, index) => {
                    let currentQuestion = {
                        id: 0,
                        min_value: 0,
                        max_value: 10
                    }

                    if(item.questions != undefined) {
                        item.questions.forEach(function(question: any) {
                            if(question.language.abbreviation == language) {

                                currentQuestion = question
                            }
                        })
                    }

                    // if (Obdcject.keys(currentQuestion).length == 0 || currentQuestion != null) {
                    if(item.inquiry_type_id == 6) {
                        if (currentQuestion.id != 0) {
                            return <SCQuestionItem index={index} data={currentQuestion}
                            maxValue={currentQuestion.max_value} minValue={currentQuestion.min_value}
                            onSelect={onSelect} key={"question" + currentQuestion.id} inquiryType={item.inquiry_type_id}/>
                        }
                    } else {
                        if (currentQuestion.id != 0) {
                            return <SCQuestionItem index={index} data={currentQuestion}
                            onSelect={onSelect} key={"question" + currentQuestion.id} inquiryType={item.inquiry_type_id}/>
                        }
                    }
                })
            }
        </div>
    )
}

export default SCQuestionnaire
