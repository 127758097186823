import { IChat, IChatSlug } from "../interface/IChat";
import { IEmailRegistrationRequest, IEmailRequest } from "../interface/IEmail";
import { ILoginRequest } from "../interface/ILogin";
import { IChangePassword, IForgotPassword, IResetPassword } from "../interface/IPassword";
import { IPhoneNumberRequest } from "../interface/IPhoneNumber";
import { IProviders } from "../interface/IProviders";
import { Chat } from "../requests/user/Chat";
import { ContactNumber } from "../requests/user/ContactNumber";
import { Email } from "../requests/user/Email";
import { Login } from "../requests/user/Login";
import { Password } from "../requests/user/Password";
import { Providers } from "../requests/user/Providers";
import { Registration } from "../requests/user/Registration";
import { Voip } from "../requests/case/Voip";
import { Details } from "../requests/user/Details";
import { IVoipCallAnswered, IVoipTokenRequest } from "../interface/IVoip";
import { CodeValidation } from "../requests/user/CodeValidation";
import { ICodeRequest } from "../interface/ICode";
import { ISocialLogin } from "../interface/ISocialLogin";

export class User {
    private request: any;

    constructor() {
        this.request = null;
    }

    async login({ username, password, device_token, device_type, type }: ILoginRequest, request : any): Promise<any> {
        this.request = new Login();
        return await this.request.login({ username, password, device_token, device_type, type }, request);
    }

    async socialLogin( {device_token, device_type, token, login_type}: ISocialLogin ): Promise<any> {
        this.request = new Login();
        return await this.request.loginSocial( {device_token, device_type, token, login_type}, "social_login" );
    }

    async logout( device_token: any ): Promise<any> {
        this.request = new Login();
        return await this.request.logout(device_token, "log_out");
    }

    async userRegistration({ username,
        password_confirm,
        mobile_number,
        user_type, //patient
        email,
        password,
        is_patient,
        device_type, //android
        device_token, //testdevicetoken101
        coc }: IEmailRegistrationRequest, request : any): Promise<any> {
        this.request = new Registration();
        return await this.request.userRegistration({
            username,
            password_confirm,
            mobile_number,
            user_type, //patient
            email,
            password,
            is_patient,
            device_type, //android
            device_token, //testdevicetoken101
            coc
        }, request);
    }

    async updateEmail({ alternate_email, email, email_options }: IEmailRequest, request : any): Promise<any> {
        this.request = new Email();
        return await this.request.updateEmail({ alternate_email, email, email_options }, request)
    }

    async updateContactNumber({ mobile_number, phonenumber_options }: IPhoneNumberRequest, request : any): Promise<any> {
        this.request = new ContactNumber();
        return await this.request.updateContactNumber({ mobile_number, phonenumber_options }, request);
    }

    async changePassword({ old_password, password, password_confirmation }: IChangePassword, request : any): Promise<any> {
        this.request = new Password();
        return await this.request.changePassword({ old_password, password, password_confirmation }, request);
    }

    async otpValidation(otp: string, request : any) {
        console.log('parameter', otp)

        this.request = new Password();
        return await this.request.validateOtp(otp, request);
    }

    async resendOTP(request : any, action : any, isPasswordChange : boolean): Promise<any> {
        this.request = new Password();
        return await this.request.resendOTP(request, action, isPasswordChange);
    }

    async resetPassword({ email,
        new_password,
        password_reset_code,
        new_password_confirmation }: IResetPassword, request : any) {

        this.request = new Password();
        return await this.request.resetPassword({ email, new_password, password_reset_code, new_password_confirmation }, request)
    }

    async forgotPassword({ email, source }: IForgotPassword, request : any): Promise<any> {

        console.log(request)
        this.request = new Password();
        return await this.request.forgotPassword({ email, source }, request);
    }

    async codeValidation(code: string, request : any) {
        console.log('parameter', code)

        this.request = new CodeValidation();
        return await this.request.validateCode(code, request);
    }

    async getProviders(request : any) {
        this.request = new Providers();
        return await this.request.getProviders(request);
    }

    async validateProvider({ provider, member_id, user }: IProviders, request : any): Promise<any> {
        this.request = new Providers();
        return await this.request.validateProvider({ provider, member_id, user }, request);
    }

    async mergePatientDetails ( guid: string, request: any ): Promise<any> {
        this.request = new Providers();
        return await this.request.mergePatientDetails(guid, request);
    }

    async getUserDetails(userId: any, request : any): Promise<any> {
        this.request = new Details();
        console.log("TestUser", userId)
        return await this.request.getUserDetails(userId, request);
    }

    async getChatRoom(request : any): Promise<any> {
        this.request = new Chat();
        return await this.request.getChatRoom(request);
    }

    async createChatRoom(request : any): Promise<any> {
        this.request = new Chat();
        return await this.request.createChatroom(request);
    }

    async getChatHistory({ slug }: IChatSlug, request : any): Promise<any> {
        this.request = new Chat();
        return await this.request.getChatHistory({ slug }, request);
    }

    async sendMessage({ message, chat_room_slug }: IChat, request : any): Promise<any> {
        this.request = new Chat();
        return await this.request.sendMessage({ message, chat_room_slug }, request);
    }

    async getAgoraToken({ channel_name }: IVoipTokenRequest): Promise<any> {
        this.request = new Voip()
        return await this.request.getAgoraToken({ channel_name })
    }

    async callAnswered({ channel_name }: IVoipCallAnswered): Promise<any> {
        this.request = new Voip()
        return await this.request.callAnswered({ channel_name })
    }

    async callEnded({channel_name}: IVoipCallAnswered): Promise<any> {
        this.request = new Voip()
        return await this.request.callEnded({ channel_name })
    }

    async getParticipants({channel_name}: any): Promise<any> {
        this.request = new Voip()
        return await this.request.participants({channel_name});
    }
}
