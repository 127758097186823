import React, { useEffect, useState, useCallback, useRef } from 'react'
import access from '../assets/Features/1.png'
import telephone from '../assets/Features/2.png'
import prescription from '../assets/Features/3.png'
import guidelines from '../assets/Features/4.png'
import imageRequest from '../assets/Features/5.png'
import callback from '../assets/Features/6.png'
import triage from '../assets/Features/7.png'
import generalIcon from '../assets/Features/8.png'
import laboratory from '../assets/Features/9.png'
import downArrow from '../assets/arrow_down.png'
import CollapsibleContainer from '../components/container/CollapsibleContainer'
import IndexCollapsibleContainer from './container/IndexCollapsibleContainer'

const Collapsible = () => {

  const [isCollapsed, setIsCollapsed] = useState(false)

  useEffect(() => {
    // tabletResize()
    // window.addEventListener('resize', handleResize);
    // return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getSize = () => {
      return {
        width: window.innerWidth,
        height: window.innerHeight,
      };
  };

  const tabletResize = () => {
      var currentSize = getSize()
      console.log(currentSize.width)
      if(currentSize.width <= 1024 && currentSize.width >= 481) {
          setIsCollapsed(true)
      }
  }

  const isTablet = () => {
      var currentSize = getSize()
      console.log(currentSize.width)
      if(currentSize.width <= 1024 && currentSize.width >= 481) {
          return true
      } else {
          return false
      }
  }

  const handleResize = useCallback(() => {
    let ticking = false;
    if (!ticking) {
      window.requestAnimationFrame(() => {
        if(isTablet()) setIsCollapsed(true)
        ticking = false;
      });
      ticking = true;
    }
  }, []);

  const handleCollapseRequest = () => {

    // if(!isTablet()) {
    //     setIsCollapsed(!isCollapsed)
    // }
  }

  const bottomRef = useRef<HTMLDivElement | null>(null);

  const handleButtonClick = () => {
    setIsCollapsed(!isCollapsed)
    setTimeout(() => {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };


  return (
    <IndexCollapsibleContainer className='flex flex-col w-full border overflow-y-auto'>
      <div className="z-[10] w-full">
      <div ref={bottomRef} onClick={handleButtonClick} className='flex items-center text-white text-xl h-16 bg-[#3463CC] w-full cursor-pointer '>
        <img src={downArrow} className={`ml-[20%] w-10 ${isCollapsed ? 'rotate-180' : null} `} />
        <p className='ml-5'>
            Learn what our app has to offer!
        </p>
      </div>
      {
        isCollapsed ?
        <div className='flex flex-col items-center justify-center transition duration-1000 ease-in-out mb-5'>
          <p className='text-[3.5rem] text-[#3F7DDB] font-semibold mt-3 mb-3'>Medgate App Features</p>

          <div className='w-[90%] lg:w-[70%] md:w-[90%] grid grid-cols-5 gap-4 mt-3 mb-2 h-[35%] md:grid-cols-5 lg:grid-cols-5 sm:grid-cols-1'>

              {/**Top part */}
            <div className='grid-design min-w-[6rem] min-h-[12rem] justify-center'>
              <img src={access} className='w-10 h-16 mt-3'/>
              <p className='mx-3 mt-3 text-center'>Access to licensed Filipino doctors</p>
            </div>

            <div className='grid-design min-w-[6rem] min-h-[12rem] justify-center'>
              <img src={telephone} className='w-10 h-16 mt-3'/>
              <p className='mx-3 mt-3 text-center'>24/7 telephone lines with multiple carriers and contact points.</p>
            </div>

            <div className='grid-design min-w-[6rem] min-h-[12rem] justify-center'>
              <img src={prescription} className='w-14 h-16 mt-3'/>
              <p className='mx-3 mt-3 mb-3 text-center'>Provide e-prescriptions to eligible patients.</p>
            </div>

            <div className='grid-design min-w-[6rem] min-h-[12rem] justify-center'>
              <img src={guidelines} className='w-10 h-15 mt-3'/>
              <p className='mx-3 mt-3 text-center'>International guidelines and protocols followed by our specialist doctors.</p>
            </div>

            <div className='grid-design min-w-[6rem] min-h-[12rem] justify-center'>
              <img src={imageRequest} className='w-[3rem] h-[2.5rem] mt-3'/>
              <p className='mx-3 mt-3 mb-3 text-center'>Ability for the patient to send images and conduct a video call to facilitate diagnoses and treatment.</p>
            </div>
          </div>

            {/**Bottom part */}
          <div className='w-[90%] lg:w-[70%] md:w-[90%] grid grid-cols-5 md:grid-cols-5 gap-4 justify-center ml-auto md:ml-[18%] sm:ml-[0] mr-[5%] md:mr-[0%] lg:mr-[5%] sm:mr-0 mt-3 h-[35%] sm:grid-cols-1'>

                <div className='grid-design min-w-[6rem] min-h-[12rem] justify-center'>
              <img src={callback} className='w-14 h-16 mt-3'/>
              <p className='mx-3 mt-3 mb-3 text-center'>Callback options for patients who wishes to schedule their consultation with a doctor.</p>
            </div>

            <div className='grid-design min-w-[6rem] min-h-[12rem] justify-center'>
              <img src={triage} className='w-10 h-16 mt-3'/>
              <p className='mx-3 mt-3 text-center'>Triage conducted by Medgate Telemedical Assistants.</p>
            </div>

            <div className='grid-design min-w-[6rem] min-h-[12rem] justify-center'>
              <img src={generalIcon} className='w-14 h-16 mt-3'/>
              <p className='mx-3 mt-3 text-center'>Provide general referrals to patients as needed.</p>
            </div>

            <div className='grid-design min-w-[6rem] min-h-[12rem] justify-center'>
            <img src={laboratory} className='w-11 h-16 mt-3'/>
              <p className='mx-3 mt-3 text-center'>Interpret laboratory results of patients.</p>
            </div>

          </div>
        </div>  : null
      }
      </div>
    </IndexCollapsibleContainer>
  )
}

export default Collapsible
