import React, { useEffect, useState } from 'react'
import CustomButton from '../buttons/CustomButton'
import audio_icon from '../../assets/Audio-blue.png'
import video_icon from '../../assets/Video-blue.png'

import audio_white from '../../assets/Audio.png'
import video_white from '../../assets/Video.png'
import back_arrow from '../../assets/Back.png'

import { useNavigate } from 'react-router-dom'
import SymptomCheckerVariables from '../../global/SymptomChecker'
import ProgressBar from './ProgressBar'
import Index from '../modals/Index'

import StandardContainer from '../container/StandardContainer'
import CustomCard from '../container/CustomCard'
import { getParsedDataFromLocalStorage } from '../../classes/DataParser'
import BackButton from '../BackButton'

const Type = () => {

  const navigate = useNavigate();

  const type : any = getParsedDataFromLocalStorage('callbackRequest_type');
  const localPreviews : any = getParsedDataFromLocalStorage('localPreviews');
  const localFiles : any = getParsedDataFromLocalStorage('localFiles');

 /*console.log("localPreviews", localPreviews)
  console.log("localFiles", localFiles)*/


  const [requestType, setRequestType] = useState(type || '')
  const [isAudioButtonActive, setIsAudioButtonActive] = useState(false)
  const [isVideoButtonActive, setIsVideoButtonActive] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [message, setMessage] = useState<any>('')

  //const [isPageRefreshed, setIsPageRefreshed] = useState(false);



  useEffect(() => {
    if(type === 'audio'){
        setIsAudioButtonActive(true)
    }
    if(type === 'video'){
        setIsVideoButtonActive(true)
    }
  }, [])


  const handleCallbackRequest = () => {

    if(!validateField()){
      localStorage.setItem("callbackRequest_type", JSON.stringify(requestType))
      navigate('/concern')
    }

      setMessage('Method is required.')
      setShowModal(true)
  }

  const validateField = (): boolean => {
    return requestType.trim() === '';
  };

  const handleTypeRequest = (isAudio : boolean, request : any) => {

      if(isAudio){
        setIsAudioButtonActive(true);
        setIsVideoButtonActive(false);
      }else{
        setIsAudioButtonActive(false);
        setIsVideoButtonActive(true);
      }

      setRequestType(request)
      localStorage.setItem("callbackRequest_type", JSON.stringify(request))

  }


  return (
    <StandardContainer>

    <BackButton route="/dashboard"/>

    <div className='flex flex-col items-center w-full'>

      <p className='text-4xl text-blue-500 font-bold w-[80%]'>Schedule Your Appointment</p>

        <CustomCard className='flex flex-col w-[80%] mt-10 bg-gray-50 rounded-2xl shadow-md py-[3%]'>

            <div className='flex flex-col justify-center items-center mt-5 h-full w-full'>
                  <div className='flex w-[80%] mx-auto items-center'>
                    <ProgressBar stage={1} request="" coc={false}/>
                  </div>

          <div className='flex flex-row justify-center space-x-7 w-full mt-3'>
              <div className={`flex flex-col items-center justify-center h-40 w-40 rounded-xl shadow-md cursor-pointer ${isAudioButtonActive ? 'bg-blue-600 text-white' : 'border border-gray-200 text-blue-600'}`} onClick={() => handleTypeRequest(true,'audio')}>
                  {isAudioButtonActive ? <img src={audio_white} className='h-20 w-20 mb-3'/> : <img src={audio_icon} className='h-20 w-20 mb-3'/>}
                  <p className={`text-lg font-semibold ${isAudioButtonActive ? 'text-white' : 'text-blue-600'}`}>Audio</p>
              </div>

              <div className={`flex flex-col items-center justify-center h-40 w-40 rounded-xl shadow-md cursor-pointer ${isVideoButtonActive ? ' bg-blue-600  text-white' : 'border border-gray-200 text-blue-600'}`} onClick={() => handleTypeRequest(false, 'video')}>
                      {isVideoButtonActive ? <img src={video_white} className='h-20 w-20 mb-3'/> : <img src={video_icon} className='h-20 w-20 mb-3'/>}
                      <p className={`text-lg font-semibold ${isVideoButtonActive ? 'text-white' : 'text-blue-600'}`}>Video</p>
                </div>
            </div>

            <div className='flex flex-row items-center justify-center space-x-5 mt-10'>
              <CustomButton title='Next' style='rounded-full dark-blue-bg h-12 w-40 text-white font-semibold hover:bg-blue-600' handleOnclick={handleCallbackRequest} isDisabled={false}/>
            </div>
          </div>
        </CustomCard>
        {showModal ? <Index message={message} status={undefined} closeModal={() => setShowModal(false)} page='' email=''/>  : null}
    </div>
    </StandardContainer>
  )
}

export default Type
