import React, { useEffect, useRef, useState } from 'react'
import SecurityBlue from '../../assets/Security- blue.png'
import { User } from '../../classes/model/User'
import { useNavigate } from 'react-router-dom'
import Spinner from '../spinner/Spinner'

interface Props {
  guid: string,
  onCancel: () => void
}

const VerificationConflict = ({ guid, onCancel }: Props) => {

  const [process, setProcess] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const userRequest = new User()
  const navigate = useNavigate();

  const modalRef = useRef<HTMLDivElement>(null);


  const confirmReplace = async () => {
    setProcess('merging')
    const response: any = await userRequest.mergePatientDetails(guid, 'mergePatientDetails_enpoint')
    if (response.message == 'success') {
      setProcess('success')
      getInsuranceDetails()
    } else {
      setProcess('error')
      setErrorMessage(response.message);
    }
  }

  const getInsuranceDetails = async () => {
      var user = JSON.parse(localStorage.getItem('user') || '{}')

      const response: any = await userRequest.getUserDetails(user.id, 'getUserDetails_endpoint')

      if(user != null) {

          if(user.insurance_number == "") {
             user.insurance_number = response.user.insurance_number
          }

          if(user.insurance_provider == "") {
             user.insurance_provider = response.user.insurance_provider
          }

          localStorage.setItem("user", JSON.stringify(user))
      }

  }

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onCancel();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='flex items-center pl-[35%] fixed inset-0 z-10 overflow-y-auto bg-gray-600 bg-opacity-70 h-full w-full'>
      <div className='flex items-center justify-center px-4 py-8 w-2/3 h-1/3'>
        <div className='w-full max-w-lg mx-auto bg-white rounded-md shadow-lg shadow-gray-700'>
        <div ref={modalRef}>
          <div className='flex items-center justify-center'>
            <div className='flex flex-col justify-center items-center p-5'>
              <img src={SecurityBlue} className='h-20 w-20'/>
              <p className='text-blue-600 text-2xl font-semibold text-center my-3'>Previous record detected!</p>
              <p className='text-blue-600 text-md text-center px-3'>An existing record linked to your provider number/Access code is found. The previous record will be used and will replace your existing data.</p>
              {
                process == '' ?
                  <div className='w-full flex flex-row space-x-4 mt-5 px-5'>
                    <button className='flex-1 rounded rounded-md border py-2 px-4 bg-gray-700 text-white font-semibold hover:bg-gray-300' onClick={onCancel}>Cancel</button>
                    <button className='flex-1 rounded rounded-md border py-2 px-4 dark-blue-bg text-white font-semibold hover:bg-blue-700' onClick={confirmReplace}>Continue</button>
                  </div>
                : null
              }

              { process == 'merging' ? <Spinner /> : null }

              {
                process == 'error' ?
                <div className='w-full flex flex-col space-x-4 mt-5 px-5'>
                  <p className='text-red-600 text-md text-center px-3 mb-3 font-semibold'>An error occured! {errorMessage}</p>
                  <button className='flex-1 rounded rounded-md border py-2 px-4 bg-gray-700 text-white font-semibold hover:bg-gray-300' onClick={onCancel}>Go Back</button>
                </div> : null
              }

              {
                process == 'success' ?
                <div className='w-full flex flex-col space-x-4 mt-5 px-5'>
                  <p className='text-green-600 text-md text-center px-3 mb-3 font-semibold'>Profile merging successfull!</p>
                  <button className='flex-1 rounded rounded-md border py-2 px-4 dark-blue-bg text-white font-semibold hover:bg-blue-700' onClick={() => {navigate('/dashboard')}}>Continue</button>
                </div> : null
              }

            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerificationConflict
