import React, { useEffect, useState } from 'react'
import CustomButton from '../buttons/CustomButton'
import CustomCard from '../container/CustomCard'

const Provider = () => {

  const user = JSON.parse(localStorage.getItem('user') || '{}')
  
   const [provider, setProvider] = useState<any>('')
  
    useEffect(() => {
  
      const formatText = () => {
  
        let formattedText = user?.insurance_provider
          .replace(/_/g, ' ')
          .toLowerCase()
          .replace(/\b\w/g, (match : any) => match.toUpperCase());
    
        formattedText = formattedText.replace(/\band\b/g, 'and');
        setProvider(formattedText);
      };
  
      formatText();
  
    }, [])

  return (

    <CustomCard className='w-[60%] mt-5 flex flex-col items-center bg-gray-50 rounded-xl border border-gray-200 shadow-lg'>
        <div className='flex flex-col shadow-lg pl-5 pr-5 w-full'>
        <p className='text-xl text-blue-600 font-semibold mt-5'>Provider Information</p>

        <div className='flex flex-row mt-3 pb-5'>

          <div className='flex flex-col flex-1 mr-[10%]'>
            <p>Provider</p>
              <div className='flex flex-col w-full h-full pb-1 border-b-2 border-b-gray-300'>
                <div className='flex flex-row items-center'>
                  <p className=' mt-2 ml-2'>{provider}</p>
                </div>
              </div>
          </div>

          <div className='flex flex-col mx-auto flex-1'>
            <p>Membership Number</p>
              <div className='flex flex-col w-full h-full pb-1 border-b-2 border-b-gray-300'>
                <div className='flex flex-row items-center'>
                  <p className='mt-2 ml-2'>{user?.insurance_number}</p>
                </div>
              </div>
          </div>
        </div>

      </div>

  </CustomCard>
  )
}

export default Provider
