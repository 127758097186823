import { useEffect, useRef, useState } from 'react'
import { User } from '../../classes/model/User'
import { IVoipTokenResponse } from '../../classes/interface/IVoip'
import pusher from '../../classes/Pusher'
import AgoraRTC from 'agora-rtc-sdk-ng'
import background from '../../assets/call-background.png';
import {
  BtnAcceptCall,
  BtnRejectCall,
  BtnEndCall,
  BtnMic,
  BtnCam,
} from '../buttons/voip/VoipButtons'

const AGORA_APP_KEY = process.env.REACT_APP_AGORA_APP_KEY

let client: any
let localVideoTrack: any
let localAudioTrack: any

let titleTimer: any = null;

let connected = false;

interface VoipProps {
  closeVoip: () => void
  channelName: string,
  call_type: string,
  audio: any,
  caller_name: string,
  blink_title: boolean
}

const Voip = ({ closeVoip, channelName, call_type, audio, caller_name, blink_title }: VoipProps) => {
  const [joined, setJoined] = useState(false)

  const [isMicMuted, setIsMicMuted] = useState(false)
  const [isCamMuted, setIsCamMuted] = useState(false)
  const [isNowBlinking, setIsNowBlinking] = useState(false)
  const [displayControls, setdisplayControls] = useState(false)

  const userPatient = JSON.parse(localStorage.getItem('user') ?? '')

  const userApi = new User()

  useEffect( () => {
    // Initialize Agora
    client = AgoraRTC.createClient({ codec: 'vp8', mode: 'rtc' })

    // Event listener for remote users when they publish their local tracks
    client.on('user-published', handleUserPublished)

    // Event listener when the remote user left the call
    client.on('user-left', handleUserLeft)

    setupPusher();

    return () => {
      // Cleanup event listeners when component unmounts
      client.off('user-published', handleUserPublished)
      client.off('user-left', handleUserLeft)
    }
  }, [])

  const toggleAudio = (play: boolean) => {
    const nav: any = window.navigator;
    if (audio !== false) {
      if (play) {
        if (nav.userActivation.hasBeenActive) {
          audio.loop = true;
          audio.play()
        }
      } else {
        audio.pause()
      }
    }
  }

  useEffect(() => {
    // Stops the ringtone if user answered the call or when the modal is closed
    // setPlayRingtone(!joined)
    if (!joined) {
      setTimeout(async function () {
        const response = await userApi.getParticipants({channel_name: channelName});
        if (!response.data.success) {
          await leave();
          toggleAudio(false);
          stopTitleTimer();
          closeVoip();
        } else {
          setdisplayControls(true);
          toggleAudio(true);
        }
        
      }, 5000);
    }
  }, [joined])

  useEffect ( () => {
    if (blink_title && !isNowBlinking && titleTimer == null) {
      initTitleTimer();
      setIsNowBlinking(true)
    }
  });

  const initTitleTimer = () => {
    titleTimer = setInterval( () => {
      let title = document.title;

      if (title == 'Medgate Web App') {
        title = '📞 Incoming call from medgate';
      } else {
        title = 'Medgate Web App';
      }

      document.title = title;
    }, 2000);
  }

  const stopTitleTimer = () => {
    try {
      clearInterval(titleTimer);  
    } catch (error) {
      console.log('No interval created')
    }
    document.title = 'Medgate Web App';
  }

  // Handler for remote users when they publish their local tracks
  const handleUserPublished = async (remoteUser: any, mediaType: any) => {
    // Subscribe to the remote user local tracks
    await client.subscribe(remoteUser, mediaType)

    if (mediaType === 'video') {
      const remoteVideoTrack = remoteUser.videoTrack

      // Render remote video
      remoteVideoTrack.play('remote-stream')
    }

    if (mediaType === 'audio') {
      const remoteAudioTrack = remoteUser.audioTrack

      // Play remote audio
      remoteAudioTrack.play()
    }
    
  }

  // Handler for remote users when they leave the call
  const handleUserLeft = (remoteUser: any) => {
    processEndOfCall()
  }

  // Request Agora token from RDP
  const requestAgoraToken = async () => {
    try {

      let response: IVoipTokenResponse = await userApi.getAgoraToken({
        channel_name: channelName,
      })

      return response.token
    } catch (error) {
      console.log('Error in requesting Agora Token: ', error)
    }
  }

  // Sends a request to RDP that the local user answered the call
  const callAnswered = async () => {
    try {
      // This request does not have a response
      await userApi.callAnswered({ channel_name: channelName })
    } catch (error) {
      console.log('Error in sending CallAnswered: ', error)
    }
  }

  // listen to pusher event when the call is answered from a different device
  const setupPusher = async () => {
    const channel = pusher.subscribe(channelName)
    channel.bind('end-call', async (data: any) => {
      if (data.channel == channelName) {
        closeVoip()
        toggleAudio(false)
        stopTitleTimer()
      }
    })

    channel.bind('call-answered', async (data: any) => {
      if (data.channel == channelName && !connected) {
        closeVoip()
        toggleAudio(false)
        stopTitleTimer()
      }
    })
  }

  // Join to the channel initiated by the remote user
  const join = async (agoraToken: string) => {
    try {
      let uuid = userPatient.id;
      await client.join(AGORA_APP_KEY, channelName, agoraToken, uuid)
    } catch (error) {
      console.log('Failed to join call: ', error)
    }
  }

  // Handler when local user left the call
  const leave = async () => {
    try {
      // Local video
      // Stop, close, and unpublish local video
      if (localVideoTrack) {
        localVideoTrack.stop()
        localVideoTrack.close()
        client.unpublish(localVideoTrack)
      }

      // Local audio
      // Stop, close, and unpublish local audio
      if (localAudioTrack) {
        localAudioTrack.stop()
        localAudioTrack.close()
        client.unpublish(localAudioTrack)
      }

      // Leave the channel
      await client.leave()
    } catch (error) {
      console.log('Error when leaving the call: ', error)
    }
  }

  // Publish tracks of local user
  const publishLocalTracks = async () => {
    try {
      // Create local tracks for audio and video
      localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack()
      if (call_type == 'video') {
        localVideoTrack = await AgoraRTC.createCameraVideoTrack()

        // Render local video
        localVideoTrack.play('local-stream')
        await client.publish([localVideoTrack, localAudioTrack])
      } else {
        await client.publish([localAudioTrack])
      }
      
    } catch (error) {
      console.log('Error in publishing local tracks: ', error)
    }
  }

  // Handler for local user when they accept the call
  const btnAcceptCall_onClick = async () => {
    const agoraToken: any = await requestAgoraToken()
    await join(agoraToken)
    /* Note: Set joined to true before publishing the local tracks
      The local tracks will not render properly when the components
      are not yet visible.
    */
    setJoined(true)
    connected = true;
    await publishLocalTracks();
    toggleAudio(false);
    stopTitleTimer();
    callAnswered()
  }

  // Handler for local user when they reject the call
  const btnRejectCall_onClick = async () => {

    try {
      toggleAudio(false);
      stopTitleTimer();

      // This request does not have a response
      await userApi.callEnded({ channel_name: channelName })
    } catch (error) {
      console.log('Error in sending CallAnswered: ', error)
    }
    closeVoip()
  }

  // Handler for local user when they want to mute or unmute
  const btnMic_onClick = () => {
    // Toggle mute
    setIsMicMuted((prev) => {
      try {
        if (localAudioTrack) {
          const newMutedState = !prev
          localAudioTrack.setEnabled(!newMutedState)
          return newMutedState  
        }  
      } catch (error) {
        console.log('Muted Error: ', error)
      }
      
      return false;
    })
  }

  // Handler for local user when they want to show their video or not
  const btnCam_onClick = () => {
    // Toggle mute
    setIsCamMuted((prev) => {
      try {
        if (localVideoTrack) {
          const newMutedState = !prev
          localVideoTrack.setEnabled(!newMutedState)
          return newMutedState
        }  
      } catch (error) {
        console.log('Cam Mute Error: ', error) 
      }
      return false;
    })
  }

  // Handler for local user when they end the call
  const btnEndCall_onClick = () => {
    processEndOfCall()
  }

  // Processes the end of call
  const processEndOfCall = async () => {
    // Unpublish local tracks and leave the channel
    await leave()

    setJoined(false)
    connected = false;

    // Reset to default values
    setIsMicMuted(false)
    setIsCamMuted(false)
    toggleAudio(false);

    // Closes the VoIP modal
    closeVoip()
  }

  return (
    <>
      <div className='flex items-center justify-center fixed inset-0 z-10 overflow-y-auto bg-gray-600 bg-opacity-70 h-full w-full'>
        <div className='flex items-center'>
          <div className='relative w-full p-3 mx-auto bg-white rounded-md shadow-lg shadow-gray-700'>
            <div className='flex flex-col justify-center items-center rounded-md bg-zinc-800'>
              <div className='relative w-full h-full'>
                {joined ? (
                  <>
                    <div id='remote-stream' className='w-[800px] h-[500px]' style={{background: `url(${background})`, backgroundSize: 'contain'}}></div>
                    {
                      call_type == 'video' ? 
                      <div id='local-stream' 
                        className='absolute bottom-0 left-0 w-1/4 h-2/4 max-w-xs max-h-xs object-cover border border-gray-300 rounded'
                        style={{background: `url(${background})`, backgroundSize: 'cover', backgroundPosition: 'center'}}></div>
                      : null
                    }
                    <div className='w-full absolute bottom-0'>
                      <div className='flex flex-row items-center justify-center gap-x-16 pb-4'>
                        <BtnMic
                          onClick={btnMic_onClick}
                          isMicMuted={isMicMuted}
                          className='w-14 h-14'
                        />
                        {
                          call_type == 'video' ?
                          <BtnCam
                            onClick={btnCam_onClick}
                            isCamMuted={isCamMuted}
                            className='w-14 h-14'
                          /> : null
                        }
                        
                        <BtnEndCall
                          onClick={btnEndCall_onClick}
                          className='w-14 h-14'
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className='flex flex-col items-center justify-center w-[800px] h-[500px]' style={{background: `url(${background})`, backgroundSize: 'contain'}}>
                    <div className='flex-grow pt-40 items-center justify-center text-white text-2xl'>
                      {caller_name} is calling...
                    </div>
                    <div className='flex flex-row items-center justify-center gap-x-16 pb-4'>
                      {
                        displayControls ?
                        <>
                          <BtnAcceptCall
                          onClick={btnAcceptCall_onClick}
                          className='w-14 h-14'
                        />
                        <BtnRejectCall
                          onClick={btnRejectCall_onClick}
                          className='w-14 h-14'
                        />
                        </> : null
                      }

                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Voip
