import { FC } from 'react'
import FacebookLogin from '@greatsumini/react-facebook-login'

interface Props {
  children?: any,
  onLogin: (response: object) => void,
  onError: (message: string) => void,
  cssClass?: string
}

const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID as string

const FacebookLoginButton: FC<Props> = ({ cssClass, children, onLogin, onError }) => {
  return (
    <FacebookLogin
      appId={FACEBOOK_APP_ID}
      className={cssClass != null ? cssClass : 'relative flex flex-row items-center justify-center text-white w-[20rem]'}
      children={
          children != null ? children :
          <div><svg className="absolute left-3 h-6 w-6 mr-2" fill="#ffffff" height="200px" width="200px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 310 310" stroke="#ffffff"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="XMLID_834_"> <path id="XMLID_835_" d="M81.703,165.106h33.981V305c0,2.762,2.238,5,5,5h57.616c2.762,0,5-2.238,5-5V165.765h39.064 c2.54,0,4.677-1.906,4.967-4.429l5.933-51.502c0.163-1.417-0.286-2.836-1.234-3.899c-0.949-1.064-2.307-1.673-3.732-1.673h-44.996 V71.978c0-9.732,5.24-14.667,15.576-14.667c1.473,0,29.42,0,29.42,0c2.762,0,5-2.239,5-5V5.037c0-2.762-2.238-5-5-5h-40.545 C187.467,0.023,186.832,0,185.896,0c-7.035,0-31.488,1.381-50.804,19.151c-21.402,19.692-18.427,43.27-17.716,47.358v37.752H81.703 c-2.762,0-5,2.238-5,5v50.844C76.703,162.867,78.941,165.106,81.703,165.106z"></path> </g> </g></svg> <span>Get started with Facebook</span></div>
      }
      onSuccess={(response) => {
        onLogin(response);
      }}
      onFail={(error) => {
        console.log('Login Failed!', error)
        onError('Login Failed!');
      }}
    />
  )
}

export default FacebookLoginButton
