export const validateEmail = (email: string): boolean => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
}

export const validatePassword = (password: string): boolean => {
    // Define criteria for password validation
    const minLength = 8
    const maxLength = 20
    const passwordRegex = /^[a-zA-Z0-9!@#$%^&*()_+=[\]{};':"\\|,.<>/?-]+$/

    // Check length and format
    return (
        password.length >= minLength &&
        password.length <= maxLength &&
        passwordRegex.test(password)
    )
}

export const validateInput = (inputValue: string) => {
    if (inputValue.trim() === '') {
        alert('Input is required');
        return;
    }
}


export const validate = {
    min: (min: number, value: number): boolean => value >= min,

    hasLowerCase(value: string): boolean {
        let lowerCasePattern = new RegExp("^(?=.*[a-z]).+$")
        return lowerCasePattern.test(value)
    },

    hasUpperCase(value: string): boolean {
        let upperCasePattern = new RegExp("^(?=.*[A-Z]).+$");
        return upperCasePattern.test(value)
    },

    hasNumber(value: string): boolean {
        let numberPattern = new RegExp("^(?=.*[\\d]).+$");
        return numberPattern.test(value)
    },

    hasSpecialChar(value: string): boolean {
        let specialCharPattern = new RegExp("^(?=.*[-+_!@#$%^&*.,?]).+$");
        return specialCharPattern.test(value)
    }
} 