export const getParsedDataFromLocalStorage = <T>(key: string): T | null => {
    // Retrieve data from localStorage
    const item = localStorage.getItem(key);
  
    // Check if item is null or empty
    if (!item || item.trim().length === 0) {
      //console.log("No data found or data is empty.");
      return null;
    }
  
    // Parse the data
    try {
      return JSON.parse(item) as T;
    } catch (error) {
      //console.error("Error parsing JSON from localStorage:", error);
      return null;
    }
  };