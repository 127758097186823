import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Case } from '../../classes/model/Case';
import { max } from 'moment';
import '../../index.css'

interface Props {

  setSlot : any
  setSelectedDate : any
  selectedDate : any
  minimumDate : any
  maximumDate : any
}

const AppointmentCalendar = ({setSlot, setSelectedDate, minimumDate, maximumDate, selectedDate} : Props) => {

  const [date, setDate] = useState<any>(selectedDate || new Date())

  /*console.log('load calendar')
  console.log('load calendar minimumDate', minimumDate)
  console.log('load calendar maximumDate', maximumDate)*/


  const caseRequest = new Case()

  const [allowedDates, setAllowDates] = useState<any[]>([])
  const [value, setValue] = useState([minimumDate, maximumDate]);
  /*const [minimumDate, setMinimumDateDate] = useState<any>()
  const [maximumDate, setMaximumDate] = useState<any>()*/

  useEffect(() => {

    if(date !== ''){
      const selectedDate = new Date(date);
      selectedDate.toLocaleDateString('en-CA'); 
      getAvailableTimeSlot(selectedDate);
    }else{
      const currentDate = new Date();
      currentDate.toLocaleDateString('en-CA'); 
  
      getAvailableTimeSlot(currentDate);
    }
   
  }, [])


  const tileClassName = ( allowedDates: any ) => {
    if (isAllowedDate(allowedDates)) {
      return 'allowed-date'; // Custom class for allowed dates
    }
  };

  const isAllowedDate = (date : any) => {
   const formattedDate = formatDate(date)
   return allowedDates.includes(formattedDate)
  };

  const getAvailableTimeSlot = async (day: any) => {

    //console.log('getAvailableTimeSlot')

    setSlot([])

    const date = formatDate(day)
    localStorage.setItem("callbackRequest_callDate", JSON.stringify(date))

    const timeSlot: any = await caseRequest.getTimeSlot({ day: date }, 'getTime_Slot_endpoint')
    console.log("response", timeSlot)

    setSlot(timeSlot)
    setSelectedDate(date);
    setDate(day)
  }

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  };

  const handleChange = (newValue : any) => {
    // Update the selected date range
    if (Array.isArray(newValue)) {
      setValue(newValue);
    } else {
      // Handle the case where a single date is selected (optional)
    }
  };

  /*const getMinMaxDate = () => {

    console.log('getting min and max date')
    let counter = 0

    {Object.keys(dates).map((item : any) => {

      if(counter === 0){
        console.log('supposed min date', dates[item][0])

        const mindate = new Date(dates[item][0])
        console.log('minDate', mindate)

       setMinimumDateDate(mindate)
        counter++
      }else{

        console.log('supposed max date', dates[item][dates[item].length -1])

        const maxdate = new Date(dates[item][dates[item].length -1])

        console.log('maxDate', maxdate)
        setMaximumDate(maxdate)
      }
    })}

  }*/

 
  return (
    <div>
        <Calendar minDate={minimumDate} maxDate={maximumDate}  tileDisabled={({ date }) => date < minimumDate || date > maximumDate} className='text-sm p-1 rounded-lg'  onChange={getAvailableTimeSlot} value={date} /> {/**tileClassName="react-calendar rct-dateHeader-primary" value={selectedDate === null ? null : new Date(selectedDate)} */}
    </div>
  )
}

export default AppointmentCalendar