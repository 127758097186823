import React, { useState } from 'react'
import { User } from '../../classes/model/User'
import { IChangePasswordResponse, IPasswordState } from '../../classes/interface/IPassword'

import key from '../../assets/key_gray.png'
import eye from '../../assets/eye.png'
import eyeWithLine from '../../assets/eye-with-line.png'
import { validate } from '../../utils/validator'
import PasswordStrengthBar from '../PasswordStrengthBar'
import { countTrueValues } from '../../utils/countTrueValues'



interface Props {
  setCurrentPassword : any,
  setNewPassword : any,
  setConfirmNewPassword : any
  isEmail : any
  setEmail : any
  onEnter : () => void
}

const PASS_MIN_LENGTH = process.env.REACT_APP_PASS_MIN_LENGTH as string


const Password = ({setCurrentPassword, setNewPassword, setConfirmNewPassword, isEmail, setEmail, onEnter} : Props) => {

  const [isShowCurrentPassword, setIsShowCurrentPassword] = useState(false)
  const [isShowNewPassword, setIsShowNewPassword] = useState(false)
  const [isShowRetypePassword, setIsShowRetypePassword] = useState(false)

  const [passwordLength, setPasswordLength] = useState(0)

  const [passwordState, setPasswordState] = useState<IPasswordState>({
    hasMinLength: false,
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumber: false,
    hasSpecialChar: false,
  })


  /*const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')*/


 /* const handleChangePasswordRequest = async () => {

    const user = new User()
    const response: IChangePasswordResponse = await user.changePassword({
      old_password: oldPassword,
      password: newPassword,
      password_confirmation: passwordConfirmation,
    })


  }*/

    const inpPassword_onChange = (password: string) => {
      validatePasswordState(password)

      setNewPassword(password)
      setPasswordLength(password.length)
    }

    const validatePasswordState = (password: string) => {
      passwordState.hasMinLength = validate.min(+PASS_MIN_LENGTH, password.length)
      passwordState.hasLowerCase = validate.hasLowerCase(password)
      passwordState.hasUpperCase = validate.hasUpperCase(password)
      passwordState.hasNumber = validate.hasNumber(password)
      passwordState.hasSpecialChar = validate.hasSpecialChar(password)
      setPasswordState(passwordState)
    }
    

  return (
    <div className='flex flex-col h-[35%] w-full space-y-7'>

        <div className='flex flex-col w-full mt-[5%] space-y-5'>
            {isEmail ? <input className='input_design placeholder:text-gray-400' type='text' placeholder='Email Address' onChange={(e) => setEmail(e.target.value)} />:

            <div className='flex flex-row border-b-2 border-b-gray-400 pb-2'>
                <img src={key} className='h-7 w-7 mr-2' />
                <input className=' placeholder:text-gray-400 !outline-none w-full' type={isShowCurrentPassword ? 'text' : 'password'} placeholder='Current Password' onChange={(e) => setCurrentPassword(e.target.value)} />
                {/** <img src={eye} className='h-7 w-7 cursor-pointer' onClick={() => setIsShowCurrentPassword(!isShowCurrentPassword)}/>*/}
              {isShowCurrentPassword ? <img src={eyeWithLine} className='h-7 w-7 cursor-pointer' onClick={() => setIsShowCurrentPassword(!isShowCurrentPassword)}/> : <img src={eye} className='h-7 w-7 cursor-pointer' onClick={() => setIsShowCurrentPassword(!isShowCurrentPassword)}/> }

              </div>
             }

            <div className='flex flex-row border-b-2 border-b-gray-400 pb-2'>
              <img src={key} className='h-7 w-7 mr-2' />
               <input className=' placeholder:text-gray-400 !outline-none w-full' type={isShowNewPassword ? 'text' : 'password'} placeholder='New Password' onChange={(e) => inpPassword_onChange(e.target.value)} onKeyDown={(e) => { if (e.key === "Enter") { onEnter() } }}/>
              {/** <img src={eye} className='h-7 w-7 cursor-pointer' onClick={() => setIsShowNewPassword(!isShowNewPassword)}/>*/}
              {isShowNewPassword ? <img src={eyeWithLine} className='h-7 w-7 cursor-pointer' onClick={() => setIsShowNewPassword(!isShowNewPassword)}/> : <img src={eye} className='h-7 w-7 cursor-pointer' onClick={() => setIsShowNewPassword(!isShowNewPassword)}/> }

            </div>

            <PasswordStrengthBar
            className={'mt-1'}
            countTrueValues={countTrueValues(passwordState)}
          />

            <div className='flex flex-col text-xs mt-1 italic'>
                <p>Should be at least 8 characters</p>
                <p>Include atleast 1 upper case</p>
                <p>Include atleast 1 lower case</p>
                <p>Include atleast 1 number</p>
                <p>Include atleast 1 special character</p>
            </div>

            <div className='flex flex-row border-b-2 border-b-gray-400 pb-2'>
              <img src={key} className='h-7 w-7 mr-2' />
              <input className='placeholder:text-gray-400 !outline-none w-full' type={isShowRetypePassword ? 'text' : 'password'} placeholder='Re-type Password' onChange={(e) => setConfirmNewPassword(e.target.value)} onKeyDown={(e) => { if (e.key === "Enter") { onEnter() } }}/>
              {/**<img src={eye} className='h-7 w-7 cursor-pointer' onClick={() => setIsShowRetypePassword(!isShowRetypePassword)}/> */}
              {isShowRetypePassword ? <img src={eyeWithLine} className='h-7 w-7 cursor-pointer' onClick={() => setIsShowRetypePassword(!isShowRetypePassword)}/> : <img src={eye} className='h-7 w-7 cursor-pointer' onClick={() => setIsShowRetypePassword(!isShowRetypePassword)}/> }

            </div>
        </div>
      </div>
  )
}

export default Password
