import React from 'react'
import background from '../../assets//Backdrop-FINAL.png'

const IndexCollapsibleContainer = (Props : any) => {
    return (
        <div className='flex bg-gray-100 relative h-[100%] flex-col items-center w-full'>
        {/*<div className='fixed-bg h-screen flex flex-col items-center justify-center bg-gray-100 top-0 bg-contain bg-no-repeat bg-bottom fixed z-[-1] opacity-30' style={{backgroundImage: `url(${background})`}}/>*/}
        <div className='fixed-bg h-[100%] flex flex-col items-center justify-center bg-gray-100 top-0 bg-contain bg-no-repeat bg-bottom absolute z-[5] opacity-30' style={{backgroundImage: `url(${background})`}}/>
        {Props.children}
    </div>
    )
}

export default IndexCollapsibleContainer