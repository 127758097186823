import React, { useEffect, useRef, useState } from 'react'
import backgroundImage from '../../assets/home-banner.png'
import { User } from '../../classes/model/User';
import { useLocation, useNavigate } from 'react-router-dom';
import Index from '../modals/Index';
import Spinner from '../spinner/Spinner';
import useEnterKey from '../../classes/EnterFunction';
import CustomCard from '../container/CustomCard';

interface Props {
  isCodeVerificationCOmplete : any
}

const CodeVerification = ({isCodeVerificationCOmplete} : Props) => {

  const location = useLocation();


  const navigate = useNavigate()
  const user = new User();

    const length = 6;
    const [code, setCode] = useState<string[]>(Array(length).fill(''));
    const inputRef = useRef<HTMLInputElement[]>(Array(length).fill(null));

    const [showModal, setShowModal] = useState(false)
    const [message, setMessage] = useState<any>('')
    const [email, setEmail] = useState<any>(localStorage.getItem('email') ?? '')
    const [ishowResendMessage, setIshowResendMessage] = useState(false)
    const [ishowFailedResendMessage, setIshowFailedResendMessage] = useState(false)

    const [isShowAnimation, setIsShowAnimation] = useState(false)
    const [isShowResendAnimation, setIsShowResendAnimation] = useState(false)
    const [zoomLevel, setZoomLevel] = useState<number>(1);

    console.log("window.innerWidth", window.innerWidth)
    console.log("window.innerHeight", window.innerHeight)


    const [dimensions, setDimensions] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  
    // Function to update dimensions
    const updateDimensions = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    useEffect(() => {
      const getSizes = () => {
        // Calculate zoom percentage
        const zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
        setZoomLevel(zoom);
      };
  
      window.addEventListener('resize', getSizes);
      getSizes();
  
      return () => {
        window.removeEventListener('resize', getSizes);
      };
    }, []);

    
  
    // Set up event listener for window resize
    useEffect(() => {

      window.addEventListener('resize', updateDimensions);
      return () => {
        window.removeEventListener('resize', updateDimensions);
      };
    }, []);
    
    const handleCodeRequest = async () => {

      setIsShowAnimation(true)

      const combinedCode = code.join('')

      if(!validateCode(combinedCode)){

        const response: any = await user.codeValidation(combinedCode, "codeValidation_endpoint")
        console.log(response)

        if(response.message === 'success'){
          isCodeVerificationCOmplete(true)
          navigate('/update-password', { state: { combinedCode } })
        }else{
          setMessage(response.message)
          setShowModal(true)
        }
      }else{
        setMessage('Reset Code is required')
        setShowModal(true)
      }


      setIsShowAnimation(false)

    }

    const handleTextChange = (input: string, index: number) => {
        const newPin = [...code];
        newPin[index] = input;
        setCode(newPin);


        if (input.length === 1 && index < length - 1) {
          inputRef.current[index + 1]?.focus();
        }


        if (input.length === 0 && index > 0) {
          inputRef.current[index - 1]?.focus();
        }
      };

    const validateCode = (code : string): boolean => {
      return code.trim() === '';
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
      const pastedData = event.clipboardData.getData("text");
      const splitData = pastedData.split("").slice(0, length);

      const updatedCode = [...code];
      splitData.forEach((char, idx) => {
        updatedCode[idx] = char;
      });
      setCode(updatedCode);

      // Automatically focus each input field
      splitData.forEach((_, idx) => {
        inputRef.current[idx]?.focus();
      });
    };

    const handleResendOtp = async () => {
      console.log("calling resend request")

      setIsShowResendAnimation(true)

      //need to use forgot password endpoint since there's no resend endpoint for forgot password
      const response: any = await user.forgotPassword({
        email: email.replace(/"/g, ''),
        source: 'Web',
      }, "forgotPassword_endpoint")

      if(response.message === 'success'){
        setIshowResendMessage(true);
      }else{
        setIshowFailedResendMessage(true)
      }

      setIsShowResendAnimation(false)

      setTimeout(() => {
        setIshowResendMessage(false);
        setIshowFailedResendMessage(false);
      }, 5000);

      console.log(response)
    }

    useEnterKey(() => handleCodeRequest());
    console.log("dimension", dimensions)
    console.log("zoom", zoomLevel)


  return (
    <>
    <div className='h-screen flex flex-col bg-cover bg-center w-full' style={{backgroundImage: `url(${backgroundImage})`}}>

      {/**<div className={`flex flex-col items-center mt-[10%] ml-[20%] w-[30%] space-x-2 bg-white rounded-xl ${dimensions.height >= 1200 ? `${ishowResendMessage || ishowFailedResendMessage ? 'h-[35%]' : 'h-[32%]'}` : `${ishowResendMessage || ishowFailedResendMessage ? 'h-[45%]' : 'h-[40%]'}`} `}> */}
      {/**<CustomCard className={`flex flex-col items-center mt-[10%] ml-[20%] w-[30%] space-x-2 bg-white rounded-xl ${zoomLevel > 148 ? 'h-[44%]' : `${dimensions.height >= 1200 ? `${ishowResendMessage || ishowFailedResendMessage ? 'h-[35%]' : 'h-[32%]'}` : `${ishowResendMessage || ishowFailedResendMessage ? 'h-[45%]' : 'h-[40%]'}`}`}  `}> */}
      <CustomCard className='flex flex-col items-center mt-[10%] ml-[20%] w-1/4 min-w-[28rem] space-x-2 bg-white rounded-xl pb-5'>

        <div className='w-[80%]'>
            <p className='flex justify-center text-blue-600 text-4xl font-bold mt-[7%] mb-[5%]'>Forgot Password</p>
            <div className='flex flex-col items-center justify-center text-large h-16 w-full bg-green-200 rounded-xl mb-5'>
              <p>We've sent a verification code to your email</p>
              <p className='text-center'>{email}</p>
            </div>
            <div className={`grid grid-cols-6 gap-2`} onPaste={handlePaste} onKeyDown={(e) => { if (e.key === "Enter") { handleCodeRequest() } }}>
                {Array.from({ length }, (_, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={code[index]}
                    onChange={(e) => handleTextChange(e.target.value, index)}
                    ref={(ref) => (inputRef.current[index] = ref as HTMLInputElement)}
                    className={`border-2 border-solid border-blue-600 focus:border-blue-600 h-14 !outline-none rounded-xl text-lg text-center font-semibold`}
                  />
                ))}
            </div>

            <div className={`flex flex-col items-center justify-center ${isShowResendAnimation ? null : 'mt-5'}`}> 

                {ishowResendMessage ? <div className="text-green-500 text-center mb-3"><p>OTP code successfully sent</p></div> : null}
                {ishowFailedResendMessage ? <div className="text-red-500 text-center mb-3"><p>Failed to resend OTP. Please try again</p></div> : null}


                <div className='flex flex-row items-center justify-center h-auto'>
                  <p>Didn't receive a code?</p>
                  {isShowResendAnimation ? <div className='mx-3 my-1 pb-1'><Spinner/> </div> : <p className='text-blue-600 border-b border-b-blue-600 cursor-pointer ml-2' onClick={() => handleResendOtp()}>Resend</p>}</div>
                </div>

            {isShowAnimation ? <div className='flex justify-center items-center'><Spinner /></div> :

              <div className={`flex flex-row mt-6 justify-center space-x-5`}>
                  <button className='h-10 w-[30%] rounded-xl bg-custom-blue text-white hover:bg-blue-600' onClick={handleCodeRequest}>Submit</button>
                  <button className='h-10 w-[30%] rounded-xl bg-blue-200 text-blue-600 hover:bg-blue-300' onClick={() => navigate('/forgot-password')}>Back</button>
              </div>
            }
        </div>
        {/**</div> */}  
        </CustomCard>
        <div className='bg-blue-600 h-16 w-full mt-auto'></div>
        {showModal ? <Index message={message} status={undefined} closeModal={() => setShowModal(false)} page='' email=''/>  : null}
    </div>
    </>
  )
}

export default CodeVerification
