import React from 'react'
import backArrow from '../../assets/back-arrow.png'
import ContactInformation from './ContactInformation'
import Provider from './Provider'
import Health from './Health'
import StandardContainer from '../container/StandardContainer'
import CustomCard from '../container/CustomCard'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

const Profile = () => {

  const user = JSON.parse(localStorage.getItem('user') || '{}')
  console.log(user)

  const birthday = moment(user?.patient?.birthdate).format('DD MMMM YYYY');

  const navigate = useNavigate();

  return (
    <StandardContainer>

      <div className='flex flex-row items-center rounded-xl bg-[#1665CE] text-white w-[60%] space-x-5'>
        <img src={backArrow} className='ml-5 cursor-pointer' onClick={() => navigate('/account')}/>
        <p className='text-xl font-semibold'>Profile</p>
      </div>

      <CustomCard className='flex flex-col rounded-xl w-[60%] mt-5 pl-5 pr-5 pb-5 bg-gray-50'>
        <p className='text-blue-500 text-xl font-semibold mt-3'>Personal Information</p>

        <div className='grid grid-cols-2 gap-[10%] mt-3 w-full h-[15%] mb-5'>

          <div className='flex flex-col w-[100%] h-full pb-2 border-b-2 border-b-gray-300'>
            <p>Name</p>
            <p className=' mt-2 ml-2'>{user?.patient?.full_name}</p>
          </div>

          <div className='flex flex-col w-[100%] h-full pb-2 border-b-2 border-b-gray-300'>
            <p>Gender</p>
            <p className='mt-2 ml-2'>{user?.patient?.gender}</p>
          </div>

          <div className='flex flex-col w-[100%] h-full pb-2 border-b-2 border-b-gray-300'>
            <p>Birthday</p>
            <p className='mt-2 ml-2'>{birthday}</p>
          </div>

          <div className='flex flex-col w-[100%] h-full pb-2 border-b-2 border-b-gray-300'>
            <p>Civil Status</p>
            <p className='mt-2 ml-2'>{user?.patient?.marital_status}</p>
          </div>

        </div>
      </CustomCard>

    <ContactInformation />

    <Provider />

    <Health />

    </StandardContainer>
  )
}

export default Profile
