import { channel } from "diagnostics_channel";
import { requests } from "../../../utils/requests";
import { IVoipCallAnswered, IVoipTokenRequest, IVoipTokenResponse } from "../../interface/IVoip";

const REQUEST_TOKEN_ENDPOINT = process.env.REACT_APP_RDP_API_VOIP_REQUEST_TOKEN_ENDPOINT as string
const CALL_ANSWERED_ENDPOINT = process.env.REACT_APP_RDP_API_VOIP_CALL_ANSWERED_ENDPOINT as string
const CALL_ENDED_ENDPOINT = process.env.REACT_APP_RDP_API_VOIP_CALL_ENDED_ENDPOINT as string

export class Voip extends requests {
    token = JSON.parse(localStorage.getItem('api_token') ?? '')

    async getAgoraToken({ channel_name }: IVoipTokenRequest) {
        const request: IVoipTokenRequest = {
            channel_name: channel_name
        }

        let response: IVoipTokenResponse = await this.postRequestWithDefineResponse(REQUEST_TOKEN_ENDPOINT, request, this.token);
        return response;
    }

    async callAnswered({ channel_name }: IVoipCallAnswered) {
        const request: IVoipCallAnswered = {
            channel_name: channel_name
        }

        const endpoint = `${CALL_ANSWERED_ENDPOINT}/${request.channel_name}`

        let response = await this.postRequest(endpoint, request, this.token);
        return response;
    }

    async callEnded({ channel_name }: IVoipCallAnswered) {
        const request: IVoipCallAnswered = {
            channel_name: channel_name
        }

        const endpoint = `${CALL_ENDED_ENDPOINT}/${request.channel_name}`

        console.log('endpoint called: ', endpoint);

        let response = await this.postRequest(endpoint, request, this.token);
        return response;
    }

    async participants ({channel_name}: any) {
        const endpoint = '/api/v2/callback/call/participants'
        let response = await this.postRequest(endpoint, {channel: channel_name}, this.token);
        return response;
    }
}