import { getMessaging, getToken } from 'firebase/messaging'
import { firebaseApp } from '../firebase';

export const getFirebaseToken = async () => {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
        const messaging = getMessaging(firebaseApp)
        const permission = await Notification.requestPermission()

        if (permission === 'granted') {
            const currentToken = await getToken(messaging, {
                vapidKey:
                    process.env.REACT_APP_FCM_VAPID_KEY,
            })
            if (currentToken) {
                console.log('Current Token', currentToken)
                return currentToken
            } else {
                console.log(
                    'No registration token available. Request permission to generate one.'
                )
                return false
            }
        } else {
            console.log('Permission not granted.')
            return false
        }
    } else {
        console.log('Push messaging is not supported.')
        return false
    }
}
