import { IOTP } from "../interface/IOTP";
import { Others } from "../requests/Others";

export class OtherRequest {

    private request: any;

    constructor() {
        this.request = null;
    }

    async getHotlines(request : any): Promise<any> {
        this.request = new Others();
        return await this.request.getHotlines(request)
    }

    async verifyOTP(otp : any, request: any): Promise<any> {
        this.request = new Others();
        return await this.request.verifyOTP(otp, request)
    }
}