import React, { useEffect, useState } from 'react'
import provider_icon_blue from '../assets/I have a provider- blue.png'
import provider_icon_green from '../assets/I have a provider- green.png'
import accessCode_icon_blue from '../assets/I have an access code- blue.png'
import accessCode_icon_green from '../assets/I have an access code- green.png'
import shop_blue from '../assets/Shop Cart.png'
import shop_green from '../assets/Shop Cart- green.png'
import backgroundImage from '../assets/home-banner.png'
import green_down from '../assets/green-arrow-down.png'

import blueTriangle from '../assets/blue-triangle.png'
import grayTriangle from '../assets/gray-triangle.png'
import { Providers } from '../classes/requests/user/Providers'
import { User } from '../classes/model/User'
import { useNavigate } from 'react-router-dom'

import CustomCard from './container/CustomCard'
import Spinner from './spinner/Spinner'
import Index from './modals/Index'
import VerificationConflict from './modals/VerificationConflict'
import { resetErrorsCount } from 'ajv/dist/compile/errors'
import useEnterKey from '../classes/EnterFunction'

interface Props {
    isOtpComplete: boolean
}

const Verification = ({ isOtpComplete }: Props) => {

    const [provider, setProvider] = useState('')
    const [memberId, setMemberId] = useState('')
    const [accessCode, setAccesCode] = useState('')
    const [coc, setCoc] = useState(0)

    const [providers, setProviders] = useState<any[]>()
    const [selectedSelection, setSelectedSelection] = useState<any>('')
    const [message, setMessage] = useState<any>('')
    const [isShowModal, setIsShowModal] = useState(false)
    const [isConflict, setIsConflict] = useState<boolean>(false)
    const [guid, setGuid] = useState<string>('')

    const [isShowSpinner, setIsShowSpinner] = useState(false)

    const [isProviderToggle, setIsProviderToggle] = useState(false)
    const [isAccessCodeToggle, setIssAccessCodeToggle] = useState(false)
    const [isShopToggle, setIsShopToggle] = useState(false)

    const userRequest = new User()
    const navigate = useNavigate();

    const fromPath = JSON.parse(localStorage.getItem('fromPath') || 'null')

    const checkInsuranceNumber = () => {
        const tempUser = JSON.parse(localStorage.getItem('temp_user') || 'null')  ;
        if (tempUser !== null) {
            if (tempUser.insurance_number != null || tempUser.insurance_number == '') {
                updateLocalStorage();
                navigate('/dashboard')
            }
        }
    }


    useEffect(() => {

        checkInsuranceNumber();

        const getProvidersRequest = async () => {
            const response: any = await userRequest.getProviders('getProviders_endpoint')
            setProviders(response.providers)
        }

        getProvidersRequest()

    }, [])

    const updateLocalStorage = () => {
        const tempToken = localStorage.getItem('temp_api_token');
        const tempUser = localStorage.getItem('temp_user');

        if (tempToken) {
            localStorage.setItem('api_token', tempToken);
            localStorage.removeItem('temp_api_token');
        }

        if (tempUser) {
            localStorage.setItem('user', tempUser);
            localStorage.removeItem('temp_user');
        }
    }

    const handleVerificationRequest = async () => {

        if (memberId === '' && accessCode === '') {
            setMessage("Please enter your Provider and Membership Number or Access Code.")
            setIsShowModal(true)
            return;
        }

        if (coc === 0) {
            setMessage("Please confirm that you understand and agree to the call recording policy")
            setIsShowModal(true)
            return;
        }

        // var user = JSON.parse(localStorage.getItem('temp_user') ?? '')
        setIsShowSpinner(true)
        const response: any = await userRequest.validateProvider({
            provider: accessCode === '' ? provider : 'Medgate Unliconsultation Plan',
            member_id: accessCode === '' ? memberId : accessCode,
            user: localStorage.getItem('temp_user')
        }, 'validateProvider_endpoint')


        console.log('handleVerificationRequest', response)

        if (response.message === 'success') {

            if (response.data.details.eligible === true) {
                updateLocalStorage();
                localStorage.setItem('user', JSON.stringify(response.data.user));
                //localStorage.setItem('loa', JSON.stringify(response?.data?.details?.loa));
                localStorage.setItem("loa", JSON.stringify(response.data.details.loa))

                if (response.data.is_conflict) {
                    setIsConflict(true);
                    setGuid(response.data.details.patient_guid)
                } else {
                    navigate('/dashboard')
                }
            } else {
                setMessage("We've detected that your provider information is no longer up-to-date. Please coordinate with your provider to ensure your account remains active and you can continue to access Medgate's services")
                setIsShowModal(true)
            }
        } else {
            setMessage(response.message.message)
            setIsShowModal(true)
        }

        setIsShowSpinner(false)
    }

    const handleDropdownRequest = (e: any) => {
        setProvider(e.target.value)
    }



    const handleSelectionRequest = (section: any) => {

        setSelectedSelection(section)

        if (section === "provider") {
            setIsProviderToggle(!isProviderToggle)
            setIssAccessCodeToggle(false)
            setIsShopToggle(false)
        }

        if (section === "accesscode") {
            setIssAccessCodeToggle(!isAccessCodeToggle)
            setIsProviderToggle(false)
            setIsShopToggle(false)


        }

        if (section === "shop") {
            setIsShopToggle(!isShopToggle)
            setIssAccessCodeToggle(false)
            setIsProviderToggle(false)
        }
    }

    useEnterKey(() => handleVerificationRequest());

    return (
        <div className='flex flex-col min-h-screen bg-gray-100 w-full bg-cover bg-center' style={{ backgroundImage: `url(${backgroundImage})` }}>

            <CustomCard className={`flex flex-col py-7 items-center justify-center ml-[20%] w-[30%] mt-[5%] min-w-[320px] space-y-8 bg-white rounded-3xl mb-5`}>

                <p className='text-4xl text-blue-500 font-bold'>Verification</p>

                <div className='flex flex-col w-[80%]'>

                    {/**provider section */}
                    <div className={`flex flex-row items-center border-b-2 pb-2 ${isProviderToggle ? 'border-b-emerald-300' : 'border-b-blue-300'} cursor-pointer`} onClick={() => handleSelectionRequest('provider')}>

                        {selectedSelection == 'provider' && isProviderToggle ?

                            <div className='flex flex-row items-center w-full'>
                                <img src={provider_icon_green} className='h-10 w-10 mr-5' />
                                <p className='text-lg text-emerald-300 font-semibold'>I have a provider</p>
                                <img src={green_down} className='flex justify-center h-4 w-6 ml-auto mr-3 cursor-pointer' />
                            </div> :
                            <div className='flex flex-row items-center w-full'>
                                <img src={provider_icon_blue} className='h-10 w-10 mr-5' />
                                <p className='text-lg text-blue-600 font-semibold'>I have a provider</p>
                                <img src={blueTriangle} className='h-5 w-4 ml-auto mr-3 cursor-pointer' />
                            </div>
                        }

                    </div>
                    {selectedSelection === 'provider' && isProviderToggle ?
                        <div className='flex flex-col'>
                            <div className='flex flex-col border-b-2 pb-2 border-b-gray-300  mt-5 cursor-pointer' >
                                <p className='mb-2'>Provider</p>
                                <select className='cursor-pointer !outline-none focus:!containeroutline-none focus:border-gray-200' onChange={(e) => { setProvider(e.target.value) }}>
                                    <option value="null"></option>
                                    {providers?.map((item, index) => (
                                        <option key={index} value={item.key}>{item.display_name}</option>
                                    ))}
                                </select>
                            </div>

                            <div className='flex flex-col mt-5 cursor-pointer'  >
                                <p>Membership Number</p>
                                <input placeholder='Insurance / Card / Account Number' className='border-b-2 border-gray-300 focus:outline-none focus:border-blue-500 transition w-full p-2' onChange={(e) => setMemberId(e.target.value)} />
                            </div>
                        </div>
                        : null}
                </div>

                {/**access code section */}
                <div className={`flex flex-row border-b-2 pb-2 ${isAccessCodeToggle ? 'border-b-emerald-300' : 'border-b-blue-300'} cursor-pointer w-[80%]`} onClick={() => handleSelectionRequest('accesscode')}>

                    {selectedSelection == 'accesscode' && isAccessCodeToggle ?
                        <div className='flex flex-row items-center w-full'>
                            <img src={accessCode_icon_green} className='h-10 w-10 mr-5' />
                            <p className='text-lg text-emerald-300 font-semibold'>I have an access code</p>
                            <img src={green_down} className='flex justify-center h-4 w-6 ml-auto mr-3 cursor-pointer' />
                        </div> :
                        <div className='flex flex-row items-center w-full'>
                            <img src={accessCode_icon_blue} className='h-10 w-10 mr-5' />
                            <p className='text-lg text-blue-600 font-semibold'>I have an access code</p>
                            <img src={blueTriangle} className='flex h-5 w-4 ml-auto mr-3 cursor-pointer' />
                        </div>}
                </div>


                {selectedSelection === 'accesscode' && isAccessCodeToggle ?
                    <div className='flex flex-col mt-5 w-[80%] h-10 rounded-xl'>
                        <input placeholder='Enter your access code here' className='input_design placeholder:text-gray-400' onChange={(e) => setAccesCode(e.target.value)} />
                    </div> : null}


                {/**shop section */}

                <div className={`flex flex-row items-center border-b-2 pb-2 ${isShopToggle ? 'border-b-emerald-300' : 'border-b-blue-300'} cursor-pointer w-[80%]`} onClick={() => handleSelectionRequest('shop')}>

                    {
                        selectedSelection == 'shop' && isShopToggle ?
                            <div className='flex flex-row items-center w-full'>
                                <img src={shop_green} className='h-10 w-10 mr-5' />
                                <p className='text-lg text-emerald-300 font-semibold'>I don't have a code or provider</p>
                                <img src={green_down} className='flex justify-center h-4 w-6 ml-auto mr-3 cursor-pointer' />
                            </div>
                            :
                            <div className='flex flex-row items-center w-full'>
                                <img src={shop_blue} className='h-10 w-10 mr-5' />
                                <p className='text-lg text-blue-600 font-semibold'>I don't have a code or provider</p>
                                <img src={blueTriangle} className='h-5 w-4 ml-auto mr-3 cursor-pointer' />
                            </div>}
                </div>

                {selectedSelection === 'shop' && isShopToggle ?
                    <div className='flex flex-col items-center mt-5 w-[80%]' onClick={(e) => {
                        e.preventDefault()
                        window.location.href = 'https://shop.medgate.ph/'
                    }}>
                        <p className='text-sm'>Visit our shop for more information about the services that is suited for you and your family.</p>
                        <button className='rounded-md bg-blue-600 h-10 w-72 text-white font-semibold hover:bg-blue-700 my-5'>Go to Shop</button>
                    </div> : null}


                <div className='flex flex-col items-center justify-center'>
                    <div className='flex flex-row mb-5 mt-3 w-[80%]'>
                        <input type='checkbox' onClick={() => setCoc(1)} onKeyDown={(e) => { if (e.key === "Enter") { handleVerificationRequest() } }} />
                        <p className='text-blue-600 text-sm ml-5'>I allow Medgate to record the conversation between me, its representative and doctors during my consultation.</p>
                    </div>

                    {isShowSpinner ? <Spinner /> : <div>{memberId !== '' && coc === 1 || accessCode !== '' && coc === 1 ? <button className='rounded-md dark-blue-bg h-10 w-32 text-white font-semibold hover:bg-blue-700' onClick={handleVerificationRequest}>Continue</button> : null}</div>}
                    {isShowModal ? <Index message={message} status={undefined} closeModal={() => setIsShowModal(false)} page='' email='' /> : null}
                </div>


            </CustomCard>
            <div className='bg-blue-600 h-16 w-full mt-auto'></div>

            {isConflict ? <VerificationConflict guid={guid} onCancel={() => { setIsConflict(false) }} /> : null}
        </div>
    )
}

export default Verification
