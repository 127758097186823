import React, { MouseEventHandler } from 'react'

interface Props {
    title: string
    style?: string
    handleOnclick?: MouseEventHandler<HTMLButtonElement>
    btnType?: "button" | "submit" | "reset"
    textStyles?: string
    isDisabled?: boolean
    imageSource?: string
}

const CustomButton = ({ title,
    style,
    handleOnclick,
    isDisabled,
    imageSource} : Props) => {
  return (
    <div>
      <button className={style} onClick={handleOnclick} disabled={isDisabled}>
    {imageSource === undefined ? null : (
      <img
        src={imageSource}
        alt={imageSource}
        height={30}
        width={40}
        className='flex justify-center items-center'
      />
    )}
    {title}
  </button>
    </div>
  )
}

export default CustomButton