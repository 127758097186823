import React, { useEffect, useRef } from 'react'
import StandardContainer from '../container/StandardContainer'

const Chat = ({ chatHistory } : {chatHistory? : any}) => {

  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatHistory]);

  function isJsonString(str:string) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

  return (
    <div className='flex flex-col w-full mb-5 min-w-[100%]' key='chatRoom'>
        <ul key='chatUl'>
          {[...chatHistory]?.sort((a, b) => a.id - b.id)?.map(item => {
            if(item?.user?.patient != null){
               {/**patient side*/}
              return <> <li key={item?.id} className='flex flex-col items-end mt-4 mb-6 ml-auto max-w-fit p-2'>
                  <div key={"chatDiv" + item.id} className="w-fit text-right bg-blue-500 rounded-xl p-2 px-5">
                    <p className='text-white whitespace-pre-wrap'>{isJsonString(item.message) ? JSON.parse(item.message) : item.message}</p>
                  </div>
                  {/**date and time */}
                  <p key={"chatP" + item.id} className='text-right bg-white text-black text-xs '>{new Date(item.created_at).toLocaleDateString('es-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit'})}</p>
              </li> </>
            }else{
               {/**tma  side*/}
              return <li key={item.id} className='flex flex-col items-start mt-4 mb-6 max-w-fit item p-2'>
                <div key={"chatDiv" + item.id} className="bg-gray-200 text-black rounded-xl p-2">
                    <p className='whitespace-pre-wrap'>{isJsonString(item.message) ? JSON.parse(item.message) : item.message}</p>
                </div>
              <p key={"chatP" + item.id} className='bg-white text-black text-xs'>{new Date(item.created_at).toLocaleDateString('es-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit'})}</p>
              </li>
            }
          })}
        </ul>
        <div ref={bottomRef}></div>
    </div>
  )
}

export default Chat
