import axios from 'axios'

const WEB_API = process.env.REACT_APP_API_URL
const SYMPTOM_CHECKER_URL = process.env.REACT_APP_SYMPTOM_CHECKER_API_URL as string;

export class SCRequests {

  fetchRequest = async (url: string) => {
    
    try {

      const fullUrl = SYMPTOM_CHECKER_URL + url

      const response = await axios.get(fullUrl)

      return response.data
    } catch (err) {
      console.log(err)

      return err
    }
  }

  postRequest = async (url: string, data: any) => {
    try {

      const fullUrl = SYMPTOM_CHECKER_URL + url

      const bearer = {
      }

      const response = await axios.post(fullUrl, data, bearer)

      return response.data
    } catch (err) {
      console.log(err)

      return err
    }
  }

  getRequestWithParams = async (url: string, data: any) => {
    try {

      const fullUrl = SYMPTOM_CHECKER_URL + url

      const response = await axios.get(fullUrl, data)

      return response.data
    } catch (err) {
      console.log(err)

      return err
    }
  }

  fetchRequests = async (endpoint : any , request : any) => {

    try {
      const bearer = {
        headers :  endpoint[request].headers
      }

      //const response = await axios.get(`${baseUrl}${endpoint}`, bearer)
      const response = await axios.get(endpoint[request].url, bearer)
      return response.data

    } catch (error : any) {
      console.log('error', error)
      return error
    }
  }


  postRequests = async (endpoint : any, data: any, request : any) => {

    console.log('postRequest', endpoint[request])
  
    try {

    const bearer = {
      headers: endpoint[request].headers
    }

      //const response = await axios.post(`${baseUrl}${endpoint}`, data, bearer)
      const response = await axios.post(endpoint[request].url, data, bearer)
      return response.data


    } catch (error : any) {

      console.log('error', error)
      return error
     
    }
  }

}
