import React, { MouseEventHandler, useEffect, useState } from 'react'

interface Props {
    title: string,
    style?: string,
    handleOnClick?: MouseEventHandler<HTMLButtonElement>,
    imageSource?: string,
    isDisabled?: boolean
}

const SCButton = ({ title,
    style,
    handleOnClick,
    isDisabled,
    imageSource} : Props) => {
    const [selectedId, setSelectedId] = useState([])
    const [scaleValue, setScaleValue] = useState("")
    var classes = style;
    if(isDisabled) {
        classes = style + " disabled bg-gray-400 hover:bg-gray-400"
    }

  return (
      <button className={classes} onClick={handleOnClick} type="button" disabled={isDisabled}>
        {imageSource === undefined ? null : (
          <img
            src={imageSource}
            alt={imageSource}
            height={30}
            width={40}
            className='flex justify-center items-center'
          />
        )}
    {title}
  </button>
  )
}

export default SCButton
