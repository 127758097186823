import React, { useState, useEffect } from 'react'
import { User } from '../classes/model/User'
import { ILoginResponse } from '../classes/interface/ILogin'
import CustomButton from './buttons/CustomButton'
import { Link, useNavigate } from 'react-router-dom'
import Collapsible from './Collapsible'
import Spinner from './spinner/Spinner'
import backgroundImage from '../assets/home-banner.png'

import { getFirebaseToken } from '../hooks/getFirebaseToken'
import { validateEmail } from '../classes/validator/Validator'
import Index from './modals/Index'
import CustomCard from './container/CustomCard'
import NotificationPermission from './modals/NotificationPermission'
import eye from '../assets/eye.png'
import eyeWithLine from '../assets/eye-with-line.png'
import useEnterKey from '../classes/EnterFunction'


const Login = () => {

  const navigate = useNavigate();

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isShowloading, setIsShowLoading] = useState(false)
  const [isErrorMessage, setIsErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');
  const [noticationPermissionIsVisible, setNoticationPermissionIsVisible] = useState<boolean>(false);
  const [isShowPassword, setIsShowPassword] = useState(false)

  const user = new User()


  const handleLoginRequest = async (skipToken: boolean) => {

    let device_token;
    let viewPopup = false;

    localStorage.removeItem('temp_api_token');
    localStorage.removeItem('email');
    localStorage.removeItem('fromPath');
    localStorage.removeItem('api_token');

    if (!skipToken) {
      let firebase_token = localStorage.getItem('firebase_token');
      if (firebase_token != null && firebase_token != '') {
        device_token = JSON.parse(firebase_token);
      } else {
        setNoticationPermissionIsVisible(true);
        viewPopup = true;
      }
    } else {
      device_token = '';
      setNoticationPermissionIsVisible(false);
    }

    if (viewPopup) {
      return;
    }


    if (validateEmail(username) && password.length != 0) {

      setIsShowLoading(true)

      const response = await user.login({username: username, password: password, device_token: device_token, device_type: 'web', type: 'patient'}, 'login_endpoint')
      console.log('response', response)

      if (response.message === 'success') {
          localStorage.setItem('fromPath', JSON.stringify('/login'))
          localStorage.setItem('email', JSON.stringify(username))

         if (response.data.user.email_verified_at === null) {

          const resendOtpRequest = await user.resendOTP("resendOtp_endpoint", "password_change", false);
           
           if (resendOtpRequest.message === "success") {
              navigate('/otp')
           } else if (response.hasOwnProperty('message')) {
             setIsShowLoading(false)
             setErrorMessage(`${resendOtpRequest.message}.`)
             setIsErrorMessage(true)
           } else {
             setIsShowLoading(false)
             setErrorMessage('Unexpected Error Occured')
             setIsErrorMessage(true)
          }
         } else {
          console.log('goes to verification page')
          navigate('/verification')

         }
      }else{
        setIsShowLoading(false)
        setErrorMessage(response.message)
        setIsErrorMessage(true)
      }
    } else {
      setIsShowLoading(false)
      setErrorMessage('Invalid Username or Password')
      setIsErrorMessage(true)
    }
  }

  const asyncGetFirebaseToken = async () => {
    let firebaseToken = await getFirebaseToken()
    if (firebaseToken) {
      console.log('firebase:', firebaseToken);
      localStorage.setItem('firebase_token', JSON.stringify(firebaseToken))
    } else {
      console.log('Unable to get Firebase token')
    }
  }

  useEffect(() => {
    localStorage.removeItem('firebase_token');
    asyncGetFirebaseToken();
    return () => {
      localStorage.removeItem('firebase_token');
    }
  }, [])

    useEnterKey(() => handleLoginRequest(false));

  return (
    <div className='flex flex-col min-h-screen bg-cover bg-center w-full' style={{ backgroundImage: `url(${backgroundImage})` }}>

      <CustomCard className='flex flex-row items-center justify-center my-[10%] ml-[20%] w-1/4 min-w-[28rem] space-x-2 bg-white rounded-[4rem] pb-5 pt-5'>

        <div className='flex flex-col justify-center items-center w-[80%] mt-5'>
          <p className='text-4xl text-blue-500 font-bold'>Welcome!</p>

          <div className='flex flex-col space-y-7 w-full mt-5' >
            <input className='input_design' placeholder='Email' onChange={(e) => setUsername(e.target.value)} onKeyDown={(e) => { if (e.key === "Enter") { handleLoginRequest(false); } }} />

            {/**<input className='input_design' type='password' placeholder='Password' onChange={(e) => setPassword(e.target.value)} onKeyDown={(e) => { if (e.key === "Enter") { handleLoginRequest(false); } }} />*/}

            <div className='flex flex-row w-full !outline-none border-b-2 pb-2'>
              <input className='w-full !outline-none placeholder:text-black' type={isShowPassword ? 'text' : 'password'} placeholder='Password' onChange={(e) => setPassword(e.target.value)} onKeyDown={(e) => { if (e.key === "Enter") { handleLoginRequest(false); } }}/>
              {isShowPassword ? <img src={eyeWithLine} className='h-7 w-7 cursor-pointer' onClick={() => setIsShowPassword(!isShowPassword)}/> : <img src={eye} className='h-7 w-7 cursor-pointer' onClick={() => setIsShowPassword(!isShowPassword)}/> }
            </div>
          </div>

          {isShowloading ? <Spinner /> :

            <div className='flex flex-row items-center justify-center space-x-5 mt-10'>
              <button className={`${username && password ? 'rounded-full bg-custom-blue h-12 w-40 text-white font-semibold hover:bg-custom-blue-dark' : 'rounded-full bg-gray-200 h-12 w-40 text-gray-400 font-semibold cursor-not-allowed'}`}  onClick={() =>  username && password ?  handleLoginRequest(false) : null } >Login</button>
              <button className='rounded-full bg-blue-200 h-12 w-40 text-blue-700 font-semibold hover:bg-blue-300' onClick={() => navigate('/')}>Cancel</button>
            </div>
          }
          <div className='mt-5 mb-5 mr-auto'>
            <Link
              className='text-blue-500 hover:border-b hover:border-blue-500'
              to={'/forgot-password'}>
              Forgot Password?
            </Link>
          </div>

          <div className='flex flex-row mr-auto mb-5'>
            <p>Not a member yet?</p>
            <Link
              className='text-blue-500 hover:border-b hover:border-blue-500 ml-2 underline'
              to={'/registration'}>
              Create your Account
            </Link>
          </div>
        </div>
      </CustomCard>
      <div className='flex mt-auto bg-blue-600 h-16 w-full'></div>
      {isErrorMessage ? <Index message={errorMessage} status={undefined} closeModal={() => setIsErrorMessage(false)} page={undefined} email={undefined} /> : null}

      {
        noticationPermissionIsVisible ?
          <NotificationPermission
            onCancel={() => { setNoticationPermissionIsVisible(!noticationPermissionIsVisible); }}
            type='login'
            onFacebookSuccess={() => { }}
            onFacebookError={() => { }}
            onGoogleContinue={() => { }}
            onEmailContinue={() => { }}
            onLoginContinue={ () => { document.location.reload() } }
          />
          : null
      }
    </div>
  )
}

export default Login
