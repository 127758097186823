import React, { useEffect, useState } from 'react'
import { OrganSystem } from '../../classes/requests/symptom_checker/OrganSystem'
import { ChiefComplaint } from '../../classes/requests/symptom_checker/ChiefComplaint'
import { Emergency } from '../../classes/requests/symptom_checker/Emergency'
import { LinearPath } from '../../classes/requests/symptom_checker/LinearPath'
import { IRecommendation } from '../../classes/interface/symptom_checker/IRecommendation'
import SCSelect from './SCSelect'
import SCQuestionnaire from './SCQuestionnaire'
import SCButton from './SCButton'
import SymptomCheckerVariables from '../../global/SymptomChecker'
import StandardContainer from '../container/StandardContainer'
import CustomCard from '../container/CustomCard'

import { OtherRequest } from '../../classes/model/OtherRequest'
import { IHotlineResponse } from '../../classes/interface/IHotlines'
import SCHotlines from './SCHotlines'
import back_arrow from '../../assets/Back.png'
import { useNavigate } from 'react-router-dom'

import useEnterKey from '../../classes/EnterFunction'
import Error from '../../components/modals/Error'
import BackButton from '../BackButton'
import { Case } from '../../classes/model/Case'

const LANGUAGE = "eng";
const INFORMATION_CONFIRMATION_TEXT = "I confirm that the information provided is accurate and correct."

const SymptomChecker = () => {

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false)
    const [organSystems, setOrganSystems] = useState([])
    const [selectedOrganSystem, setSelectedOrganSystem] = useState(0)
    const [chiefComplaints, setChiefComplaints] = useState<any[]>([])
    const [selectedChiefComplaint, setSelectedChiefComplaint] = useState(0)
    const [selectedChiefComplaintId, setSelectedChiefComplaintId] = useState("")
    const [sessionId, setSessionId] = useState("")
    const [emergencyData, setEmergencyData] = useState<any[]>([])
    const [emergencyQuestions, setEmergencyQuestions] = useState<any[]>([])
    const [emergencyAnswers, setEmergencyAnswers] = useState<any[]>([])
    const [emergencyLength, setEmergencyLength] = useState(0)
    const [submitEmergencyDisabled, setSubmitEmergencyDisabled] = useState(true)
    const [qaData, setQaData] = useState<any[]>([])
    const [qaLength, setQaLength] = useState(0)
    const [isRecommendation, setIsRecommendation] = useState(false)
    const [isEmergency, setIsEmergency] = useState(false)
    const [answers, setAnswers] = useState<any[]>([])
    const [questions, setQuestions] = useState<any[]>([])
    const [submitQaDisabled, setSubmitQaDisabled] = useState(true)
    const [recommendationData, setRecommendationData] = useState<any>([])
    const [isInformationTicked, setIsInformationTicked] = useState(false)
    const [hotlines, setHotlines] = useState<any>()
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const caseRequest = new Case()

    useEffect(()=>{
        localStorage.setItem("sessionId", "")
        getOrganSystems()
        getHotlines()
    },[])

    const getHotlines = async () => {

         const others = new OtherRequest()
         const response: IHotlineResponse = await others.getHotlines('getHotlines_endpoint')
         console.log('list of numbers', response)

         setHotlines(response)
    }

     const getOrganSystems = async () => {
         const organSystemRequest = new OrganSystem()
         setOrganSystems(await organSystemRequest.getAll())
     }

     const getChiefComplaints = async (value: string) => {
         const chiefComplaintRequest = new ChiefComplaint()
         var params = value
         setChiefComplaints(await chiefComplaintRequest.get(params))
     }

     function selectOrganSystem(value: string) {
         console.log(selectedChiefComplaint)
         setSelectedOrganSystem(Number(value))
         if(Number(value) != 8) {
             getChiefComplaints(value)
         } else {
             setChiefComplaints([])
             initiateEmergency()
         }
     }

     function selectChiefComplaint(value: string) {
         let index = 0;

         chiefComplaints.forEach(function(chiefComplaint, i) {
             if(chiefComplaint.id == value) {
                 index = i
             }
         })

         setSelectedChiefComplaint(chiefComplaints[index].name)
         setSelectedChiefComplaintId(chiefComplaints[index].id)
         initiateEmergency()
     }

     const initiateEmergency = async () => {

        const emergencyRequest = new Emergency()
        var result = await emergencyRequest.initialize("initialize_emergency_endpoint")
        var data = result.data

        setEmergencyData(data)
        setEmergencyLength(getFilteredLength(data))
        setSessionId(result.session_id)
        setIsLoading(false)
    }

    function selectEmergencyAnswer(index: number, question: string, answer: string, isTerminator = 0) {

        var tempAnswers = emergencyAnswers
        tempAnswers[index] = answer
        var tempQuestions = emergencyQuestions
        tempQuestions[index] = question

        setEmergencyQuestions(tempQuestions)
        setEmergencyAnswers(tempAnswers)

        if(isTerminator == 1) {
            submitEmergencyAnswers()
        }

        if (filterCountableQuestions(tempQuestions) == emergencyLength) {
            setSubmitEmergencyDisabled(false)
        }
    }

    function selectAnswer(index: number, question: string, answer: string, isTerminator = 0) {

        var tempArray = answers
        tempArray[index] = answer
        var tempQuestionArray = questions
        tempQuestionArray[index] = question

        setAnswers(tempArray)
        setQuestions(tempQuestionArray)

        var tempAnswers = answers.filter(function( element ) {
           return element !== undefined
        })

        if (tempAnswers.length == qaLength) {
            if(isInformationTicked) {
                setSubmitQaDisabled(false)
            } else {
                setSubmitQaDisabled(true)
            }
        }
    }

    const submitEmergencyAnswers = async () => {

        const emergencyRequest = new Emergency()

        var tempEmergencyQuestions = emergencyQuestions.filter(function( element ) {
           return element !== undefined
        })

        var tempEmergencyAnswers = emergencyAnswers.filter(function( element ) {
           return element !== undefined
        })

        var params = {
            "questions": tempEmergencyQuestions,
            "answers": tempEmergencyAnswers,
            "sub_path_id": selectedChiefComplaintId,
            "session_id": sessionId
        }

        var result = await emergencyRequest.submit(params, 'submit_emergency_endpoint')

        if(result.is_recommendation == false) {
                initiateQa()
        } else {
            handleRecommendation(result)
        }
        setIsLoading(false)
    }

    const initiateQa = async () => {

        const linearPathRequest = new LinearPath()

        var params = {
            "path_id": selectedOrganSystem,
            "session_id": sessionId
        }

        var result = await linearPathRequest.initialize(params, "initialize_endpoint")

        if(result.data != undefined && result.data != null) {
            if(result.data.length == 0) {
                submitQa()
            } else {
                setQaData(result.data)
                setQaLength(getFilteredLength(result.data))
                window.scrollTo(0, 0)
            }
        }

        setIsLoading(false)
    }

    const submitQa = async () => {

        const linearPathRequest = new LinearPath()

        //Remove undefined from arrays
        var tempQuestions = questions.filter(function( element ) {
           return element !== undefined
        })

        var tempAnswers = answers.filter(function( element ) {
           return element !== undefined
        })

        var params = {
            "sub_path_id": selectedChiefComplaintId,
            "questions": tempQuestions,
            "answers": tempAnswers,
            "session_id": sessionId
        }

        var result = await linearPathRequest.submit(params, "submit_endpoint")

        handleRecommendation(result)
        setIsLoading(false)
    }

    const closeSymptomChecker = () => {
      window.location.href = "/dashboard";
    };

    const callMedgate = async () => {
        const response = await caseRequest.getAppointmentList('getAppointment_list_endpoint');

        if(response.length !== 0){

         const result =  response.data.filter((record: { status: string }) => ('new' === record.status || 'ongoing' === record.status))
         console.log('result', result)

          if(result.length !== 0){
              setErrorMessage('You still have an active consultation on queue. One of our agents will get in touch with you soon. Please be patient')
              setShowErrorModal(true)
          }else{
            navigate('/type')
            localStorage.setItem("sessionId", sessionId)
            window.location.href = "/type";
          }

        }
    };

    function filterCountableQuestions(questions: any[]) {
        var count = 0
        emergencyData.forEach(function(emergency) {
            emergency.questions.forEach(function(emergencyQuestion: any) {
                questions.forEach(function(question) {
                    if(emergencyQuestion.id == question) {
                        if(emergency.inquiry_type_id != 6) {
                            count++;
                        }
                    }
                })
            });
        });

        return count;
    }

    function getFilteredLength(data: any[]) {
        var lengthCounter = 0

        data.forEach(function(item) {
            if(item.inquiry_type_id != 6) lengthCounter++
        })

        return lengthCounter
    }

    function handleRecommendation(recommendation: IRecommendation) {

        setIsRecommendation(true)
        if(recommendation.is_emergency) {
            setIsEmergency(recommendation.is_emergency)
        }
        setRecommendationData(recommendation.data)
    }

    function handleInformationTicked(value: boolean) {
        setIsInformationTicked(value)

        var tempAnswers = answers.filter(function( element ) {
           return element !== undefined
        })

        if (tempAnswers.length == qaLength) {

            if(value) {
                setSubmitQaDisabled(false)
            } else {
                setSubmitQaDisabled(true)
            }
        }
    }

    useEnterKey(() => {

        if(showErrorModal) {
            setShowErrorModal(false)
        } else {
            var tempQuestions = emergencyQuestions

            if(!isRecommendation) {

                if(emergencyData.length != 0 && qaData.length == 0) {
                    if(!submitEmergencyDisabled) {
                        submitEmergencyAnswers()
                        return
                    }
                } else {
                    if(!submitQaDisabled) {
                        submitQa()
                        return
                    }
                }

                setErrorMessage("Please fill out all items")
                setShowErrorModal(true)
            }
        }
    });

    function formatRecommendationText(text:string) {
        var textArray:any = []

        if(text.includes("chat")) {
            textArray = text.split("chat");
        }

        return (
            <p className='text-3xl text-blue-500 font-semibold mb-5 w-full text-center'>
                {textArray[0]}
                <a href="/chat" className="underline">chat</a>
                {textArray[1]}
            </p>
        )
    }

    return (
        <StandardContainer>

        <BackButton route='/symptom'/>

        <div className="text-left w-[80%]"><p className='text-4xl text-blue-500 font-bold'>Symptom Checker</p></div>

        <CustomCard className='flex flex-col items-center justify-center pb-10 w-1/2 mt-10 space-x-2 bg-white rounded-lg pt-5 w-[80%]'>

          <div className='flex flex-col h-full w-[80%] mt-5 space-y-8'>

            {
                isRecommendation == true ?
                (
                    isEmergency == true ? (
                        <div className="flex flex-col items-center justify-center">
                            { formatRecommendationText(recommendationData.content)}

                            <SCHotlines hotlines={hotlines}/>

                            <div className="flex flex-1 flex-row w-[100%] items-center justify-center space-x-5 mb-5">
                                <SCButton title={"Close"} handleOnClick={() => {closeSymptomChecker()}} style="rounded-3xl h-12 px-[3rem] mt-5 font-bold text-white bg-blue-600 border-gray-300 rounded-2xl focus:ring-blue-500 hover:bg-gray-400 hover:text-gray-100"/>
                            </div>

                        </div>
                    )
                    :
                    (
                        recommendationData.id != 3 ?
                            (   <div className="flex flex-col items-center justify-center">
                                <p className='text-l text-blue-600 font-semibold text-center'>{recommendationData.content}</p>

                                <div className="flex flex-1 flex-row w-[100%] items-center justify-center space-x-5 mt-5">
                                    <SCButton title={recommendationData.text} handleOnClick={() => {callMedgate()}} style="rounded-3xl h-12 px-[3rem] min-w-[18rem] font-semibold hover:bg-blue-700 text-white bg-blue-600"/>
                                </div>

                                <p className='text-l text-blue-600 font-semibold mt-5 w-[100%] text-center'>Schedule a Call with a Telemedical Assistant to Confirm your Doctor's Appointment</p>

                                <div className="flex flex-1 flex-row w-[100%] items-center justify-center space-x-5 mb-5">
                                    <SCButton title={"Close"} handleOnClick={() => {closeSymptomChecker()}} style="rounded-3xl h-12 px-[3rem] min-w-[18rem] mt-5 font-semibold text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 hover:bg-gray-400 hover:text-gray-100"/>
                                </div>

                            </div>  )
                            :
                            (
                                <div className="flex flex-col items-center justify-center">
                                    {/* replace chat with a button link here */}
                                    { formatRecommendationText(recommendationData.content)}

                                    <SCHotlines hotlines={hotlines}/>

                                    <div className="flex flex-1 flex-row w-[100%] items-center justify-center space-x-5 mb-5">
                                        <SCButton title={"Close"} handleOnClick={() => {closeSymptomChecker()}} style="rounded-3xl h-12 px-[3rem] mt-5 font-bold text-white bg-blue-600 border-gray-300 rounded-2xl focus:ring-blue-500 hover:bg-gray-400 hover:text-gray-100"/>
                                    </div>

                                </div>
                            )
                    )
                )
                :
                (
                    <div className='flex flex-col h-full'>

                      <div className='flex flex-col'>
                          <p className='text-xl text-blue-600 font-semibold'>What organ system or body part do you have concern/s with?</p>
                          <SCSelect defaultText={"Choose an Organ System"} data={organSystems} onSelect={(value) => selectOrganSystem(value)}/>
                      </div>

                      {
                          chiefComplaints.length != 0 ?
                          <div className='flex flex-col'>
                              <p className='text-xl text-blue-600 font-semibold mt-5'>What is your concern on the selected organ system?</p>
                              <SCSelect defaultText={"Choose a Health Concern"} data={chiefComplaints} onSelect={(value) => selectChiefComplaint(value)}/>
                          </div>
                           : null
                      }

                      {
                          (selectedChiefComplaint != 0 || selectedOrganSystem == 8)?
                            <p className='text-blue-600 my-5'> Answer either <span className='font-bold mt-5'>YES, NO, or NOT SURE</span> if your are experiencing the sypmtoms relating to your concern.</p>
                          : null
                      }

                      {
                          (emergencyData.length != 0 && qaData.length == 0) ?
                              <SCQuestionnaire language={LANGUAGE} data={emergencyData} onSelect={(index, question, answer, isTerminator) => selectEmergencyAnswer(index, question, answer, isTerminator)}/>
                           : null
                      }

                      {
                          (emergencyData.length != 0 && qaData.length != 0) ?
                          (<>
                              <SCQuestionnaire language={LANGUAGE} data={qaData} onSelect={(index, question, answer, isTerminator) => selectAnswer(index, question, answer, isTerminator)}/>
                              <div className="flex flex-row items-center justify-center mb-5">
                                  <input id="default-checkbox" type="checkbox" onChange={(e) => handleInformationTicked(e.target.checked)} checked={isInformationTicked} className="flex w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                  <label htmlFor="default-checkbox" className="flex text-blue-600 font-semibold text-l ml-5">{INFORMATION_CONFIRMATION_TEXT}</label>
                              </div>
                          </>)
                          : null
                      }

                      {
                          (emergencyData.length != 0 && qaData.length == 0) ?
                              <div className="flex flex-col items-center justify-center">
                                  <div className="flex flex-1 flex-row w-[100%] items-center justify-center space-x-5 mb-5">
                                      <SCButton isDisabled={submitEmergencyDisabled} title={"Next"} handleOnClick={() => {submitEmergencyAnswers()}} style="rounded-3xl h-12 px-[3rem] font-semibold hover:bg-blue-700 text-white bg-blue-600"/>
                                  </div>
                              </div>
                          : null
                      }

                      {
                          (qaData.length != 0) ?
                              <div className="flex flex-col items-center justify-center">
                                  <div className="flex flex-1 flex-row w-[100%] items-center justify-center space-x-5 mb-5">
                                      <SCButton isDisabled={submitQaDisabled} title={"Submit"} handleOnClick={() => {submitQa()}} style="rounded-3xl h-12 px-[3rem] font-semibold hover:bg-blue-700 text-white bg-blue-600"/>
                                  </div>
                              </div>
                          : null
                      }

                    </div>
                )
            }

          </div>
        </CustomCard>

        {
            (showErrorModal) ?
                <Error message={errorMessage} closeModal={() => {setShowErrorModal(false)}} />
            : null
        }

        </StandardContainer>
    )
}

export default SymptomChecker
