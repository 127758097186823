import { ILoginRequest } from "../../interface/ILogin";
//import { requests } from '../../../utils/requests'
import { ISocialLogin } from "../../interface/ISocialLogin";
import { User } from "../../model/User";
import { requests } from "../Api";
import { endpoints } from "../Endpoint";

const USER_LOGIN_ENDPOINT = process.env.REACT_APP_RDP_API_USER_LOGIN_ENDPOINT as string
const USER_LOGOUT_ENDPOINT = process.env.REACT_APP_RDP_API_USER_LOGOUT_ENDPOINT as string
const API_SOCIAL_LOGIN_ENDPOINT = process.env.REACT_APP_RDP_API_SOCIAL_LOGIN as string

export class Login extends requests {

    token = JSON.parse(localStorage.getItem("api_token") || '{}')

    async login({ username, password, device_token, device_type, type  }: ILoginRequest, request : any) {
        
        const endpoint = endpoints(request, "POST", false, null);

        const data: ILoginRequest = {
            username: username,
            password: password,
            device_token: device_token,
            device_type: device_type,
            type: type
        }

        console.log('login data: ', data);
        console.log('login request', request)

        let response = await this.postRequest(endpoint, data, request);

        console.log('login response', response)

        if (response?.message === 'success') {
            // Save token in local storage
            //localStorage.setItem("api_token", JSON.stringify(response.data.token.accessToken))
            localStorage.setItem("temp_api_token", JSON.stringify(response.data.token.accessToken))

            // Save user info in local storage
            //localStorage.setItem("user", JSON.stringify(response.data.user))
            localStorage.setItem("temp_user", JSON.stringify(response.data.user))

        }

        return response;
    }

    async logout(device_token: any, request: any){

        const endpoint = endpoints(request, "POST", true, null);
        let response = await this.postRequest(endpoint, {device_token: device_token}, request);
        return response;

        /*let response = await this.postRequest(USER_LOGOUT_ENDPOINT, null, this.token);

        return response;*/

    }

    async loginSocial ( {device_token, device_type, token, login_type}: ISocialLogin, request: any ) {

        if (!login_type || login_type == null) {
            return false;
        }

        const endpoint = endpoints(request, "POST", false, login_type);

        const data: ISocialLogin = {
            device_token: device_token,
            device_type: device_type,
            token: token,
            login_type: login_type
        }

        //let response = await this.postRequest(url, data, this.token);

       // console.log("login.ts response", response)
        let response = await this.postRequest(endpoint, data, request);

        console.log('social login:', response);

        if (response?.message === 'success') {
            // Save token in local storage
            localStorage.setItem("api_token", JSON.stringify(response.data.token.accessToken))

            // Save user info in local storage
            localStorage.setItem("user", JSON.stringify(response.data.user))
        }

        return response;
    }
}