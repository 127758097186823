import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateRoutes = () => {
    const token = JSON.parse(localStorage.getItem('api_token') || 'null')
    const user = JSON.parse(localStorage.getItem('user') || 'null')

    return (
      token !== null ? 
        <>
          {
            user !== null ? <>
              {
                user.email_verified_at === null ? 
                  <Navigate to="/otp"/> : 
                <>
                  {
                    user.insurance_number == null || user.insurance_number == '' ? 
                    <Navigate to="/verification" /> :
                    <Outlet/>
                  }
                </>
              }
            </> : <Navigate to="/"/>
          }
        </>
      : <Navigate to="/"/>
      // 
    )
}

export default PrivateRoutes