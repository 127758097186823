import { config } from "../Config";

export let endpoints = (request : any, method : any, isWithAuthorization : boolean, parameters : any) => {

    let headers = null
    let token = ''

    if (isWithAuthorization) {
        const storedToken = localStorage.getItem("api_token");
        token = storedToken ? JSON.parse(storedToken) : '';

        if(token === ''){
            const storedTempToken = localStorage.getItem("temp_api_token");
            token = storedTempToken ? JSON.parse(storedTempToken) : '';
        }
    }

    const getAuthHeader = () => {
        console.log('isWithAuthorization', isWithAuthorization)
        return isWithAuthorization ? `Bearer ${token}` : null;
      };

    if(method === "POST"){
        headers =  {
           'Authorization' : getAuthHeader(),
           'Content-Type': 'application/x-www-form-urlencoded',
           "Access-Control-Allow-Origin": "*"
          };
    }else{
        headers = {
            Authorization: `Bearer ${token}`,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
            "Access-Control-Allow-Origin": "*"
          }
    }


    return {

        login_endpoint  : {
            url: config.BASE_URL + "/api/v1/user/login",
            method: "POST",
            headers: headers,
        },

        registration_endpoint  : {
            url: config.BASE_URL + "/api/v1/user/register",
            method: "POST",
            headers: headers,
        },

        updateEmail_endpoint  : {
            url: config.BASE_URL + "/api/v2/user/update_emails",
            method: "POST",
            headers: headers,
        },

        updateContactNumber_endpoint  : {
            url: config.BASE_URL + "/api/v2/user/update_phones",
            method: "POST",
            headers: headers,
        },

        getUserDetails_endpoint  : {
            url: config.BASE_URL + "/api/v1/user/details/" + parameters,
            method: "GET",
            headers: headers,
        },

        /** Eligibility */
        validateProvider_endpoint  : {
            url: config.BASE_URL + "/api/v2/eligibility/check",
            method: "POST",
            headers: headers,
        },

        mergePatientDetails_enpoint: {
            url: config.BASE_URL + "/api/v2/user/confirm/replace",
            method: "POST",
            headers: headers,
        },
        /** Eligibility */


        getProviders_endpoint  : {
            url: config.BASE_URL + "/api/v2/eligibility/providers",
            method: "GET",
            headers: headers,
        },

        validate_otp_endpoint  : {
            url: config.BASE_URL + "/api/v2/user/otp/validate",
            method: "POST",
            headers: headers,
        },

        getHotlines_endpoint  : {
            url: config.BASE_URL + "/api/v2/eligibility/contacts",
            method: "GET",
            headers: headers,
        },

        getAppointment_list_endpoint : {
            url: config.BASE_URL + "/api/v1/case/consultation",
            method: "GET",
            headers: headers,
        },

        /**Chat section*/

        createChatroom_endpoint : {
            url: config.BASE_URL + "/api/v1/chat/room",
            method: "POST",
            headers: headers,
        },

        getChatroom_endpoint : {
            url: config.BASE_URL + "/api/v1/chat/room",
            method: "GET",
            headers: headers,
        },

        getChat_history_endpoint : {
            url: config.BASE_URL + `/api/v1/chat?chat_room_slug=${parameters}`,
            method: "GET",
            headers: headers,
        },

        sendChat_endpoint : {
            url: config.BASE_URL + "/api/v1/chat",
            method: "POST",
            headers: headers,
        },


        /**appointment*/


        getConcerns_endpoint : {
            url: config.BASE_URL + "/api/v2/eligibility/health_concerns",
            method: "GET",
            headers: headers,
        },

        getDays_Slot_endpoint : {
            url: config.BASE_URL + "/api/v2/eligibility/days",
            method: "GET",
            headers: headers,
        },

        getTime_Slot_endpoint : {
            url: config.BASE_URL + `/api/v2/eligibility/times?date=${parameters}`,
            method: "GET",
            headers: headers,
        },

        setAppointment_endpoint : {
            url: config.BASE_URL + "/api/v2/eligibility/case",
            method: "POST",
            headers: headers,
        },


        uploadImage_endpoint : {
            url: config.BASE_URL + "/api/v2/eligibility/case/add_image",
            method: "POST",
            headers: headers,
        },

        getCase_details_endpoint : {
            url: config.BASE_URL + `/api/v2/case/${parameters}`,
            method: "POST",
            headers: headers,
        },


        /**password section */

        forgotPassword_endpoint : {
            url: config.BASE_URL + "/api/v1/password/reset",
            method: "POST",
            headers: headers,
        },

        codeValidation_endpoint  : {
            url: config.BASE_URL + "/api/v1/password/code/validate",
            method: "POST",
            headers: headers,
        },

        changePassword_endpoint  : {
            url: config.BASE_URL + "/api/v1/password/change",
            method: "POST",
            headers: headers,
        },

        updatePassword_endpoint  : {
            url: config.BASE_URL + "/api/v2/user/update/password",
            method: "POST",
            headers: headers,
        },

        resendOtp_endpoint  : {
            url: config.BASE_URL + "/api/v2/user/otp/resend",
            method: "POST",
            headers: headers,
        },


        /*Others section */

        downloadFile_endpoint  : {
            url: config.BASE_URL + `/api/v2/download/documents?url=${parameters}`,
            method: "GET",
            headers: headers,
        },

        forceDownloadFile_endpoint  : {
            
            url: config.BASE_URL + `/api/v2/force_download/documents?filename=${parameters}`,
            method: "GET",
            headers: headers,
        },

        getAssets_endpoint  : {
            url: config.BASE_URL + `/api/v2/asset?category=${parameters}&full_data=true`,
            method: "POST",
            headers: headers,
        },


        /**sypmtom checker section */
        initialize_endpoint  : {
            url: config.SYMPTOM_CHECKER_URL + `/inquiry/init_linear?path_id=${parameters}`,
            method: "GET",
            headers: headers,
        },

        submit_endpoint  : {
            url: config.SYMPTOM_CHECKER_URL + `/inquiry/submit_linear`,
            method: "POST",
            headers: headers,
        },

        update_case_history_endpoint : {
            url: config.SYMPTOM_CHECKER_URL + `/inquiry/update_history_case`,
            method: "POST",
            headers: headers,
        },

        initialize_emergency_endpoint : {
            url: config.SYMPTOM_CHECKER_URL + `/inquiry/init_emergency_questions`,
            method: "GET",
            headers: headers,
        },

        submit_emergency_endpoint : {
            url: config.SYMPTOM_CHECKER_URL + '/inquiry/submit_emergency_questions',
            method: "POST",
            headers: headers,
        },

        /**social */
        social_login :{
            url: config.BASE_URL + `/api/v1/social/${parameters}`,
            method: "POST",
            headers: headers,
        },

        log_out: {
            url: config.BASE_URL + `/api/v2/user/logout`,
            method: "POST",
            headers: headers,
        }

    }

}
