import React, { MouseEventHandler, useEffect, useState } from 'react'
import SCButton from './SCButton'
import '../../assets/styles/SymptomChecker.css'
import painScaleSmileys from '../../assets/pain_scale_smileys_trans.png'

interface Props {
    index: number,
    data: any,
    minValue?: number | 0,
    maxValue?: number | 100,
    inquiryType?: number,
    onSelect: (
        index: number,
        question: string,
        answer: string,
        isTerminator: number
    ) => void
}

const SCQuestionItem = ({
    data,
    onSelect,
    index,
    minValue,
    maxValue,
    inquiryType
    } : Props) => {

    const [selectedId, setSelectedId] = useState([])
    const [scaleValue, setScaleValue] = useState(minValue)

    function customScaleText(minValue?: number | 0, maxValue?: number | 100) {
        let arr = [];
        let ctr = minValue || 0 // cause typescript is being difficult
        let max = maxValue || 10

        for(ctr; ctr <= max; ctr++) {
            let color = '#ED' + ctr + 'EBF';
            arr.push(
                <div className="flex flex-1 flex-col" key={ctr}>
                    <div className="flex flex-1 flex-row">
                        <span className="flex-1"></span>
                        <div className="scaleVerticalLine border-blue-600"></div>
                    </div>
                    <span className="scaleText text-blue-600">{ctr}</span>
                </div>
            )
        }

        return arr;
    }

    const answersdiv = () => {
        if(inquiryType == 6) {

            if(data.id == 12) {

                //handle pain scale
                return (
                    <div className='flex flex-1 flex-col w-[80%] items-center justify-center mt-5 mb-5'>
                        <img src={painScaleSmileys} className='flex w-[100%] h-full mb-2'/>
                        <input id="default-range" type="range" className="slider w-full bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                        min={minValue}
                        max={maxValue}
                        value={scaleValue}
                        onChange={(e) => {
                            setScaleValue(Number(e.target.value))
                            if(e.target.value >= data.er_threshold) {
                                onSelect(index, data.id, e.target.value, 1)
                            } else {
                                onSelect(index, data.id, e.target.value, 0)
                            }
                        }}/>
                        <div className="scaleTextContainer w-[100%]">
                        {customScaleText(minValue, maxValue)}
                        </div>
                    </div>
                );

            } else {
                return (
                    <div className='flex flex-1 flex-row w-[100%] items-center justify-center space-x-5 mt-5 mb-5'>
                        <input id="default-range" type="range" className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                        min={minValue}
                        max={maxValue}
                        value={scaleValue}
                        onChange={(e) => {
                            setScaleValue(Number(e.target.value))
                            if(e.target.value >= data.er_threshold) {
                                onSelect(index, data.id, e.target.value, 1)
                            } else {
                                onSelect(index, data.id, e.target.value, 0)
                            }
                        }}/>
                    </div>
                );
            }

        } else {
            return (
                <div className='flex flex-1 flex-row w-[100%] items-center justify-center space-x-5 mt-5 mb-5'>
                    {
                        data.answers.map((item: any) => {
                            const backgroundColor = item.id === selectedId ? 'bg-blue-600' : 'bg-gray-50';
                            const color = item.id === selectedId ? 'text-white' : 'text-blue-600';
                            const hover = item.id === selectedId ? 'hover:bg-blue-700' : 'hover:bg-blue-300';

                            return <SCButton title={item.text} style={(hover) + " " + (color) + " " + (backgroundColor) + ' rounded-lg h-12 flex-1 font-semibold'}
                            key={data.id + item.id}
                            handleOnClick={() => {
                                setSelectedId(item.id)
                                onSelect(index, data.id, item.id, item.is_terminator)
                            }}/>
                        })
                    }
                    </div>

                )
        }
    }

    return (
        <div className='flex flex-col items-center justify-center bg-gray-200 w-full rounded-lg mb-5 p-3'>

            <p className='flex flex-1 text-blue-600 font-semibold text-xl mt-5' key={"SCQuestionItemP" + data.text}>{data.text}</p>

            {answersdiv()}
        </div>
    )
}

export default SCQuestionItem
