import React, { useCallback, useEffect, useState } from 'react'
import { Appointment } from '../classes/requests/case/Appointment'
import triangleRight from '../assets/blue-triangle.png'
import triangleDown from '../assets/blue-triangle-down.png'
import triangleUp from '../assets/blue-triangle-up.png'
import back_arrow from '../assets/Back.png'
import imageUpload from '../assets/5.png'
import Pagination from './Pagination'
import AppointmentPosts from './AppointmentPosts'
import { Case } from '../classes/model/Case'
import { useDropzone } from 'react-dropzone'
import StandardContainer from './container/StandardContainer'
import CustomCard from './container/CustomCard'
import { escape } from 'querystring'
import moment from 'moment';
import Index from './modals/Index'
import DownloadMessage from './modals/DownloadMessage'
import FileViewer from './modals/FileViewer'
import { useNavigate } from 'react-router-dom'
import BackButton from './BackButton'
import { config } from "../classes/Config";

const Appointments = () => {

  const navigate = useNavigate();

  const [upcomingAppointments, setUpcomingAppointments] = useState<any[] | undefined>(undefined)
  const [pastAppointments, setPastAppointments] = useState<any[] | undefined>(undefined)

  const [isUpcomingToggle, setIsUpcomingToggle] = useState(false)
  const [isPastToggle, setIsPastToggle] = useState(false)

  const [isCaseCollapsed, setIsCaseCollapsed] = useState(false)

  const [postsPerPge, SetPostsPerPage] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  const [collapsedName, setCollapsedName] = useState('')

  const [files, setFiles] = useState<any[]>([]);
  const [previews, setPreviews] = useState<any[]>([]);

  const [isShowFile, setIsShowFile] = useState(false)
  const [selectedFile, setSelectedFile] = useState<any>()
  const [caseId, setCaseId] = useState<any>('')
  const [showModal, setShowModal] = useState(false)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const [message, setMessage] = useState<any>('')
  const [filename, setFilename] = useState<any>('')
  const [isImage, setIsImage] = useState(false)

  console.log("previews", previews)


  const caseRequest = new Case()

  useEffect(() => {
    getCallbackRequestsList()
  }, [])

  const onDrop =  useCallback(async (acceptedFiles: File[]) => {

    const validFileTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];

    const validFiles = acceptedFiles.filter(file => validFileTypes.includes(file.type));
    console.log('acceptedFiles', acceptedFiles)


    if (validFiles.length > 0) {
      setMessage('');

    } else {
      setShowModal(true)
      setMessage('Only PDF and image files are allowed (JPG, JPEG, PNG, PDF).');
      return;
    }

    console.log('previews.length', previews.length)
    const limitFiles = [...previews, ...acceptedFiles]


    if (acceptedFiles.length > 5 || limitFiles.length > 5) {
      // Show the modal if more than 5 files are uploaded
      setShowModal(true);
      setMessage('File Limit Exceeded. You can only upload up to a maximum of 5 files')
      return;
    }

    var fileSizeCheck = 0
    acceptedFiles.forEach((file) => {
        if (file.size > 10e6) {
          // Check if file size is less than 10mb
          setShowModal(true);
          setMessage("Please upload a file smaller than 10 MB");
          fileSizeCheck = 1;
        }
    })

    if(fileSizeCheck == 1) {
        return;
    }

    const data = prepareFormData(acceptedFiles)
    const response = await caseRequest.uploadImage(data, "uploadImage_endpoint");

    console.log('upload image', response)

    setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);

    //Generate object URLs for previews
    /*const newPreviews = acceptedFiles.map(file => URL.createObjectURL(file));
    setPreviews(prevPreviews => [...prevPreviews, ...newPreviews]);*/


    const newPreviews = acceptedFiles.map(file => ({
      filelink: URL.createObjectURL(file),
      filename: file.name,
    }));
    
    setPreviews(prevPreviews => [...prevPreviews, ...newPreviews]);

    //Cleanup object URLs when component unmounts
    return () => {
      newPreviews.forEach(url => URL.revokeObjectURL(url.filelink));
    };
  }, [caseId, previews]);

  const prepareFormData = (files : File[]) => {

    const formData = new FormData
    console.log('patient_case_id', caseId)

    const data : any = {
      case_id : caseId
    }

    console.log("caseID", caseId)

    files.forEach((file) => {
      console.log("uploading file", file)
      formData.append('file[]', file);
    });

    Object.keys(data).forEach( (key : any) => {
      formData.append(key, data[key]);
    })

    return formData;
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const getCallbackRequestsList = async () => {

      const response = await caseRequest.getAppointmentList('getAppointment_list_endpoint');

      console.log('getCallbackRequestsList' , response)

      if(response.length !== 0){

        const dateTimeNow = new Date();
        const dateTimeFormat = moment(dateTimeNow).format('YYYY-MM-DD HH:mm:ss')

        const upcoming = (filterRecords(response.data, dateTimeFormat, true));
        const past = (filterRecords(response.data, dateTimeFormat, false))

        console.log('upcoming', upcoming)
        console.log('past', past)

        setUpcomingAppointments(filterRecords(response.data, dateTimeFormat, true));
        setPastAppointments(filterRecords(response.data, dateTimeFormat, false))
      }else{
        setMessage(response.message)
        setShowModal(true)
      }
  }

  const filterRecords = (records : any[], dateTimeNow : any, isUpcoming : boolean) => {
    console.log('records', records)
    console.log('isUpcoming', isUpcoming)
    if(records.length !== 0){
      if(isUpcoming){
        return records.filter(record => ( dateTimeNow < record.call_datetime && 'submitted' !== record.status )) //'new' === record.status || 'started' === record.status || 'ongoing' === record.status ||
      }else{
        return records.filter(record => ('submitted' === record.status))
        //return records.filter(record => ( dateTimeNow > record.call_datetime))
      }
    }
  }


  const handleUpcomingRequest = async (caseId : any) => {
    console.log('get case details', caseId)
    setCaseId(caseId)
    setIsCaseCollapsed(!isCaseCollapsed)
    const response: any = await caseRequest.getCaseDetails({caseId}, "getCase_details_endpoint")
    console.log('çase response', response)

    if(response?.case_images?.json?.case_images.length > 0 ){
      setPreviews(response.case_images.json.case_images)
    }

  }

  const handleDownloadPdfRequest = (url : any, filename : any ) => {

    console.log("url", url)
    console.log("filename", filename)


    setMessage("Your document is being prepared. Please hold on for a moment.")
    setShowDownloadModal(true)

    setTimeout(() => {
      downloadFile(url, filename)
    }, 5000);

  }

  const downloadFile = (url : any, filename : any ) => {
    setShowDownloadModal(false)
    setMessage("")
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
  }

  const indexOfLastPost = currentPage * postsPerPge;
  const indexOfFirstPost = indexOfLastPost - postsPerPge;
  const currentPosts = pastAppointments?.slice(indexOfFirstPost, indexOfLastPost);

  

  return (
  <StandardContainer>

    <BackButton route="/dashboard"/>

    <div className='flex flex-col items-center w-full'>
      <p className='text-4xl text-blue-500 font-bold text-left w-[80%]'>Appointments</p>

    <CustomCard className={`flex flex-col items-center w-[80%] mt-10 bg-white rounded-lg pt-3`}>

        <div className='flex flex-col justify-center cursor-pointer pb-2 border-b-4 apppointment-green-border w-[90%] mx-[5%] mt-5 mb-5' onClick={() => {setCollapsedName('upcoming'); setIsUpcomingToggle(!isUpcomingToggle); setIsPastToggle(!isPastToggle);}}>
          <div className='flex flex-row items-center'>
            <p className='text-2xl text-blue-500 font-semibold'>Upcoming Appointments</p>
            {collapsedName === 'upcoming' && isUpcomingToggle ? <img src={triangleUp} className='w-6 h-4 ml-auto mr-5'/> : <img src={triangleDown} className='w-6 h-4 ml-auto mr-5'/>}
          </div>
        </div>

        {upcomingAppointments !== undefined ? (

          collapsedName === 'upcoming' && isUpcomingToggle && upcomingAppointments?.length !== 0 ?
          (
            <div className={`${ isCaseCollapsed ?  'flex flex-col w-[90%] rounded-xl bg-gray-100 py-[3%] pl-[5%] justify-center' :  'flex flex-col w-[90%] rounded-xl bg-gray-100 pl-[5%] justify-center'}`}>
            <div className='flex flex-row py-5'>
              <div className='flex flex-col justify-center w-[60%]'>
                <p className='text-xs text-gray-400 font-semibold'>{upcomingAppointments[0]?.case_number}</p>
                <p className='text-2xl font-semibold text-blue-600'>{upcomingAppointments[0]?.case.health_concern}</p>
                <p className='text-xs text-gray-400'>{new Date(upcomingAppointments[0]?.parsed_date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})} | {upcomingAppointments[0]?.parsed_time} | {upcomingAppointments[0]?.preferred_method}</p>
              </div>
              { isCaseCollapsed ?
                  <div className="w-full items-center flex">
                    <img src={triangleUp} className='w-6 h-4 ml-auto mr-5 cursor-pointer' onClick={() => handleUpcomingRequest(upcomingAppointments[0]?.patient_case_id)} />
                  </div>
                  :
                  <div className="w-full items-center flex">
                    <img src={triangleDown} className='w-6 h-4 ml-auto mr-5 cursor-pointer' onClick={() => handleUpcomingRequest(upcomingAppointments[0]?.patient_case_id)} />
                  </div>
              }
            </div>

            {
              isCaseCollapsed ?
                <div className='flex flex-col mt-3 ml-5'>
                      {previews.length !== 0 ?
                        <div className='flex flex-col ml-3 text-xs font-bold'>
                          <div className='flex flex-row mr-auto'>
                            <img src={imageUpload} className='w-6 h-6 ml-auto mr-2'/>
                            <p>Case Images</p>
                          </div>

                          <div className='flex flex-row mt-2'>
                            {previews.map((preview, index) => (
                              console.log(preview.filename),
                              <div>
                                  { preview.filename.substring(preview.filename.lastIndexOf('.') + 1) === "pdf" ?
                                   <a onClick={() => handleDownloadPdfRequest(`${preview.filelink.includes("blob") ? `${config.CASE_IMAGES_URL}/api/case_images/pdf?file_name=${upcomingAppointments[0]?.case_number}-${preview.filename}` : `${config.CASE_IMAGES_URL}/api/case_images/pdf?file_name=${preview.filename}`}`, preview.filename)} key={index} className="flex justify-center items-center cursor-pointer text-blue-500 ml-3 border border-black h-20 w-20 rounded-xl">PDF {index + 1} </a> :
                                  <img key={index} src={preview.filelink} alt={`Preview ${index}`} style={{ width: '80px', height: '70px', objectFit: 'cover', margin: '5px', cursor: 'pointer' }} onClick={() => {setSelectedFile(preview.filelink); setIsShowFile(true); setFilename(preview.filename); setIsImage(true)}}/>}
                              </div>
                            ))}

                            { upcomingAppointments[0].status === "new" ?
                              previews.length >= 5 ? null : <div {...getRootProps({ className: 'h-16 w-16 border border-gray-400 rounded-2xl text-gray-400 text-2xl flex items-center justify-center mt-2 ml-2 cursor-pointer'})}>
                                    <p className=''>+</p>
                                    <input {...getInputProps()}/>
                                </div> : null}

                          </div>
                        </div> : (upcomingAppointments[0].status === "new" ?
                          <div className='flex flex-col'>
                            <div className='flex flex-row ml-3 text-xs font-bold mr-auto'>
                                <img src={imageUpload} className='w-6 h-6 ml-auto mr-2'/>
                                <p>Case Images</p>
                            </div>
                              { previews.length >= 5 ? null : <div {...getRootProps({ className: 'h-16 w-16 border border-gray-400 rounded-2xl text-gray-400 text-2xl flex items-center justify-center mt-5 ml-2 cursor-pointer'})}>
                                    <p className=''>+</p>
                                    <input {...getInputProps()}/>
                                </div>}
                          </div>
                            : <div className='text-white bg-red-500 mt-5 h-10 border border-gray-400 text-lg text-center w-96 rounded-lg mr-auto'> <p className='mt-1'>No uploaded images</p></div>)
                      }
                </div> : null
            }
          </div>
          )  :  ( collapsedName === 'upcoming' && isUpcomingToggle ?
            <div className='bg-gray-100 w-[90%] h-[10%]'>
                  <p className='text-gray-500 font-semibold text-xl ml-[2%]'>No upcoming appointment</p>
            </div> : null
      )
          ) : ( collapsedName === 'upcoming' && isUpcomingToggle ?
              <div className='bg-gray-100 w-[90%] h-[10%]'>
                    <p className='text-gray-500 font-semibold text-xl ml-[2%]'>No upcoming appointment</p>
              </div> : null
        )}



        {/**this section is for past appointments */}
        <div className='flex flex-col justify-center cursor-pointer pb-2 border-b-4 apppointment-green-border w-[90%] mx-[5%] mt-[3%] mb-10'>
        <div className='flex flex-row items-center' onClick={() => {setCollapsedName('past'); setIsPastToggle(!isPastToggle); setIsUpcomingToggle(!isUpcomingToggle)}}>
            <p className='text-2xl text-blue-500 font-semibold'>Past Appointments</p>
            {collapsedName === 'past' && isPastToggle ? <img src={triangleUp} className='w-6 h-4 ml-auto mr-5'/> : <img src={triangleDown} className='w-6 h-4 ml-auto mr-5'/>}
          </div>
        </div>
        {
          collapsedName === 'past' && isPastToggle ?
            <div className='flex flex-col w-[90%] '>

                <AppointmentPosts posts={currentPosts} />
                <Pagination postsPerPage={postsPerPge} length={pastAppointments?.length} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
            </div>
          : null
        }

    </CustomCard>

    {isShowFile ? <FileViewer file={selectedFile} closeModal={() => setIsShowFile(false)} filename={filename} isImage={isImage}/> : null}
    {/**{isShowFile && filename.split('.').pop()  === 'pdf' ? <PdfViewer closeModal={() => setIsShowFile(false)} pdfUrl={selectedFile} /> : null} */}
    {showModal ? <Index message={message} status={undefined} closeModal={() => setShowModal(false)} page='' email=''/>  : null}
    {showDownloadModal ? <DownloadMessage message={message} status={undefined} closeModal={() => setShowModal(false)} page='' email=''/>  : null}
    </div>
  </StandardContainer>
  )
}

export default Appointments
