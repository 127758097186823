import imgPhoneAccept from '../../../assets/icons/voip/phone-accept.png'
import imgPhoneReject from '../../../assets/icons/voip/phone-reject.png'
import imgPhoneEnd from '../../../assets/icons/voip/phone-end.png'

import imgMicUnmuted from '../../../assets/icons/voip/mic-unmuted.png'
import imgMicMuted from '../../../assets/icons/voip/mic-muted.png'
import imgCamUnmuted from '../../../assets/icons/voip/cam-unmuted.png'
import imgCamMuted from '../../../assets/icons/voip/cam-muted.png'

interface ButtonProps {
  onClick: () => void
  alt?: string
  className?: string
  isMicMuted?: boolean
  isCamMuted?: boolean
}

export const BtnAcceptCall = ({ onClick, alt, className }: ButtonProps) => {
  return (
    <button onClick={onClick} className={`p-0 m-0 border-none ${className}`}>
      <img
        src={imgPhoneAccept}
        alt={alt || 'imgPhoneAccept'}
        className='w-full h-full'
      />
    </button>
  )
}

export const BtnRejectCall = ({ onClick, alt, className }: ButtonProps) => {
  return (
    <button onClick={onClick} className={`p-0 m-0 border-none ${className}`}>
      <img
        src={imgPhoneReject}
        alt={alt || 'imgPhoneReject'}
        className='w-full h-full'
      />
    </button>
  )
}

export const BtnEndCall = ({ onClick, alt, className }: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`p-0 m-0 border-none ${className} rotate-135`}>
      <img
        src={imgPhoneEnd}
        alt={alt || 'imgPhoneEnd'}
        className='w-full h-full'
      />
    </button>
  )
}

export const BtnMic = ({
  onClick,
  alt,
  className,
  isMicMuted,
}: ButtonProps) => {
  return (
    <button onClick={onClick} className={`p-0 m-0 border-none ${className}`}>
      <img
        src={isMicMuted ? imgMicMuted : imgMicUnmuted}
        alt={alt || 'microphone'}
        className='w-full h-full'
      />
    </button>
  )
}

export const BtnCam = ({
  onClick,
  alt,
  className,
  isCamMuted,
}: ButtonProps) => {
  return (
    <button onClick={onClick} className={`p-0 m-0 border-none ${className}`}>
      <img
        src={isCamMuted ? imgCamMuted : imgCamUnmuted}
        alt={alt || 'camera'}
        className='w-full h-full'
      />
    </button>
  )
}
