export const config = {
    APP_URL: "localhost:3000",
    LOCALHOST: "http://localhost",

   
    BASE_URL: "https://rdpbackend.medgate.cloud",
    SYMPTOM_CHECKER_URL : "https://selftriage.medgate.cloud/api/v1",
    CASE_IMAGES_URL : "https://caseimages.medgate.cloud"
    
    /*
    SYMPTOM_CHECKER_URL : "https://dev-selftriage.medgate.cloud/api/v1",
    BASE_URL: "https://dev-rdpbackend.medgate.cloud",
    CASE_IMAGES_URL : "https://dev-caseimages.medgate.cloud"
    */
};
 