import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const LoggedInRedirect = () => {
    const token = JSON.parse(localStorage.getItem('api_token') || 'null')
    const user = JSON.parse(localStorage.getItem('user') || 'null')

    return (
      // token == null ? <Outlet/> : <Navigate to="/dashboard"/>
      //token == null ? <Outlet/> : <Navigate to="/login"/>
      token != null ? 
      <>
        {
          user !== null ? <>
            {
              user.email_verified_at === null ? 
                <Outlet/> : 
              <>
                {
                  user.insurance_number == null || user.insurance_number == '' ? 
                  <Outlet/> :
                  <Navigate to="/dashboard"/>
                }
              </>
            }
          </> : <Outlet/>
        }
      </>
      : <Outlet/>                  
    )
}

export default LoggedInRedirect
