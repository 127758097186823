import React, { useEffect, useRef } from 'react'
import check_icon from '../../assets/Checkbox.png'
import { useNavigate } from 'react-router-dom'

interface Props {
    closeModal: () => void
    page : any
    status : any
    isForgot : boolean
    message : any
}


const ChangePasswordModal = ({closeModal, page, status, isForgot, message} : Props) => {

  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);


  const handleButtonRequest = () => {
    closeModal();
    if(status){
      navigate(page)
    }
  }

    // Close modal if a click happens outside the modal
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        closeModal();
      }
    };
  
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

  return (
    <div className='flex items-center pl-[40%] fixed inset-0 z-10 overflow-y-auto bg-gray-600 bg-opacity-70 h-full w-full'>
    <div className='flex items-center px-4 py-8 w-[30%] h-1/3'>
      <div className='relative w-full max-w-lg p-4 bg-white shadow-lg shadow-gray-700 rounded-xl'>
      <div ref={modalRef}>
        <div className='flex items-center justify-center mt-3'>
          <div className='flex flex-col items-center justify-center text-blue-500'>
            {/**modal content */}
            {/**<img src={check_icon} className='w-28 h-28'/>*/}
            <p className='text-2xl font-semibold text-center'>{message}</p>
            <button className='h-10 w-[50%] bg-blue-600 text-white rounded-xl mt-5 mb-5 hover:bg-blue-700' onClick={handleButtonRequest}>Ok</button>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ChangePasswordModal
