import React, { useEffect, useRef, useState } from 'react'
import { User } from '../../classes/model/User'
import pusher from '../../classes/Pusher'
import Chat from './Chat'
import sender from '../../assets/send.png'
import back_arrow from '../../assets/Back.png'
import { getParsedDataFromLocalStorage } from '../../classes/DataParser'
import StandardContainer from '../container/StandardContainer'
import Error from '../modals/Error'
import { useNavigate } from 'react-router-dom'
import BackButton from '../BackButton'
import { create } from 'domain'

const simulatedEvent = {
  type: "click",
  target: { id: "simulatedButton" },
  clientX: 150,
  clientY: 250,
  preventDefault: () => {
  },
};

var isLoaded = false;

const Index = () => {

  const user = new User()
  const navigate = useNavigate();

  const [chatHistory, setChatHistory] = useState<any[]>([])
  const [messageContent, setMessageContent] = useState<any>()

  const [errorMessage, setErrorMessage] = useState<any>('')
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const [chatSlug, setChatSlug] = useState('')
  const [roomClosed, setRoomClosed] = useState(false)

  const messageIdsRef = useRef<Set<string>>(new Set());
  const hasSentMessageRef = useRef(false);

  const textareaRef = useRef<any>();

  useEffect(() => {
    
    if (chatSlug == '' && isLoaded == false) {
      console.log('chatSlug is empty')
      isLoaded = true;
      createChatRoom();
    }

  })

  const initPusher = async (slug: string) => {

    pusher.connection.bind('state_change', (states: any) => {
      console.log('Pusher connection state:', states);
      if (states.current === 'connected') {
        setIsConnected(true);
        console.log('Pusher connected successfully!');
      } else if (states.current === 'disconnected') {
        setIsConnected(false);
        console.log('Pusher disconnected.');
      }
    });

    const channel = pusher.subscribe(`chat-${slug}`);
    channel.bind('new-message', (data: any) => {

      console.log("listen to new message", data)
      if (messageIdsRef.current.has(data.id)) {
        return; //Message already processed
      }

      setChatHistory(prevMessages => [...prevMessages, data]);
      messageIdsRef.current.add(data.id);
    })

    const roomChannel = pusher.subscribe(`chat-room`);
    roomChannel.bind('closed', (data: any) => {
      if (slug === data.slug) {
        console.log('Chat room closed:', data);
        setRoomClosed(true);
      }
    });
  }

  const createChatRoom = async () => {

    const response: any = await user.createChatRoom('createChatroom_endpoint')
    console.log("Create chat room response : ", response)

    setChatSlug(response.data.slug);

    await initPusher(response.data.slug);

    if (response.data.last_chat === null && !hasSentMessageRef.current) {
      handleSendMessage(simulatedEvent, true, response.data.slug)
      hasSentMessageRef.current = true;
    } else {
      getChatHistory(response.data.slug, true)
    }
  }

  const getChatHistory = async (slug: any, isFromUnload: boolean) => {
    console.log("Getting chat history for slug : ", slug)
    const history: any = await user.getChatHistory({ slug: slug }, 'getChat_history_endpoint')
    console.log("chat history : ", history.data)
    setChatHistory(history.data);
  }

  const handleSendMessage = async (e: any, isFromUnload?: boolean, currentSlug?: any) => {

    if (e.type == "click" || (e.keyCode == 13 && !e.shiftKey)) {
      e.preventDefault()
      var tempMessage = isFromUnload ? "Hello" : messageContent
      const slug = isFromUnload ? currentSlug : chatSlug.replace(/"/g, '');

      setMessageContent('')

      if (tempMessage.length >= 1) {

        const messageRequest: any = await user.sendMessage({
          message: tempMessage,
          //message: JSON.stringify(tempMessage),
          chat_room_slug: slug,
        }, 'sendChat_endpoint')

        console.log('messageRequest', messageRequest)
        if (chatSlug != null) {
          getChatHistory(slug, true)
        }

        resizeTextArea()
      } else {
        setErrorMessage("The message must be at least 2 characters")
        setShowErrorModal(true)
      }
    }
  }

  const messageContentHandler = (val: string) => {
    setMessageContent(val)
    resizeTextArea()
  }

  const resizeTextArea = () => {
    if (textareaRef.current != null) {
      textareaRef.current.style.height = "3rem";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + "px";
    }
  }

  return (
    <StandardContainer className='flex flex-col justify-center items-center w-full'>

      <BackButton route="/dashboard" />

      <div className='flex flex-col items-center h-[80vh] w-full rounded-lg'>

        <div id="chatBox" className='bg-white h-[100%] overflow-y-auto w-[95%] top-[7%] mb-2'>
          <Chat chatHistory={chatHistory} />
        </div>
        {!roomClosed ?
        <div className='flex bg-white flex-row items-center max-h-[50%] justify-center w-[95%] rounded-xl border border-blue-600 mt-auto'>
          <textarea id="messageBox" ref={textareaRef} value={messageContent} className='!outline-none h-full ml-3 max-h-full min-h-[3rem] px-5 content-center w-full bg-transparent border-none' onChange={(e) => messageContentHandler((e.target.value))} placeholder='Please type your message' onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSendMessage(e);
            }
          }} />
          <img src={sender} alt={'send_icon'} width={30} className='cursor-pointer ml-2 mr-5' onClick={handleSendMessage} />
        </div>  : 
        <div className='flex bg-white flex-row items-center max-h-[50%] justify-center w-[95%] rounded-xl border border-blue-600 mt-auto'>
          <p className='text-center text-red-500'>This chat room session has ended</p>
        </div> 
        }
      </div>
      {showErrorModal ? <Error message={errorMessage} closeModal={() => setShowErrorModal(false)} /> : null}
    </StandardContainer>
  )
}

export default Index
