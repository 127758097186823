import React, { useState, useEffect } from 'react'
import logo from './logo.svg'
import './App.css'
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'
import Index from './components/Index'
import PrivateRoutes from './components/PrivateRoutes'
import Login from './components/Login'
import Dashboard from './components/Dashboard'
import { useBeforeunload } from 'react-beforeunload'
import Registration from './components/Registration'
import Account from './components/patient/Account'
import Type from './components/appointment/Type'
import Concern from './components/appointment/Concern'
import SetAppointment from './components/appointment/SetAppointment'
import Provider from './components/patient/Provider'
import SymptomCheckerIndex from './components/symptom_checker/Index'
import SymptomChecker from './components/symptom_checker/SymptomChecker'
import Shop from './components/Shop'
import Profile from './components/patient/Profile'
import ContactInformation from './components/patient/ContactInformation'
import ContactEmail from './components/patient/ContactEmail'
import ContactNumber from './components/patient/ContactNumber'
import Sidebar from './components/Sidebar'
import Chat from './components/chat/Index'
import Appointments from './components/Appointments'
import ForgotPassword from './components/password/ForgotPassword'
import OTP from './components/OTP'
import Password from './components/settings/Index'
import ChangePassword from './components/settings/ChangePassword'
import Header from './components/Header'
import CodeVerification from './components/password/CodeVerification'
import UpdatePassword from './components/password/UpdatePassword'
import Verification from './components/Verification'
import LoggedInRedirect from './components/LoggedInRedirect'
import Voip from './components/modals/Voip'
import AppleAuth from './components/AppleAuth'
import MobileDetector from './components/modals/MobileDetector'
import Tutorial from './components/patient/Tutorial'

import { User } from './classes/model/User';

function App() {
  /**clear the storage when the user close the browser*/
  /*useBeforeunload(() => {

    if (window) localStorage.clear();

  });*/

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false)
  const [isOtpCompleted, setIsOtpCompleted] = useState(false)
  const [isCodeVerificationCompleted, setIsCodeVerificationCompleted] = useState(false)

  const [showVoip, setShowVoip] = useState(false)
  const [channelName, setChannelName] = useState<any>()
  const [uid, setUid] = useState<any>()
  const [doctoName, setDoctoName] = useState<any>()
  const [callMethod, setCallMethod] = useState<any>()
  const [blinkTitle, setBlinkTitle] = useState<boolean>(false)

  const user = new User();

  navigator.serviceWorker.onmessage = async (event) => {
    let data = event.data;
    const response = await user.getParticipants({channel_name: data.channel_name});

    if (response.data.success) {
      setChannelName(data.channel_name);
      setUid(data.uid);
      setDoctoName(data.doctor_name);
      setCallMethod(data.call_method);
      setShowVoip(true)
      setBlinkTitle(!data.is_displayed);
    }

  }

  const closeVoip = () => {
    setShowVoip(false)
  }

  const initRingtone = () => {
    try {
      let audio = new Audio('./ringtone.mp3');
      return audio;
    } catch (error) {
      return false;
    }
  }

  const parseParams = async () => {
    const url = new URL(window.location.href);
    if (url.searchParams.get('channel') != null) {
      const response = await user.getParticipants({channel_name: url.searchParams.get('channel')});
      if (response.data.success) {
        setChannelName(url.searchParams.get('channel'));
        setUid(url.searchParams.get('uid'));
        setDoctoName(url.searchParams.get('doctor_name'));
        setCallMethod(url.searchParams.get('call_method'));
        setShowVoip(true)
      }
      
      window.history.pushState({}, document.title, '/dashboard');
    }
  }

  useEffect(() => {
    parseParams();
  })

  return (
    <Router>
      <Header isSidebarCollapsed={isSidebarCollapsed} setIsSidebarCollapsed={(value) => setIsSidebarCollapsed(value)} />
      <div className='flex w-full Gilroy'>
        <Sidebar isSidebarCollapsed={isSidebarCollapsed} setIsSidebarCollapsed={(value) => setIsSidebarCollapsed(value)} />
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path='/appointments' element={<Appointments />} />
            <Route path='/numbers' element={<ContactNumber />} />
            <Route path='/account' element={<Account />} />
            <Route path='/password' element={<Password />} />
            <Route path='/change-password' element={<ChangePassword />} />
            <Route path='/main' element={<Account />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/chat' element={<Chat />} />
            <Route path='/type' element={<Type />} />
            <Route path='/concern' element={<Concern />} />
            <Route path='/set-appointment' element={<SetAppointment />} />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/provider' element={<Provider />} />
            <Route path='/symptom' element={<SymptomCheckerIndex />} />
            <Route path='/patient-symptom' element={<SymptomChecker />} />
            <Route path='/shop' element={<Shop />} />
            <Route path='/contact' element={<ContactInformation />} />
            <Route path='/emails' element={<ContactEmail />} />
            <Route path='/numbers' element={<ContactNumber />} />
            <Route path='/tutorials' element={<Tutorial />} />
          </Route>

          {/**<Route path='/' element={<Index />} />
                <Route path='/login' element={<Login />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/code-verification' element={<CodeVerification />} />
                <Route path='/update-password' element={<UpdatePassword />} />
                <Route path='/registration' element={<Registration />} />
                <Route path='/verification' element={<Verification />} />
                <Route path='/otp' element={<OTP />} /> */}

          <Route element={<LoggedInRedirect />}>
            <Route path='/' element={<Index />} />
            <Route path='/login' element={<Login />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/code-verification' element={<CodeVerification isCodeVerificationCOmplete={setIsCodeVerificationCompleted} />} />
            <Route path='/update-password' element={<UpdatePassword isComplete={isCodeVerificationCompleted} />} />
            <Route path='/registration' element={<Registration />} />
            <Route path='/otp' element={<OTP setIsOtpCompleted={setIsOtpCompleted} />} />
            <Route path='/verification' element={<Verification isOtpComplete={isOtpCompleted} />} />
            <Route path='/apple/auth' element={<AppleAuth/>} />
          </Route>
        </Routes>
        {showVoip && <Voip closeVoip={closeVoip} channelName={channelName} call_type={callMethod} audio={initRingtone()} caller_name={doctoName} blink_title={blinkTitle} />}
      </div>
      {/* <MobileDetector /> */}

    </Router>
  )
}

export default App
