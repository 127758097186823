import React from 'react'
import CustomButton from '../buttons/CustomButton'
import { useNavigate } from 'react-router-dom'
import StandardContainer from '../container/StandardContainer'
import CustomCard from '../container/CustomCard'
import back_arrow from '../../assets/Back.png'
import BackButton from '../BackButton'


const Index = () => {

    const navigate = useNavigate();

    return (
        <StandardContainer>

        <BackButton route='/dashboard'/>

        <p className='text-4xl text-blue-500 font-bold w-[80%]'>Symptom Checker</p>

            <CustomCard className='flex flex-col items-center justify-center w-[80%] mt-10 space-x-2 bg-white rounded-lg pb-[4%] pt-[4%]'>

                <div className='flex flex-col justify-center items-center w-[80%] py-5 space-y-8 text-center'>

                    <p className='text-xl text-blue-600 font-semibold w-[80%]'>Try our symptom checker! Answer a few quick questions to
                    share your health concerns with us.
                    </p>

                    <p className='text-xl text-blue-600 font-semibold w-[80%]'>Your answers will be received by our doctors, ensuring we are prepared to help you feel your best
                    during your consultation.
                    </p>

                    <div className='flex flex-row items-center justify-center space-x-5 mt-10'>
                        <CustomButton title='Begin' style='rounded-full dark-blue-bg h-12 w-40 text-white font-semibold hover:bg-blue-700' isDisabled={false} handleOnclick={() => navigate('/patient-symptom')}/>
                    </div>

                    <p className='text-xs italic text-blue-400 font-semibold w-[60%]'>Disclaimer: If you think you are experiencing a medical emergency, please seek immediate care from the nearest hospital or emergency services.
                    </p>

                </div>
            </CustomCard>
        </StandardContainer>
    )
}

export default Index
