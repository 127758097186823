import { ItimeSlot } from "../../interface/ITimeSlot";
import { requests } from "../Api";
import { endpoints } from "../Endpoint";

const ELIGIBILITY_DAYS_ENDPOINT = process.env.REACT_APP_RDP_API_ELIGIBILITY_DAYS_ENDPOINT as string
const ELIGIBILITY_TIMESDATE_ENDPOINT = process.env.REACT_APP_RDP_API_ELIGIBILITY_TIMESDATE_ENDPOINT as string

export class AvailableSlot extends requests {
    //token = JSON.parse(localStorage.getItem('api_token') ?? '')

    async getSlot(request : any) {

        const endpoint = endpoints(request, "GET", true, null);

        let response = await this.fetchRequest(endpoint, request)
        return response;
    }

    async getTimeSlot({ day }: ItimeSlot, request : any) {

        const endpoint = endpoints(request, "GET", true, day);
        console.log('endpoint', endpoint)

        // let response = await this.fetchRequest(`https://dev-rdpbackend.medgate.cloud/api/v2/eligibility/times?date=${day}`, this.token)
        //let response = await this.fetchRequest(`${ELIGIBILITY_TIMESDATE_ENDPOINT}${day}`, this.token)

        let response = await this.fetchRequest(endpoint, request)
        return response;
    }
}