import { ICallbackRequest, ICaseDetails } from "../interface/ICallbackRequest";
import { ItimeSlot } from "../interface/ITimeSlot";
import { Appointment } from "../requests/case/Appointment";
import { AvailableSlot } from "../requests/case/AvailableSlot";

export class Case {

    private request: any;

    constructor() {
        this.request = null;
    }

    async getConcerns(request : any): Promise<any> {
        this.request = new Appointment();
        return await this.request.getConcerns(request)
    }

    async setAppointment( data : FormData, request : any ): Promise<any> {
        

        //console.log('get form data image 222', data?.get("file[]"))

        this.request = new Appointment();
        return await this.request.setAppointment(data, request);
    }

    async getAppointmentList(request : any): Promise<any> {
        this.request = new Appointment();
        return await this.request.getAppointmentList(request)
    }

    /*async getCaseDetails(caseId : any): Promise<any> {
        this.request = new Appointment();
        return await this.request.getCaseDetails(caseId)
    }

    async getPastAppointment(): Promise<any> {
        this.request = new Appointment();
        return await this.request.getPastAppointment()
    }*/

    async getCaseDetails({ caseId } : ICaseDetails, request : any): Promise<any> {
        console.log('caseId', caseId)

        this.request = new Appointment();
        return await this.request.getCaseDetails({ caseId }, request)
    }

    async getSlot(request : any): Promise<any> {

        this.request = new AvailableSlot();
        return await this.request.getSlot(request);
    }


    async getTimeSlot({ day }: ItimeSlot, request : any): Promise<any> {

        this.request = new AvailableSlot();
        return await this.request.getTimeSlot({ day }, request);
    }

    async uploadImage(data : FormData, request : any) : Promise<any>{
        console.log('uploadImage', data)
        this.request = new Appointment();
        return await this.request.uploadImage(data, request)

    }

}