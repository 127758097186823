import { IPhoneNumberRequest } from "../../interface/IPhoneNumber"
import { requests } from "../Api";
import { endpoints } from "../Endpoint";

const USER_UPDATE_PHONES_ENDPOINT = process.env.REACT_APP_RDP_API_USER_UPDATE_PHONES_ENDPOINT as string

export class ContactNumber extends requests {
    //token = JSON.parse(localStorage.getItem('api_token') ?? '')

    async updateContactNumber({ mobile_number, phonenumber_options }: IPhoneNumberRequest, request : any) {

        const endpoint = endpoints(request, "POST", true, null);

        const data: IPhoneNumberRequest = {
            mobile_number: mobile_number,
            phonenumber_options: phonenumber_options
        }

        //let response = await this.postRequest(USER_UPDATE_PHONES_ENDPOINT, contactNumbers, this.token)
        let response = await this.postRequest(endpoint, data, request)
        return response
    }
}