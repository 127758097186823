import axios from 'axios'

const baseUrl : any = process.env.REACT_APP_RDP_API_BASE_URL

export class requests {
 
  fetchRequest = async (endpoint : any ,token: any) => {

    console.log('url', `${baseUrl}${endpoint}`)

    try {
      const bearer = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      }

      const response = await axios.get(`${baseUrl}${endpoint}`, bearer)

      return response.data

    } catch (error : any) {
      console.log('error', error)
      if(error.message === 'Request failed with status code 400' || 'Request failed with status code 500'){
          return {
            message : error.response.data
          }
      }else if(error.message === 'Request failed with status code 422'){
        return {
          message : error.response.data.message
        }
      }
      else{
       return {
         message : error.message
       }
      }
    }
  }

  postRequest = async (endpoint : any, data: any, token: string) => {

    console.log('postRequest', data)
    console.log('url', `${baseUrl}${endpoint}`)
   
    try {

    const bearer = {

      headers: { 
        'Authorization' : `Bearer ${token}` ,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    }

      const response = await axios.post(`${baseUrl}${endpoint}`, data, bearer)
      
      return {
        message : 'success',
        data : response.data
      }

    } catch (error : any) {

      console.log('error', error)

      if(error.message === 'Request failed with status code 400' || 'Request failed with status code 500'){
        console.log('only 400 and 500')
          return {
            message : error.response.data
          }
      }else if(error.message === 'Request failed with status code 422'){
        console.log('only 422')

        return {
          message : error.response.data.message
        }
      }
      else{
        console.log('default')
       return {
         message : error.message
       }
      }
     
    }
  }

  postRequestWithoutAuthorization = async (endpoint : any, data: any) => {

    console.log('postRequest', data)
    console.log('url', `${baseUrl}${endpoint}`)
   
    try {

    const bearer = {

      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    }

      const response = await axios.post(`${baseUrl}${endpoint}`, data, bearer)
      
      return {
        message : 'success',
        data : response.data
      }

    } catch (error : any) {

      console.log('error', error)

      if(error.message === 'Request failed with status code 400' || 'Request failed with status code 500'){
        console.log('only 400 and 500')
          return {
            message : error.response.data
          }
      }else if(error.message === 'Request failed with status code 422'){
        console.log('only 422')

        return {
          message : error.response.data.message
        }
      }
      else{
        console.log('default')
       return {
         message : error.message
       }
      }
     
    }
  }

  postRequestWithDefineResponse = async (endpoint : any, data: any, token: string) => {
   
    try {

    const bearer = {
      headers: { 
        'Authorization' : `Bearer ${token}` ,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    }

      const response = await axios.post(`${baseUrl}${endpoint}`, data, bearer)
      
      return response.data

    } catch (error : any) {
      console.log(error)

      return error
    }
  }


  getRequestWithParams = async (url: string, data: any) => {
    try {

      const response = await axios.get(url, data)

      return response.data
    } catch (err) {
      console.log(err)

      return err
    }
  }
}
