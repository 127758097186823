import { time } from 'console'
import React, { useState } from 'react'
import triangleBlue from '../../assets/blue-triangle-down.png'
import moment from 'moment'
import { getParsedDataFromLocalStorage } from '../../classes/DataParser'

interface Props {

    time : any[]
    selectedTime : any
    setSelectedTime : any
    selectedDate : any
}

const AvailableTime = ({time, selectedTime, setSelectedTime, selectedDate} : Props) => {


    /*console.log('selectedTime', selectedTime)*/


    // console.log('selectedDate', selectedDate)

  const localCallSection = getParsedDataFromLocalStorage('callbackRequest_callSection')

  //const [selectedTime, setSelectedTime] = useState('')
  const [isShowAvailableSlot, setIsShowAvailableSlot] = useState(localCallSection ? true : false)
  const [selectedSection, setSelectedSection] = useState<any>(localCallSection || '')
  const [currentHour, setCurrentHour] = useState<any>()
  const [currentDate, setCurrentDate] = useState<any>()


  const handleExpandSectionRequest = (item : any) => {
    const now = new Date();
    const hours = now.getHours();

    const formattedDate = moment().format('YYYY-MM-DD');
    setCurrentDate(formattedDate)

    setCurrentHour(hours)
    setSelectedSection(item);
    localStorage.setItem("callbackRequest_callSection", JSON.stringify(item))

    if(item == selectedSection){

        setIsShowAvailableSlot(!selectedSection)

    }else{
        setIsShowAvailableSlot(true)
    }

    //{ item == selectedSection ? setIsShowAvailableSlot(!selectedSection) : setIsShowAvailableSlot(true) }
  }

  const handleTimeSelection = (time : any) => {
    setSelectedTime(time)
    localStorage.setItem("callbackRequest_callTime", JSON.stringify(time))

  }

  return (
    <div className='flex flex-col w-full'>
        {Object.keys(time).sort((a, b) => {
      // Move "dawn" to the top
      if (a === "dawn") return -1;
      if (b === "dawn") return 1;
      return 0; // Preserve the original order for other keys
    }).map((item : any) => (
            <div className='w-full'>
                <div className='flex flex-row lg:w-72 items-center text-lg text-blue-600 pb-2 border-b-2 border-b-custom-devider cursor-pointer capitalize' onClick={() => handleExpandSectionRequest(item)}>
                    <p>{item}</p>
                    <img src={triangleBlue} className='w-3 h-2 justify-center ml-auto mr-2'/>
                </div>

            {
                isShowAvailableSlot && item == selectedSection ? <div className='grid grid-cols-3 gap-1 gap-x-0 gap-y-3 mt-1 mb-3'>
                    {
                        time[selectedSection].map((slot : any) => (

                            currentDate === selectedDate ?
                                slot.value <= currentHour || slot.value === '24' ?
                                    <button  className='callback-slot h-10 bg-gray-200 text-blue-500 cursor-not-allowed' key={slot.value}>{slot.label}</button> :
                                    <button onClick={() => handleTimeSelection(slot.value)} className={`callback-slot cursor-pointer ${slot.value === selectedTime ? 'bg-blue-500 text-white' : 'text-blue-600 border-2 border-blue-400'}`} key={slot.value}>{slot.label}</button>
                                    

                            : <button onClick={() => handleTimeSelection(slot.value)}  className={`callback-slot cursor-pointer ${slot.value === selectedTime ? 'bg-blue-500 text-white' : 'text-blue-600 border-2 border-blue-400'}`} key={slot.value}>{slot.label}</button>
                        ))
                    }
                </div> : null
            }
            </div>
        ))}
    </div>
  )
}

export default AvailableTime
