import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import green_appointment from '../../assets/Appointments- green.png'
import moment from 'moment';

interface Props {

    name : string,
    closeModal: () => void
    page : string
    callbackType : string
    callDate : any
    callTime : string
}

const CallbackRequestModal = ({name, closeModal, page, callbackType, callDate, callTime} : Props) => {

    const navigate = useNavigate();
    const modalRef = useRef<HTMLDivElement>(null);


    const handleOnclickRequest = () => {
      closeModal()
      navigate(page)
    }

      // Close modal if a click happens outside the modal
  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='flex items-center pl-[35%] fixed inset-0 z-10 overflow-y-auto bg-gray-600 bg-opacity-70 h-full w-full'>
    <div className='flex items-center justify-center px-4 py-8 w-2/3 h-1/3'>
      <div className='w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg shadow-gray-700'>
      <div ref={modalRef}>
        <div className='flex items-center justify-center mt-3'>
          <div className='flex flex-col justify-center items-center'>
            {/**modal content */}
            <img src={green_appointment} className='h-20 w-20'/>
            <p className='text-blue-600 text-2xl font-semibold text-center'>Thank you, {name}!</p>
            <p className='text-center'>Your {callbackType} call appointment has been scheduled on {callDate} at {moment(callTime, 'HH:mm').format('h:mm A')} </p>
            <button className='h-10 w-64 bg-blue-600 text-white rounded-xl hover:bg-blue-700 mt-10' onClick={handleOnclickRequest}>Continue</button>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default CallbackRequestModal
