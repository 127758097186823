import React, { useCallback, useEffect, useState } from 'react'
import CustomButton from '../buttons/CustomButton'
import { Case } from '../../classes/model/Case'

import uploadImageIcon from '../../assets/Upload image.png'
import back_arrow from '../../assets/Back.png'
import { useNavigate } from 'react-router-dom'
import { useDropzone } from 'react-dropzone';
import PictureGuidelines from '../modals/PictureGuidelines'
import { IImage } from '../../classes/interface/IImage'
import Index from '../modals/Index'
import ProgressBar from './ProgressBar'
import FileViewer from '../modals/FileViewer'

import StandardContainer from '../container/StandardContainer'
import CustomCard from '../container/CustomCard'
import ImageVariables from '../../global/ImageVariables'
import { getParsedDataFromLocalStorage } from '../../classes/DataParser'
import BackButton from '../BackButton'
import db, { getAllFiles, deleteFileByFileName, deleteAllFiles } from './db';



interface FileData {
  path: string;
  lastModified: number;
  //lastModifiedDate: string;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
}

const Concern = () => {
  const navigate = useNavigate()
  const localConcern : any = getParsedDataFromLocalStorage('callbackRequest_concern');
  const localIsOthers = getParsedDataFromLocalStorage('isOthers');
  const localIsCheckbox = getParsedDataFromLocalStorage('callbackRequest_checkbox');

  const localPreviews : any = getParsedDataFromLocalStorage('localPreviews');
  const localFiles : any = getParsedDataFromLocalStorage('localFiles');
  const isRefreshed : any = getParsedDataFromLocalStorage('isRefreshed');


  const [concern, setConcern] = useState<any>(localConcern || '')
  const [concerns, setConcerns] = useState<any[]>()
  const [files, setFiles] = useState<any[]>(localFiles || []);
  const [previews, setPreviews] = useState<any[]>([]);

  const [isShowImage, setIsShowImage] = useState(false)
  const [selectedFile, setSelectedFile] = useState<any>()

  const [images, setImages] = useState<IImage[]>([]);

  const [isShowPictureGuidelines, setIsShowPictureGuidelines] = useState(false)
  const [isOthers, setIsOthers] = useState(localIsOthers || false)

  const [showModal, setShowModal] = useState(false)
  const [message, setMessage] = useState<any>('')
  //const [filename, setFilename] = useState<any>('')
  const [isImage, setIsImage] = useState(false)

  const [coc, setCoc] = useState<any>(localIsCheckbox || false)
  let counter = 0;


  //for tomorrow need to map the localpreviews and set it again to the state
  //maybe on the usestate to do the mapping

  const onDrop = useCallback(async (acceptedFiles: File[]) => {

    const validFileTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];

    const validFiles = acceptedFiles.filter(file => validFileTypes.includes(file.type));

    if (validFiles.length > 0) {
      setMessage('');

    } else {
      setShowModal(true)
      setMessage('Only PDF and image files are allowed (JPG, JPEG, PNG, PDF).');
      return;
    }

    const limitFiles = [...previews, ...acceptedFiles]

    if (acceptedFiles.length > 5 || limitFiles.length > 5) {
      // Show the modal if more than 5 files are uploaded
      setShowModal(true);
      setMessage('File Limit Exceeded. You can only upload up to a maximum of 5 files')
      return;
    }

    var fileSizeCheck = 0
    acceptedFiles.forEach((file) => {
        if (file.size > 10e6) {
          // Check if file size is less than 10mb
          setShowModal(true);
          setMessage("Please upload a file smaller than 10 MB");
          fileSizeCheck = 1;
        }
    })

    if(fileSizeCheck == 1) {
        return;
    }

    for (let file of acceptedFiles) {
      // Convert the file to a Blob (in this case, it is already a Blob, so we can directly use it)
      const fileBlob = file;
      //setFiles(prevFiles => [...prevFiles, file])

      // Save the file's name and data (Blob) to the Dexie database
      try {
        await db.filesTable.add({
          fileName: file.name,
          fileData: fileBlob,
        });
        console.log(`File ${file.name} saved successfully!`);
      } catch (error) {
        console.error(`Error saving file ${file.name}:`, error);
      }
    }

    const newPreviews = acceptedFiles.map(file => ({
      previewUrl: URL.createObjectURL(file),
      fileName: file.name,
    }));
    
    setPreviews(prevPreviews => [...prevPreviews, ...newPreviews]);
   
    return () => {
      newPreviews.forEach(url => URL.revokeObjectURL(url.previewUrl));
    };
  }, [previews]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {

      const fetchFiles = async () => {
        const files = await getAllFiles();
        console.log("db files", files)


        const newPreviews = files.map(file => ({
          previewUrl: URL.createObjectURL(file.fileData),
          fileName: file.fileName,
        }));

        console.log("counter", counter)

        if(counter === 0){
          counter = 1
          setPreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
        }
        // Update state with the filtered previews (only new ones)

      };


    const getConcernRequest = async () => {

      const concerns = new Case()
      const response: any = await concerns.getConcerns('getConcerns_endpoint')

      setConcerns(arrangeConcerns(response))
    }
    
    fetchFiles();
    getConcernRequest()
    

  }, [])


const arrangeConcerns = (data: any[]) => {
    var tempData:any[] = []
    var othersExist:boolean = false

    data.forEach((item) => {
        if(item !== 'Others') {
            tempData.push(item)
        } else {
            othersExist = true
        }
    })

    if(othersExist) tempData.push('Others')

    return tempData
}

const validateField = (): boolean => {
  return concern.trim() === '';
};


  const handleDropdownRequest = (e : any) => {

    if(e.target.value !== 'Others'){
      localStorage.setItem("isOthers", JSON.stringify(false))
      localStorage.setItem("callbackRequest_concern", JSON.stringify(e.target.value))
      setIsOthers(false)
      setConcern(e.target.value)
    }else{
      setIsOthers(true)
      localStorage.setItem("isOthers", JSON.stringify(true))
      setConcern("")
    }
  }


  const handleCallbackRequest = () => {

    if(!validateField()){

      images.forEach((image, key) => {
          // ImageVariables[key].filename = image.filename;
          // ImageVariables[key].base64string = image.base64string;
          ImageVariables[key] = {
              filename: image.filename,
              base64string: image.base64string
          }
      })

      // localStorage.setItem('images', JSON.stringify(images))
      localStorage.setItem("callbackRequest_concern", JSON.stringify(concern))
      if(isOthers){
        localStorage.setItem("isOthers", JSON.stringify(isOthers))
      }
      navigate('/set-appointment')

    }

   setMessage('Please select your health concern')
   setShowModal(true)

  }

  const handleCheckboxRequest = (event : any) => {
    setCoc(event.target.checked)
    localStorage.setItem("callbackRequest_checkbox", JSON.stringify(event.target.checked))

  }

  const handleClearQueue = async () => {
    await deleteAllFiles();
    setPreviews([])
    /*localStorage.removeItem("localFiles");
    localStorage.removeItem("localPreviews");*/
  }

  const handleRemoveImage = (url : any, filename : any) => {
    console.log("clicked url", url)

    deleteFileByFileName(filename)
    const filteredData = previews.filter(item => !url.includes(item.previewUrl));
    setPreviews(filteredData)

    //localStorage.setItem("localPreviews", JSON.stringify(filteredData));

  }

  const handleTextareaRequest = () => {
    console.log("concern", concern)
    console.log("textarea on blur ")
    localStorage.setItem("callbackRequest_concern", JSON.stringify(concern))

  }

  return (
    <StandardContainer>

    <BackButton route="/type"/>

    <div className='flex flex-col items-center justify-center w-full'>
      <p className='text-4xl text-blue-500 font-bold w-[80%]'>Schedule Your Appointment</p>

      <CustomCard className={`flex flex-row items-center justify-center w-[80%] mt-10 space-x-2 bg-gray-50 rounded-2xl shadow-md py-[3%]`}>

        <div className='flex flex-col justify-center items-center mt-5 h-full w-full'>

        <div className='flex w-[80%] mx-auto items-center'>
            <ProgressBar stage={2} request={concern} coc={coc}/>
          </div>

          <div className='flex flex-col items-center justify-center space-x-7 w-full'>

            <p className='text-xl font-semibold text-blue-500'>What is your concern?</p>

            <select className="border h-9 w-[50%] mt-5 border-b-4 border-b-green-300" onChange={handleDropdownRequest}>
              <option className='text-gray-500'>{`${isOthers ? "Others" : localConcern !== null ? localConcern : "Choose a health concern"}`}</option>
                {concerns?.sort((a, b) => {
                  if (a === 'Others') return 1;
                  if (b === 'Others') return -1;
                  return a.localeCompare(b); }).map((item) => (
                 <option key={item} value={item}>{item}</option>
                ))}
            </select>

            { isOthers && ( <textarea className='content-center border border-blue-500 rounded-lg w-[25rem] text-center h-[5rem] my-5 placeholder:pl-3 px-3' placeholder='Please specify your concern' required onBlur={handleTextareaRequest} onChange={(e) => setConcern(e.target.value)} value={ isOthers ? concern : "" }/> )}

            <p className='text-gray-600 font-semibold text-sm mt-3'>Upload at least 3 (maximum of 5) images for our doctor's evaluation</p>

            <div className='flex flex-col mt-5'>

              {previews.length === 0 ? <div {...getRootProps({ className: 'flex flex-row items-center justify-center border border-gray-200 h-12 w-48 rounded-lg shadow-lg cursor-pointer mx-auto dropzone' })}>
                  <img src={uploadImageIcon} className='w-10 h-10'/>
                  <p className='text-blue-600 font-semibold text-sm ml-2'>Upload image</p>
                  <input {...getInputProps()} />
                </div>
              : null}

              <div className='flex flex-row'>

              {previews?.map((preview : any, index) => (

                console.log("previews.length", previews.length),
                console.log("preview", preview.previewUrl),

                preview.fileName.substring(preview.fileName.lastIndexOf('.') + 1) === "pdf" ?
                <div className='flex flex-row mt-auto'>
                  <div className='flex flex-col mx-2 h-full'>
                    <p className='ml-auto hover:text-xl hover:bg-gray-300 hover:rounded-full hover:w-7 hover:text-center cursor-pointer' onClick={() => handleRemoveImage(preview.previewUrl, preview.fileName)}>x</p>
                    <p className='flex justify-center items-center pt-auto h-24 border border-black rounded-xl w-24 cursor-pointer' onClick={() => {setSelectedFile(preview.previewUrl); setIsShowImage(true); setIsImage(false); {/**setFilename(files[index][0].name) */}}} >Pdf {index + 1}</p></div>
                  </div> :
                  <div className='flex flex-col mx-2'>
                    <p className='ml-auto hover:text-xl hover:bg-gray-300 hover:rounded-full hover:w-7 hover:text-center cursor-pointer' onClick={() => handleRemoveImage(preview.previewUrl, preview.fileName)}>x</p>
                    <img key={index} src={preview.previewUrl} alt={`Preview ${index}`} className='h-[100px] w-[100px] object-cover transform transition-transform duration-300 group-hover:scale-110 cursor-pointer' onClick={() => {console.log("image clicked"); setSelectedFile(preview.previewUrl); setIsShowImage(true); setIsImage(true); {/**setFilename(files[index][0].name) */}}}/>
                  </div>
                
              ))}
              
                {previews.length === 5 || previews.length === 0 ? null : <div {...getRootProps({ className: 'ml-[10%] flex items-center justify-center rounded-2xl border-2 border-gray-200 h-full w-[20%] cursor-pointer' })}>
                    +
                  <input {...getInputProps()}/>
                </div> }
              </div>


              {previews.length !== 0 ? 
              
              <div className='flex flex-row items-center justify-center shadow-lg w-[40%] h-11 bg-gray-50 mt-[5%] border border-gray-200 rounded-xl mx-auto font-semibold text-gray-400 cursor-pointer mb-5' onClick={handleClearQueue}>
                <p className='flex justify-center items-center w-8 h-8 rounded-full border-2 border-gray-200 mr-2'>X</p>
                <p>Clear queue</p>
              </div> :
              
              <div className='flex flex-col'>
              <div {...getRootProps({ className: 'flex flex-col justify-center items-center mt-10 text-gray-400 border-dashed border-2 border-gray-400 h-20 w-full dropzone' })}>
                    <p className='font-semibold '>Drag your files to upload</p>
                    <p className='text-sm'>Supports JPG, JPEG with a max size of 10MB</p>
                    <input {...getInputProps()} />
                </div>
             </div> }

             <p className='flex text-blue-600 text-xs justify-end mt-1 cursor-pointer' onClick={() => setIsShowPictureGuidelines(true)}>Guidelines on Photo Capturing</p>

              <div className='flex flex-row text-blue-600 space-x-3 text-sm mt-4 justify-center'>
                <input type='checkbox' onClick={handleCheckboxRequest} onKeyDown={(e) => { if (e.key === "Enter") { handleCallbackRequest() } }} checked={coc === true ? true : false}/>
                <p className='text-[0.81rem]'>I confirm that the information provided is accurate and correct.</p>
              </div>
              {/**<div className='flex items-center justify-center border border-gray-200 h-9 w-40 rounded-lg shadow-lg'>
                <p className='text-gray-500 font-semibold text-sm'>Clear queue</p>
              </div> */}
            </div>
          </div>

          { coc ?
            <div className='flex flex-row items-center justify-center space-x-5 mt-5'>
              <button className='rounded-full dark-blue-bg h-12 w-40 text-white font-semibold hover:bg-blue-600' onClick={handleCallbackRequest}>Next</button>
            </div> :null
          }

        </div>
      </CustomCard>
      {showModal ? <Index message={message} status={undefined} closeModal={() => setShowModal(false)} page='' email=''/>  : null}
      {isShowImage ? <FileViewer file={selectedFile} closeModal={() => setIsShowImage(false)} filename='' isImage={isImage}/> : null}
      {isShowPictureGuidelines ? <PictureGuidelines closeModal={() => setIsShowPictureGuidelines(false)}/> : null}
    </div>
  </StandardContainer>
  )
}

export default Concern
