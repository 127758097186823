import React from 'react'
import CustomButton from './buttons/CustomButton'
import check_icon from '../assets/Checkbox.png'
import back_arrow from '../assets/Back.png'
import background from '../assets/Backdrop-FINAL.png'
import StandardContainer from './container/StandardContainer'
import CustomCard from './container/CustomCard'
import { useNavigate } from 'react-router-dom'
import BackButton from './BackButton'
const Shop = () => {

  const navigate = useNavigate()

  return (
    <StandardContainer>

        {/**<div className='absolute top-[9%] w-[80%]' onClick={() => navigate('/dashboard')}>
          <img src={back_arrow} className='w-12 h-10 cursor-pointer'/>
        </div> */}
        <BackButton route="/dashboard"/>
        
        <p className='text-4xl text-blue-500 font-bold w-[80%]'>Shop</p>

        <CustomCard className="flex flex-row items-center justify-center w-[80%] m-10 space-x-2 bg-gray-50 rounded-2xl shadow-xl shadow-inner px-5">
          {/*<div className='flex flex-row items-center justify-center h-[60%] w-[80%] mt-10 space-x-2 bg-gray-50 rounded-2xl shadow-lg px-5'>*/}

            <div className='flex flex-col justify-center items-center my-[3%] w-full'>
                <div className='flex flex-col items-center justify-center p-5 rounded-xl border-4 border-blue-500 shadow-md w-[35%] md:w-[50%]'>
                    <p className='text-blue-500 text-lg font-semibold mt-5'>Medgate Unliconsultation Plan</p>
                    <p className='text-blue-500 text-4xl font-bold'>&#8369; 999.00/year</p>
                    <p className='text-xs text-blue-500 mb-3'>*VAT ex</p>
                    <button className='rounded-full dark-blue-bg h-12 w-40 text-white font-semibold hover:bg-blue-600 mb-3' onClick={(e) => {e.preventDefault()
                    window.location.href = 'https://shop.medgate.ph/'
                    }}>Get my plan now!</button>
                </div>

                <div className='flex flex-col mt-5 text-blue-500'>

                    <p className='font-bold'>Your key to unlocking our full suite of telemedicine services</p>

                    <div className='text-sm mt-5 space-y-1'>
                        <div className='flex flex-row'><img src={check_icon} className='h-5 w-5 mr-2'/><p>Unlimited Teleconsultation</p></div>
                        <div className='flex flex-row'><img src={check_icon} className='h-5 w-5 mr-2'/><p>24/7 access to Filipino specialist doctors</p></div>
                        <div className='flex flex-row'><img src={check_icon} className='h-5 w-5 mr-2'/><p>Care Plan</p></div>
                        <div className='flex flex-row'><img src={check_icon} className='h-5 w-5 mr-2'/><p>E-Prescription</p></div>
                        <div className='flex flex-row'><img src={check_icon} className='h-5 w-5 mr-2'/><p>Medical Certificate</p></div>
                        <div className='flex flex-row'><img src={check_icon} className='h-5 w-5 mr-2'/><p>Php 999 (VAT ex) good for 1 year</p></div>
                    </div>

                    <div className='font-bold text-blue-500 mt-3'>
                        <p>With the plan, you can access the Medgate Telemedicine Center with a simple phone call.</p>
                        <p>You can consult a specialist doctor without the hassle of going to the hospital and waiting in line for hours</p>
                    </div>
                </div>
            </div>
      </CustomCard>

    </StandardContainer>
  )
}

export default Shop
