import React, { useRef, useState } from 'react'
import CustomButton from './buttons/CustomButton'
import backgroundImage from '../assets/home-banner.png'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { OtherRequest } from '../classes/model/OtherRequest'
import Index from './modals/Index'
import Spinner from './spinner/Spinner'
import { User } from '../classes/model/User'
import CustomCard from './container/CustomCard'
import useEnterKey from '../classes/EnterFunction'

interface Props{
  setIsOtpCompleted : any
}

const OTP = ({setIsOtpCompleted} : Props) => {
   const email = JSON.parse(localStorage.getItem('email') || 'null')
   const fromPath = JSON.parse(localStorage.getItem('fromPath') || 'null')

    const location = useLocation();
    const navigate = useNavigate()

    /*console.log('location.state',location.state.email)
    console.log('location.state.fromPath',location.state.fromPath)*/


    const length = 6;
    const [otp, setOtp] = useState<string[]>(Array(length).fill(''));
    const inputRef = useRef<HTMLInputElement[]>(Array(length).fill(null));
    const [message, setMessage] = useState<any>('')
    const [isShowModal, setIsShowModal] = useState(false)
    const [isShowSpinner, setIsShowSpinner] = useState(false)
    const [isResend, setIsResend] = useState(false)
    const [ishowResendMessage, setIshowResendMessage] = useState(false)
    const [ishowFailedResendMessage, setIshowFailedResendMessage] = useState(false)
    //const [email, setEmail] = useState(location?.state?.email)
    //const [fromPath, setFromPath] = useState(location?.state?.fromPath)
    const user = new User();


    const handleOtpRequest = async () => {
      const otpRequest = new OtherRequest()

      setIsShowSpinner(true)
      const combinedOtp = otp.join('')
      console.log("combinedOtp", combinedOtp)

      if(combinedOtp !== ""){
        const response: any = await otpRequest.verifyOTP(combinedOtp , 'validate_otp_endpoint')

        console.log("validate_otp_endpoint", response)

        if(response.message === 'success'){

          console.log("success")
          localStorage.setItem('fromPath', JSON.stringify('/otp'))
          console.log('success otp validation')
          setIsOtpCompleted(true)
          navigate('/verification')
        }else{
          console.log("fail")
          setMessage(response.message.message)
          setIsShowModal(true)
          /*if(response.message === 'Network Error' || response.message === 'timeout exceeded'){
            setErrorMessage(`${response.message.charAt(0).toUpperCase() + response.message.slice(1)}. Please check your internet connection and try again.`)
            setIsShowModal(true)
          }else{
            setErrorMessage(response.message)
            setIsShowModal(true)
          }*/
        }
      }else{
        setMessage("Please input your verification code")
        setIsShowModal(true)
      }
        setIsShowSpinner(false)
    }

    const handleResendOtp = async () => {
      setOtp(Array(length).fill(''));
      setIsResend(true);

      const sendOtpRequest  = await user.resendOTP("resendOtp_endpoint", "password_change", false);
      console.log('sendOtpRequest', sendOtpRequest)

      if(sendOtpRequest.message === 'success'){
        //setMessage("We've sent the OTP code to your email. Please check your inbox and enter the code below")
        setIshowResendMessage(true);
      }else{
        setIshowFailedResendMessage(true)
      }
 
      setIsResend(false);

      setTimeout(() => {
        setIshowResendMessage(false);
        setIshowFailedResendMessage(false);
      }, 5000);

    }

    const handleTextChange = (input: string, index: number) => {
      const newPin = [...otp];
      newPin[index] = input;
      setOtp(newPin);


      if (input.length === 1 && index < length - 1) {
        inputRef.current[index + 1]?.focus();
      }


      if (input.length === 0 && index > 0) {
        inputRef.current[index - 1]?.focus();
      }
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
      const pastedData = event.clipboardData.getData("text");
      const splitData = pastedData.split("").slice(0, length);
  
      const updatedOtp = [...otp];
      splitData.forEach((char, idx) => {
        updatedOtp[idx] = char;
      });
      setOtp(updatedOtp);
  
      // Automatically focus each input field
      splitData.forEach((_, idx) => {
        inputRef.current[idx]?.focus();
      });
    };

    useEnterKey(() => handleOtpRequest());



  return (
    <>
    <div className='flex flex-col bg-cover bg-center bg-no-repeat w-full min-h-screen' style={{backgroundImage: `url(${backgroundImage})`}}>
      <CustomCard className='flex flex-col items-center mt-[10%] ml-[20%] w-[25%] min-w-[320px] min-h-[45%] pb-5 bg-white rounded-3xl'>
        <p className='text-4xl text-blue-500 font-bold mt-[8%]'>OTP Verification</p>

        <div className='flex flex-col w-[80%] mt-[4%] text-sm'>
            <p className='text-large h-16 w-full bg-green-200 rounded-xl text-center pt-3 mb-5'>We've sent a verification code to your email {email}</p>
            <div className={`grid grid-cols-6 gap-2`} onPaste={handlePaste} onKeyDown={(e) => { if (e.key === "Enter") { handleOtpRequest() } }}>
                {Array.from({ length }, (_, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={otp[index]}
                    onChange={(e) => handleTextChange(e.target.value, index)}
                    ref={(ref) => (inputRef.current[index] = ref as HTMLInputElement)}
                    className={`border-2 border-solid border-blue-600 focus:border-blue-600 h-14 outline-none rounded-xl text-lg font-semibold text-center`}
                  />
                ))}
            </div>
        </div>

        {ishowResendMessage ? <div className="text-green-500 text-center mt-3"><p>OTP code successfully sent</p></div> : null}
        {ishowFailedResendMessage ? <div className="text-red-500 text-center mt-3"><p>Failed to resend OTP. Please try again</p></div> : null}


        <span className='flex flex-row mt-3 text-center'><p>Didn't receive a code?</p>{isResend ? <div className='flex items-center ml-2 h-3 w-3 mr-3 '><Spinner /></div> : <p className='text-blue-600 border-b border-b-blue-600 cursor-pointer ml-2' onClick={() => handleResendOtp()}>Resend</p>}</span>
        {isShowSpinner ? <div className='flex items-center justify-center'><Spinner /></div> :
        <div className='flex flex-row items-center justify-center space-x-5 mt-5 w-[80%]'>
            <button className='rounded-2xl bg-blue-600 h-12 w-full text-white font-semibold hover:bg-blue-700' onClick={handleOtpRequest}>Submit</button>
            <button className='rounded-2xl bg-blue-200 h-12 w-full text-blue-600 font-semibold hover:bg-blue-300' onClick={() => navigate(fromPath)}>Back</button>
        </div> }
      </CustomCard>
      <div className='bg-blue-600 h-16 w-full mt-auto'></div>
      {isShowModal ? <Index message={message} status={undefined} closeModal={() => setIsShowModal(false)} page={null} email=''/> : null}
    </div>
    </>
  )
}

export default OTP
