import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { User } from '../../classes/model/User';
import Spinner from '../spinner/Spinner';
import useEnterKey from '../../classes/EnterFunction';

interface Props {
    closeModal: () => void
    setIsShowmodal : any
    setMessage : any
}

const OtpVerification = ({closeModal, setIsShowmodal, setMessage} : Props) => {

    const user = new User();
    const navigate = useNavigate();

    const patient = JSON.parse(localStorage.getItem('user') || '{}')
    console.log('patient', patient)

    const length = 6;
    const [otp, setOtp] = useState<string[]>(Array(length).fill(''));
    const inputRef = useRef<HTMLInputElement[]>(Array(length).fill(null));
    const [errorMessage, setErrorMessage] = useState<any>('')
    const [ishowSuccessResend, setIshowSuccessResend] = useState(false)
    const [isShowSpinner, setIsShowSpinner] = useState(false)
    const [isShowResendSpinner, setIsShowResendSpinner] = useState(false)
    const [isShowError, setIsShowError] = useState(false)
    const modalRef = useRef<HTMLDivElement>(null);


    const handleButtonRequest =  async () => {
        setIsShowSpinner(true)
        setIshowSuccessResend(false);
        setIsShowError(false);

        const combinedCode = otp.join('')

        const response: any = await user.otpValidation(combinedCode, "validate_otp_endpoint")  //codeValidation_endpoint

        console.log('otpValidation', response)

        if(response.message === "success"){
          setMessage(response.message)
          closeModal();
          navigate('/change-password')
          //setIsShowmodal(true)
        }else{
          setErrorMessage(response.message.message)
          //setIsShowmodal(true)
          setIsShowError(true)
        }

        setOtp(Array(length).fill(''));
        setIsShowSpinner(false)

    }

    const handleResendOtp = async () => {

      setIsShowResendSpinner(true)
      setIsShowError(false)
      const sendOtpRequest  = await user.resendOTP("resendOtp_endpoint", 'password_change', true);

      if(sendOtpRequest.message === 'success'){
        setOtp(Array(length).fill(''));
        setIshowSuccessResend(true);
      }
      else{
        setErrorMessage("Failed to resend OTP. Please try again");
        setIsShowError(true)
      }

      setIsShowResendSpinner(false)

      setTimeout(() => {
        setIshowSuccessResend(false);
        setIsShowError(false);
      }, 5000);
    }

    const handleTextChange = (input: string, index: number) => {
        const newPin = [...otp];
        newPin[index] = input;
        setOtp(newPin);


        if (input.length === 1 && index < length - 1) {
          inputRef.current[index + 1]?.focus();
        }


        if (input.length === 0 && index > 0) {
          inputRef.current[index - 1]?.focus();
        }
      };
      

        // Close modal if a click happens outside the modal
      const handleClickOutside = (event: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
          closeModal();
        }
      };

      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

      const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
        const pastedData = event.clipboardData.getData("text");
        const splitData = pastedData.split("").slice(0, length);
    
        const updatedOtp = [...otp];
        splitData.forEach((char, idx) => {
          updatedOtp[idx] = char;
        });
        setOtp(updatedOtp);
    
        // Automatically focus each input field
        splitData.forEach((_, idx) => {
          inputRef.current[idx]?.focus();
        });
      };

    useEnterKey(() => handleButtonRequest());


  return (
    <div className='flex items-center pl-[35%] fixed inset-0 z-10 overflow-y-auto bg-gray-600 bg-opacity-70 h-full w-full'>
    <div className='flex justify-center items-center px-4 py-8'>
      <div className='relative w-full max-w-lg p-4 bg-white shadow-lg shadow-gray-700 rounded-xl'>
      <div ref={modalRef}>

        <div className='flex items-center justify-center'>
          <div className='flex flex-col items-center justify-center'>
            {/**modal content */}
            <p className='ml-auto text-2xl cursor-pointer' onClick={closeModal}>x</p>
            <p className='text-blue-500 font-semibold text-4xl mb-5'>Verify</p>
            <div className='text-center w-full pl-5 bg-green-200 rounded-xl pt-3 pb-2 mb-5'>
              <p>We've sent a verification code to your email</p>
              <p className=''>{patient?.email}</p>
            </div>
            <div className={`grid grid-cols-6 gap-2`} onPaste={handlePaste} onKeyDown={(e) => { if (e.key === "Enter") { handleButtonRequest() } }}>
                {Array.from({ length }, (_, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={otp[index]}
                    onChange={(e) => handleTextChange(e.target.value, index)}
                    ref={(ref) => (inputRef.current[index] = ref as HTMLInputElement)}
                    className={`border-2 border-solid border-blue-600 focus:border-blue-600 h-14 outline-none rounded-xl text-lg font-semibold text-center`}
                  />
                ))}
            </div>
            {ishowSuccessResend ? <p className="text-green-500 mt-5">OTP code successfully sent</p> : null}
            {isShowError ? <p className="text-red-500 text-xl mt-5">{errorMessage}</p> : null }
            {
              isShowSpinner ? <Spinner /> :
              <button className='h-10 w-[50%] bg-blue-600 text-white rounded-xl mt-5 hover:bg-blue-700' onClick={handleButtonRequest}>Submit</button>
            }

              
            <div className={`flex flex-row justify-center items-center ${isShowResendSpinner ? null : 'mt-5'}`}>
              <p>Didn't receive a code?</p>
              {isShowResendSpinner ? <div className='ml-2'><Spinner /></div> : <p className='text-blue-600 border-b border-b-blue-600 cursor-pointer ml-2' onClick={() => handleResendOtp()}>Resend</p> }
            </div>

          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default OtpVerification
