import React from 'react'

const Spinner = () => {
  return (
    <div>
        <svg className="mt-5 text-blue-500 inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" viewBox="0 0 24 24">test</svg>
    </div>
  )
}

export default Spinner