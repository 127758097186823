import { requests } from "../Api";
import { IEmailRegistrationRequest } from "../../interface/IEmail";
import { endpoints } from "../Endpoint";

const USER_REGISTRATION_ENDPOINT = process.env.REACT_APP_RDP_API_ELIGIBILITY_REGISTRATION_ENDPOINT as string

export class Registration extends requests {
    
    async userRegistration({ username, password_confirm, mobile_number, user_type, email, password, is_patient, device_type, device_token, coc }: IEmailRegistrationRequest, request : any) {

        const endpoint = endpoints(request, "POST", false, null);

        const data: IEmailRegistrationRequest = {
            username: username,
            email: email,
            mobile_number: mobile_number,
            password: password,
            password_confirm: password_confirm,
            user_type: user_type,
            is_patient: is_patient,
            device_type: device_type,
            device_token: device_token,
            coc: coc
        }

        console.log('userRegistration', data)

        let response = await this.postRequest(endpoint, data, request);

        if (response?.message === 'success') {

            localStorage.setItem('temp_api_token', JSON.stringify(response?.data.token.accessToken))
        }

        return response;
    }
}