import { SCRequests } from "../../../utils/SCRequests";

//todo: maybe get from local storage if it already exists?
export class OrganSystem extends SCRequests {

    async getAll() {
        const response = await this.fetchRequest('/path/linear');

        localStorage.setItem('organ_list', JSON.stringify(response.data))

        return response.data;
    }
}
