import React, { useState, useEffect } from 'react'
import { User } from '../classes/model/User'
import { IEmailRegistrationResponse } from '../classes/interface/IEmail'
import { Link, useNavigate } from 'react-router-dom'
import CustomButton from './buttons/CustomButton'
import Collapsible from './Collapsible'
import backgroundImage from '../assets/home-banner.png'

import { IPasswordState } from '../classes/interface/IPassword'
import PasswordStrengthBar from './PasswordStrengthBar'
import { countTrueValues } from '../utils/countTrueValues'
import { validate } from '../utils/validator'
import Spinner from './spinner/Spinner'
import Index from './modals/Index'
import CustomCard from './container/CustomCard'
import NotificationPermission from './modals/NotificationPermission'
import { getFirebaseToken } from '../hooks/getFirebaseToken'
import eye from '../assets/eye.png'
import eyeWithLine from '../assets/eye-with-line.png'
import RegistrationModal from './modals/RegistrationModal'
import useEnterKey from '../classes/EnterFunction'


const PASS_MIN_LENGTH = process.env.REACT_APP_PASS_MIN_LENGTH as string

interface Props{
  onComplete : any
}

const Registration = () => {
  const navigate = useNavigate()
  const user = new User()

  const [noticationPermissionIsVisible, setNoticationPermissionIsVisible] = useState<boolean>(false);
  const [isShowPassword, setIsShowPassword] = useState(false)

  /*const [username, setUsername] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [password, setPassword] = useState('')*/

  const [inputFields, setInputFields] = useState<{[key: string]: string }>({
    username: '',
    mobileNumber: '',
    password: '',
    termsAndCondition: '',
    codeOfConduct: ''
  });

  const [invalidFields, setInvalidFields] = useState<{[key: string]: boolean }>({
    username: false,
    mobileNumber: false,
    password: false,
    termsAndCondition: false,
    codeOfConduct: false
  });

  const [passwordState, setPasswordState] = useState<IPasswordState>({
    hasMinLength: false,
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumber: false,
    hasSpecialChar: false,
  })

  const [errorMessage, setErrorMessage] = useState<any>('')
  const [isShowAnimation, setIsShowAnimation] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showRegistrationModal, setShowRegistrationModal] = useState(false)

  const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    
      // Function to update dimensions
      const updateDimensions = () => {
        setDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };

      useEffect(() => {
    
        window.addEventListener('resize', updateDimensions);
        return () => {
          window.removeEventListener('resize', updateDimensions);
        };
      }, []);


      console.log("dimension", dimensions)

  const handleRegistrationRequest = async (skipToken: boolean) => {

    let device_token;
    let viewPopup = false;

    localStorage.removeItem('temp_api_token');
    localStorage.removeItem('email');
    localStorage.removeItem('fromPath');
    localStorage.removeItem('api_token');

    const cocValue = inputFields.termsAndCondition === 'true' ? 1 : 0

    setIsShowAnimation(true)

    if (validateFields()) {

      if (!skipToken) {
        let firebase_token = localStorage.getItem('firebase_token');
        if (firebase_token != null && firebase_token != '') {
          device_token = JSON.parse(firebase_token);
        } else {
          setNoticationPermissionIsVisible(true);
          viewPopup = true;
        }
      } else {
        device_token = '';
        setNoticationPermissionIsVisible(false);
        setIsShowAnimation(false);
      }

      if (viewPopup) {
        return ;
      }

      const response = await user.userRegistration({
        username: inputFields.username,
        password_confirm: inputFields.password,
        mobile_number: inputFields.mobileNumber,
        user_type: 'patient',
        email: inputFields.username,
        password: inputFields.password,
        is_patient: true,
        device_type: 'web',
        device_token: device_token,
        coc: cocValue
      }, 'registration_endpoint')

      console.log('registration response', response)

      

      if(response.message === 'success'){

        //navigate('/otp', {state : {email : inputFields.username, token : response?.data?.token?.accessToken, fromPath : '/registration'}})
        localStorage.setItem('email', JSON.stringify(inputFields.username))
        localStorage.setItem('fromPath', JSON.stringify('/registration'))
        navigate('/otp')
      }else{

          if((response.message.message == "Email is already in use. Please reset your password to retrieve it or contact support.")) {
              setErrorMessage("An account with this email already exists")
              setShowRegistrationModal(true)

          } else if(response.message.message === null || response.message.message === undefined){
            setErrorMessage(response.message.email[0])
            setShowErrorModal(true)

          } else {
              setErrorMessage(response.message.message)
              setShowErrorModal(true)
          }
      }

    }else{

      // const invalidFieldsString = Object.entries(invalidFields)
      //   .filter(([_, isInvalid]) => isInvalid) // Keep only entries with true values
      //   .map(([key]) => key.charAt(0).toUpperCase() + key.slice(1))
      //   .map((key) => key.replace(/([A-Z])/g, '\n$1').trim()) // Extract keys from the filtered entries
      //   .join('\n');

      var invalidFieldsString = generateInvalidFieldsString()
      console.log('invalidFieldsString', invalidFieldsString)
      setErrorMessage(`${invalidFieldsString}`)
      setShowErrorModal(true)
    }

    setIsShowAnimation(false)
  }

  const validateFields = (): boolean => {
    const newInvalidFields: { [key: string]: boolean } = {};

    Object.keys(inputFields).forEach(key => {
        console.log(key)
        console.log(inputFields[key].trim())
      newInvalidFields[key] = inputFields[key].trim() == '' || inputFields[key].trim() == 'false';
    });

    console.log('newInvalidFields', newInvalidFields)

    setInvalidFields(newInvalidFields);
    return !Object.values(newInvalidFields).includes(true);
  };

  const generateInvalidFieldsString = () => {

      let count = 0;
      let checkboxCount = 0
      let checkboxName = ""
      const newInvalidFields: { [key: string]: boolean } = {};

      Object.keys(inputFields).forEach(key => {
        newInvalidFields[key] = inputFields[key].trim() == '' || inputFields[key].trim() == 'false';
      });

      Object.entries(newInvalidFields).forEach((field: any, index: any) => {
        if(field[1] === true){
            count++
        }

        if(field[1] === true && field[0] === 'termsAndCondition' || field[1] === true && field[0] === 'codeOfConduct'){
          checkboxCount++
        }

        console.log("field[0]", field[0])
      })

      console.log("checkboxCount", checkboxCount)
      var tempString: String = ""
      if(count === 5){
        tempString = "Please fill in all required fields and agree to the Terms and Conditions and Code of Conduct"
      }else{
        Object.entries(newInvalidFields).forEach((field: any, index: any) => {
          if(field[1] == true) {
              console.log("field[0]", field[0])
              console.log("field[1]", field[1])
              if (tempString !== "") {
                tempString += "\n"; //
              }
              if(field[0] == 'username') {
                  tempString += "Username is a required field."
              } else if(field[0] == 'mobileNumber') {
                  tempString += "Mobile Number is a required field."
              } else if(field[0] == 'password') {
                  tempString += "Password is a required field."
              } else if(field[0] == 'termsAndCondition') {
                  checkboxName = "termsAndCondition"
                  //tempString += "Please agree to the Terms and Conditions."
              } else if(field[0] == 'codeOfConduct') {
                checkboxName = "codeOfConduct"
                  //tempString += "Please agree to the Code of Conduct."
              }
          }
      });
      }

      if(checkboxCount === 2){
        tempString += "Please agree to the Terms and Conditions and Code of Conduct."
      }else{
        if(checkboxCount !== 0){
          if(checkboxName === "termsAndCondition"){
            tempString += "Please agree to the Terms and Conditions."
          }else{
            tempString += "Please agree to the Code of Conduct."
          }
        }
      }

      console.log("return tempString", tempString)
      return tempString
  }

  /*const inpPassword_onChange = (password: string) => {
    validatePasswordState(password)

    setPassword(password)
  }*/

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    console.log('name', name)
    console.log('value', e.target.checked)

    if(name === 'password'){
      validatePasswordState(value)
    }

    if (name === 'mobileNumber') {
      // Filter the value to allow only numbers
      const numericValue = value.replace(/[^0-9]/g, '');
      setInputFields({ ...inputFields, [name]: numericValue });
    }else{
      setInputFields(prev => ({ ...prev, [name]: type === 'checkbox' ? checked.toString() : value }));
    }
  };

  // Password validation for strength bar
  const validatePasswordState = (password: string) => {
    passwordState.hasMinLength = validate.min(+PASS_MIN_LENGTH, password.length)
    passwordState.hasLowerCase = validate.hasLowerCase(password)
    passwordState.hasUpperCase = validate.hasUpperCase(password)
    passwordState.hasNumber = validate.hasNumber(password)
    passwordState.hasSpecialChar = validate.hasSpecialChar(password)
    setPasswordState(passwordState)
  }


  const asyncGetFirebaseToken = async () => {
    let firebaseToken = await getFirebaseToken()
    if (firebaseToken) {
      console.log('firebase:', firebaseToken);
      localStorage.setItem('firebase_token', JSON.stringify(firebaseToken))
    } else {
      console.log('Unable to get Firebase token')
    }
  }

  useEffect(() => {
    localStorage.removeItem('firebase_token');
    asyncGetFirebaseToken();
    return () => {
      localStorage.removeItem('firebase_token');
    }
  }, [])


  useEnterKey(() => handleRegistrationRequest(false));

  const isValidPassword = passwordState.hasMinLength && passwordState.hasLowerCase && passwordState.hasUpperCase && passwordState.hasNumber && passwordState.hasSpecialChar;


  return (

  <div
    className='h-screen flex flex-col bg-center w-full overflow-y-auto'>

    <div
      className='h-[100%] min-h-[90vh] flex flex-col bg-center bg-gray-100 w-full overflow-y-auto bg-cover bg-no-repeat overflow-y-hidden'
      style={{ backgroundImage: `url(${backgroundImage})` }}>

      <CustomCard className='flex flex-row items-center justify-center ml-[20%] my-[5%] xl:w-[32%] lg:w-[32%] md:w-[45%] space-x-2 bg-white rounded-xl'>
        <div className='flex flex-col w-[80%] my-7'>
          <p className='text-center text-4xl text-blue-500 font-bold mb-5'>Registration</p>
          <div className=' flex flex-col space-y-7 w-full'>
            <input className='input_design'  name='username' value={inputFields.username} placeholder='Email Address *' onChange={(e) => handleChange(e)} />
            <input className='input_design' name='mobileNumber' value={inputFields.mobileNumber} placeholder='Mobile Number *' onChange={(e) => handleChange(e)}/>


           <div className='flex flex-row w-full !outline-none border-b-2 pb-2'>
              <input className='w-full !outline-none placeholder:text-black' type={isShowPassword ? 'text' : 'password'} name='password' placeholder='Password *' value={inputFields.password} onChange={(e) => handleChange(e)} />
              {isShowPassword ? <img src={eyeWithLine} className='h-7 w-7 cursor-pointer' onClick={() => setIsShowPassword(!isShowPassword)}/> : <img src={eye} className='h-7 w-7 cursor-pointer' onClick={() => setIsShowPassword(!isShowPassword)}/> }
            </div>
           
          </div>
          <PasswordStrengthBar
            className={'my-2'}
            countTrueValues={countTrueValues(passwordState)}
          />
          <div className='flex flex-col text-xs mt-1 italic'>
            <p className={passwordState.hasMinLength ? 'text-custom-green-text' : 'text-custom-red-text'}>Should be at least {PASS_MIN_LENGTH} characters</p>
            <p className={passwordState.hasUpperCase ? 'text-custom-green-text' : 'text-custom-red-text'}>Include at least 1 upper case</p>
            <p className={passwordState.hasLowerCase ? 'text-custom-green-text' : 'text-custom-red-text'}>Include at least 1 lower case</p>
            <p className={passwordState.hasNumber ? 'text-custom-green-text' : 'text-custom-red-text'}>Include at least 1 number</p>
            <p className={passwordState.hasSpecialChar ? 'text-custom-green-text' : 'text-custom-red-text'}>Include at least 1 special character</p>
          </div>
          <div className='mt-5'>

            <div className='xl:text-base md:text-sm'>
              <div className="agreement-checkbox-wrapper">
                <input className='mr-3' type='checkbox' onChange={(e) => handleChange(e)} value={inputFields.termsAndCondition} name='termsAndCondition'/>
                <span>
                  I agree to Medgate's
                  <Link className='text-blue-500 hover:border-b hover:border-blue-500 mr-1 ml-1' to={'https://medgate.ph/terms-and-conditions/'}>Terms and Conditions</Link>
                  <span className={`${ dimensions.width < 1450 ? 'ml-7' : null }`}> and </span>
                  <Link className={`text-blue-500 hover:border-b hover:border-b-blue-500 ${dimensions.width < 1540 && dimensions.width > 1440 ? 'ml-6' : `${dimensions.width == 1600 ? "ml-7" : null }` }`} to={'https://medgate.ph/data-privacy-policy/'}>Privacy Policy</Link>
                </span>
              </div>
            </div>

            <div className='xl:text-base md:text-sm'>
              <div className="agreement-checkbox-wrapper">
                <input className='mr-2' type='checkbox' onChange={(e) => handleChange(e)} value={inputFields.codeOfConduct} name='codeOfConduct'/> I agree to Medgate Patient{' '}
                <Link className='text-blue-500 hover:border-b hover:border-blue-500' to={'https://medgate.ph/medgate-code-of-conduct/'}> Code of Conduct.</Link>
              </div>
            </div>
            
            <div className='xl:text-base md:text-sm'>
              <div className="agreement-checkbox-wrapper">
                <input className='mr-2' type='checkbox'/> I agree to receive Medgate's weekly newsletter.
              </div>
            </div>

          </div>

          <div className='flex flex-row items-center justify-center space-x-5 mt-7 mb-5'>
          {isShowAnimation ? <Spinner /> :
          <div className='flex flex-row space-x-7'>
            <button disabled={!isValidPassword} className={`rounded-full  h-12 w-40 text-white font-semibold ${isValidPassword ? 'dark-blue-bg hover:bg-blue-600' : 'bg-gray-300 cursor-not-allowed'}`} onClick={() => handleRegistrationRequest(false)}>
              Get Started
            </button>
            <button className='rounded-full bg-blue-200 h-12 w-40 text-blue-700 font-semibold hover:bg-blue-300' onClick={() => navigate('/')}>Cancel</button>
          </div>}
          </div>
        </div>
      </CustomCard>
      <div className='bg-blue-600 h-16 w-full mt-auto'></div>
      {showErrorModal ? <Index message={errorMessage} status={undefined} closeModal={() => setShowErrorModal(false)} page={null} email={inputFields.username}/> : null}
      {showRegistrationModal ? < RegistrationModal message={errorMessage} email={inputFields.username}/> : null}
      {
        noticationPermissionIsVisible ?
        <NotificationPermission
          onCancel={ () => {setNoticationPermissionIsVisible(!noticationPermissionIsVisible); setIsShowAnimation(false);} }
          type='email'
          onFacebookSuccess={() => {}}
          onFacebookError={ () => {} }
          onGoogleContinue={ () => {} }
          onEmailContinue={ () => { document.location.reload() } }
          onLoginContinue={() => {}}
          />
        : null
      }
      </div>
    </div>
  )
}

export default Registration
