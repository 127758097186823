import React, { useEffect, useRef, useState } from 'react'
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';


// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.min.mjs',
//     import.meta.url,
//   ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.mjs';

interface fileProps {

    file : any
    closeModal: () => void
    filename : string
    isImage : boolean
}

const FileViewer = ({file, closeModal, filename, isImage} : fileProps) => {

    console.log('image', file)
    console.log('filename', filename)
    console.log('isImage', isImage)


    const modalRef = useRef<HTMLDivElement>(null);


    const fileUrl = '/image_req_infographic.pdf'

    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState(1);
    //const [isImage, setIsImage] = useState(filename.split('.').pop() !== 'pdf' ? true : false)

    const nextPage = () => {
      console.log("nextPage")
      setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages ?? 1));
    };
    
    // Previous page handler
    const prevPage = () => {
      console.log("prevPage")
      setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1));
    };  


    const handleLoadError = (error: Error) => {
        console.error('Error loading PDF:', error.message);
        // You can display an error message to the user here
    };

    const onLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
      };

  // Close modal if a click happens outside the modal
  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
    <div className='flex items-center justify-center fixed inset-0 z-10 overflow-y-auto bg-gray-600 bg-opacity-50 h-full w-full'>
      <div className='flex justify-center items-center px-4 py-8'>
        <div className='relative p-4 bg-white shadow-lg shadow-gray-700 rounded-xl w-[80%] h-max'>
          <div ref={modalRef}>
            <div className='flex flex-col items-center justify-center w-full h-full'>
              {/* Modal content */}
              <p className='text-2xl font-semibold ml-auto cursor-pointer text-gray-500 mr-1 mb-3' onClick={closeModal}>x</p>

              {isImage ? (
                <img src={file} className='max-h-[80vh] h-[85%] mb-3 object-contain' />
              ) : (
                <div className="w-full overflow-hidden relative">
                  <Document
                    file={file}
                    onLoadSuccess={onLoadSuccess}
                    onLoadError={handleLoadError}
                  >
                    <Page
                      pageNumber={pageNumber}
                      width={undefined}  // This ensures responsive scaling
                      className="max-w-full h-auto object-contain"
                    />
                  </Document>
                </div>
              )}

              {isImage ? null : (
                  <div className='flex flex-row space-x-5 mt-5'>
                  <button
                    className={`mr-5 border-2 bg-blue-500 h-10 w-20 text-white rounded-xl ${
                      pageNumber <= 1 ? 'cursor-not-allowed' : 'cursor-pointer'
                    }`}
                    onClick={prevPage}
                    disabled={pageNumber <= 1}
                  >
                    Previous
                  </button>
                  <span>Page {pageNumber} of {numPages}</span>
                  <button
                    className={`ml-5 border-2 bg-blue-500 h-10 w-20 text-white rounded-xl ${
                      pageNumber >= (numPages ?? 1) ? 'cursor-not-allowed' : 'cursor-pointer'
                    }`}
                    onClick={nextPage}
                    disabled={pageNumber >= (numPages ?? 1)}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default FileViewer
