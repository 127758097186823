import React, { useState } from 'react'
import backArrow from '../../assets/back-arrow.png'
import { useNavigate, useSearchParams } from 'react-router-dom'
import password from '../../assets/I have an access code- blue.png'
import arrow from '../../assets/blue-triangle.png'
import { User } from '../../classes/model/User'
import OtpVerification from '../modals/OtpVerification'
import Spinner from '../spinner/Spinner'
import Modal from '../modals/Index';


const Index = () => {

  const navigate = useNavigate();
  const user = new User()

  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);
  const [message, setMessage] = useState<any>('')
  const [isShowSpinner, setIsShowSpinner] = useState(false)



  const handleResendOtp = async () => {
    setIsShowSpinner(true)
    const sendOtpRequest  = await user.resendOTP("resendOtp_endpoint", 'password_change', true);
    setIsShowModal(true);
    if(sendOtpRequest.message === 'success'){
      setIsShowModal(true);
    }else{
      setMessage(sendOtpRequest.message)
      setIsShowErrorModal(true)
    }

    setIsShowSpinner(false)
  }

  return (
    <>
      <div className='h-screen flex flex-col items-center bg-gray-100 w-full'>

        <div className='flex flex-row items-center rounded-xl bg-blue-600 text-white w-[60%] space-x-5 mt-10'>
          <img src={backArrow} className='ml-5 cursor-pointer' onClick={() => navigate('/account')}/>
          <p className='text-xl font-semibold'>Settings</p>
        </div>

        <div className='flex flex-row items-center h-16 w-[60%] bg-white rounded-xl text-blue-500 text-xl font-semibold mt-5 cursor-pointer' onClick={handleResendOtp}>
              <img src={password} className='h-8 w-8 ml-2'/>
              <p className='ml-5'>Change Password</p>
              {isShowSpinner ? <div className='flex items-center justify-center h-5 w-5 ml-auto mr-5'><Spinner /></div> : <img src={arrow} className='h-5 w-5 ml-auto mr-5'/>}
        </div>
      </div>
      {isShowModal ? <OtpVerification  closeModal={() => setIsShowModal(false)}  setIsShowmodal={setIsShowModal} setMessage={() => setMessage}/> : null}
      {isShowErrorModal ? <Modal message={message} status={undefined} closeModal={() => setIsShowErrorModal(false)} page='' email=''/> : null}
    </>
  )
}

export default Index
