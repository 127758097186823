import axios from 'axios'

export class requests {

    fetchRequest = async (endpoint : any , request : any) => {


          
          console.log("url", endpoint[request].url)
          
        try {
          const bearer = {
            headers :  endpoint[request].headers
          }
          //const response = await axios.get(`${baseUrl}${endpoint}`, bearer)
          const response = await axios.get(endpoint[request].url, bearer)
          return response.data
    
        } catch (error : any) {
          console.log('error', error)

          if(error.message === 'Network Error' || 'timeout exceeded'){
            return {
              message : error.message
            }

          }else if(error.message === 'Request failed with status code 400' || 'Request failed with status code 500'){
              return {
                message : error.response.data
              }
          }else if(error.message === 'Request failed with status code 422'){
            return {
              message : error.response.data.message
            }
          }
          else{
           return {
             message : error.message
           }
          }
        }
      }


      postRequest = async (endpoint : any, data: any, request : any) => {

        console.log('postRequest', endpoint[request])
      
        try {
    
        const bearer = {
          headers: endpoint[request].headers
        }
    
          //const response = await axios.post(`${baseUrl}${endpoint}`, data, bearer)
          const response = await axios.post(endpoint[request].url, data, bearer)
          console.log("api response", response)
          
          return {
            message : 'success',
            data : response.data
          }
    
        } catch (error : any) {
    
          console.log('error', error)
    
          if(error.message === 'Network Error' || error.message === 'timeout exceeded'){
          console.log('Network Error')

            return {
              message : error.message
            }

          }else if(error.message === 'Request failed with status code 400' || error.message === 'Request failed with status code 500'){
            console.log('only 400 and 500')
              return {
                message : error.response.data
              }
          }else if(error.message === 'Request failed with status code 422'){
            console.log('only 422')
    
            return {
              message : error.response.data.message
            }
          }
          else{
            console.log('default')
           return {
             message : error.message
           }
          }
         
        }
      }



}