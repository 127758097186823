import React, { ChangeEvent, useEffect, useState } from 'react'
import backArrow from '../../assets/back-arrow.png'
import { useNavigate } from 'react-router-dom';
import { User } from '../../classes/model/User';
import { IEmailRequest } from '../../classes/interface/IEmail';
import StandardContainer from '../container/StandardContainer'
import CustomCard from '../container/CustomCard'
import { belongsTo } from '../../classes/data/belongsTo';
import { Categories } from '../../classes/data/Categories';
import Spinner from '../spinner/Spinner';
import Index from '../modals/Index';

const ContactEmail = () => {

  const navigate = useNavigate();
  const userRequest = new User()
  const user = JSON.parse(localStorage.getItem('user') || '{}')
  const emails = JSON.parse(user.patient.patient_email_addresses)

  console.log(emails)

  const [emailOptions, setEmailOptions] = useState<any[]>(emails)
  const [primaryEmail, setPrimaryEmail] = useState<any>(emails[0].email)
  const [alternateEmail, setAlternateEmail] = useState<any>(user.patient.alternate_email)

  const [showSpinner, setShowSpinner] = useState(false)
  const [showShowModal, setShowModal] = useState(false)
  const [message, setMessage] = useState<any>('')
  const [page, setPage] = useState<any>('')

  const handleUpdateEmail = async () => {

    console.log('emailOptions', emailOptions)

    setShowSpinner(true)

    const response = await userRequest.updateEmail({email : user.email, alternate_email : alternateEmail, email_options : emailOptions }, "updateEmail_endpoint");
    console.log("email update response", response)

    if(response.message === 'success'){
      localStorage.setItem("user", JSON.stringify(response.data))
      setMessage('Your email address has been saved successfully.')
      setPage('/profile')
    }else{
      setMessage(response.message.email_options[0])
      setPage('/emails')

    }

    console.log('update email response', response)
    setShowSpinner(false)
    setShowModal(true)

  }

  const  handleInputOnchangeRequest = (index: number, newValue: string) => {
    const updatedOptions = [...emailOptions];
    updatedOptions[index].email = newValue;
    setEmailOptions(updatedOptions);
  }

  const  handleSetAsPrimaryRequest = (index: number,  event : ChangeEvent<HTMLInputElement>) => {

    setPrimaryEmail(emailOptions[index].email)
    const isChecked = event.target.checked

    setEmailOptions(
        emailOptions.map((item, idx) => {
          if (idx === index && isChecked) {
            return { ...item, is_primary: isChecked ? '1'  : '0'}
          } else {
            // Unchecks other checkboxes
            return { ...item, is_primary: '0' }
          }
        })
      )
  }

  const  handleSetAsalternateRequest = (email : any) => {
    setAlternateEmail(email)
  }

  const handleUpdateDropdownRequest = (event : any, index : any, type : any) => {

    //every time the user change the belong to, need to find in the array and update the state
    if(type === 'category'){
      const updatedOptions = [...emailOptions];
      updatedOptions[index].email_category = event.target.value;
      setEmailOptions(updatedOptions);

    }else{
      const updatedOptions = [...emailOptions];
      updatedOptions[index].belongs_to = event.target.value;
      setEmailOptions(updatedOptions);
    }
    
  }


  return (
<>
    <StandardContainer className='flex flex-col items-center bg-gray-100 rounded-xl border border-gray-200 shadow-lg w-full'>

        <div className='flex flex-row items-center rounded-xl dark-blue-bg text-white w-[60%]'>
            <img src={backArrow} className='ml-5 cursor-pointer' onClick={() => navigate('/profile')}/>
            <p className='text-xl font-semibold ml-5'>Contact Information</p>
            { showSpinner ? <div className='ml-auto mr-5 pb-2'><Spinner /></div> : 
            <p className='text-white text-lg font-semibold ml-auto mr-5 cursor-pointer' onClick={handleUpdateEmail}>Done</p> }
        </div>

        <CustomCard className='flex flex-col bg-gray-50 shadow-lg w-[60%] border border-gray-200 rounded-xl mt-5 mb-10'>

            <div className='flex flex-col w-full mx-[10%]'>

                <p className='text-xl font-semibold text-blue-600 mt-5'>Current Email Settings</p>
                <div className='flex flex-row mt-3 w-[80%]'>
                    <div className='flex flex-row space-x-[5%] w-full'>
                        <div className='flex flex-col w-full pb-1 border-b-2 border-b-gray-300'>
                            <p>Primary Email</p>
                            <p className='mt-2 ml-2'>{primaryEmail}</p>
                        </div>

                        <div className='flex flex-col w-full pb-1 border-b-2 border-b-gray-300'>
                            <p>Alternative Email</p>
                            <p className='mt-2 ml-2 mb'>{alternateEmail}</p>
                        </div>
                    </div>
                </div>


              <div className='flex flex-col w-[80%]'>
                <p className='text-xl font-semibold text-blue-600 mt-5'>Email Options</p>

                <div className='mb-5'>
                    {emailOptions.map((item : any, index : any) => (


                        <div className={`flex flex-col w-full ${item.is_primary === '1' ? 'pointer-events-none opacity-50' : null}`} key={index}> {/**className='flex flex-col w-full' */}

                            <div className='flex flex-col pb-1 border-b-2 border-b-gray-300 mt-5' key={index}>
                                <p className='font-semibold'>Email</p>
                                <input type='text' className='!outline-none w-full bg-gray-50 placeholder:text-black' value={item.email}  onChange={(e) => handleInputOnchangeRequest(index, e.target.value)} />
                            </div>

                            <div className='mt-3'>
                                <div className='flex flex-row space-x-[5%] w-full'>

                                    <div className='pb-1 border-b-2 border-b-gray-300 w-full'>
                                        <p>Contact Category</p>
                                        <select className='w-full bg-gray-50 outline-none border-none' value={item.email_category} onChange={(e) => handleUpdateDropdownRequest(e, index, 'category')}>
                                          {Categories.map(( category : any ) => (
                                            <option key={index}>
                                              {category.category}
                                            </option>
                                          ))}
                                        </select>
                                    </div>

                                    <div className='pb-1 border-b-2 border-b-gray-300 w-full'>
                                        <p>Belong To</p>
                                        <select className='w-full bg-gray-50 outline-none border-none' value={item.belongs_to} onChange={(e) => handleUpdateDropdownRequest(e, index, 'belong')}>
                                          {belongsTo.map(( belongTo : any ) => (
                                            <option key={index}>
                                              {belongTo.belong}
                                            </option>
                                          ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className='flex flex-row items-center space-x-2 mt-2'>
                               <div className='flex flex-row mr-3 items-center'>
                                    <input className='rounded-full mr-1 w-4 h-4' type='checkbox' checked={item.is_primary == 1 ? true : false} onChange={(e) => handleSetAsPrimaryRequest(index, e)}></input>
                                    <p>Set as Primary Email</p>
                               </div>

                               <div className='flex flex-row mr-3 items-center'>
                                    <input className='rounded-full mr-1 w-4 h-4' type='checkbox' checked={alternateEmail === item.email ? true : false} onChange={(e) => handleSetAsalternateRequest(item.email)}></input>
                                    <p>Set as Alternative Email</p>
                               </div>
                            </div>
                    </div>
                    ))}
                </div>
              </div>
            </div>
            {showShowModal ? <Index message={message} status={undefined} closeModal={() => setShowModal(false)} page={page} email=''/> : null }
        </CustomCard>
    </StandardContainer>
    </>
  )
}

export default ContactEmail
