import React from 'react'
import CustomButton from '../buttons/CustomButton'
import edit from '../../assets/edit.png'
import { useNavigate } from 'react-router-dom'
import CustomCard from '../container/CustomCard'

const ContactInformation = () => {

  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user') || '{}')
  const emails = JSON.parse(user.patient.patient_email_addresses)

  return (
    <CustomCard className='w-[60%] mt-5 flex flex-col items-center bg-gray-50 rounded-xl border border-gray-200 shadow-lg'>
        <div className='flex flex-col shadow-lg pl-5 pr-5 w-full'>
          <p className='text-xl font-semibold text-blue-600 mt-5'>Contact Information</p>

            <div className='flex flex-row mt-3 pb-5'>

              <div className='flex flex-col flex-1 cursor-pointer mr-[10%]' onClick={() => navigate('/emails')}>
                <p>Primary Email</p>
                  <div className='flex flex-col w-full h-full pb-1 border-b-2 border-b-gray-300'>
                    <div className='flex flex-row items-center'>
                      <p className=' mt-2 ml-2'>{emails?.[0]?.email}</p>
                      <img src={edit} className='h-5 w-5 ml-auto cursor-pointer' onClick={() => navigate('/emails')}/>
                    </div>
                  </div>
              </div>

              <div className='flex flex-col mx-auto flex-1 cursor-pointer' onClick={() => navigate('/numbers')}>
                <p>Primary Phone Number</p>
                  <div className='flex flex-col w-full h-full pb-1 border-b-2 border-b-gray-300'>
                    <div className='flex flex-row items-center'>
                      <p className='mt-2 ml-2'>{user?.patient?.mobile_number}</p>
                      <img src={edit} className='h-5 w-5 ml-auto cursor-pointer' onClick={() => navigate('/numbers')}/>
                    </div>
                  </div>
              </div>
            </div>
        </div>
    </CustomCard>
  )
}

export default ContactInformation
