import React from 'react'
import background from '../../assets//Backdrop-FINAL.png'
import '../../App.css'

const StandardContainer = (Props : any) => {
    return (
        <div className='flex flex-col items-center pt-[5rem] w-full pb-5 Gilroy overflow-visible'>
            {/*<div className='fixed-bg h-screen flex flex-col items-center justify-center bg-gray-100 top-0 bg-contain bg-no-repeat bg-bottom fixed z-[-1] opacity-30' style={{backgroundImage: `url(${background})`}}/>*/}
            <div className='fixed-bg flex flex-col h-screen items-center justify-center bg-gray-100 top-0 bg-contain bg-no-repeat bg-bottom fixed z-[-1] opacity-30' style={{backgroundImage: `url(${background})`}}/>
            {Props.children}
        </div>
    )
}

export default StandardContainer
