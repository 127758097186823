import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import CustomCard from './container/CustomCard'
import backgroundImage from '../assets/home-banner.png'
import { getFirebaseToken } from '../hooks/getFirebaseToken'
import { User } from '../classes/model/User'
import axios from 'axios';

const user = new User();

const AppleAuth = () => {

  const apple_callback_url = process.env.REACT_APP_APPLE_CALLBACK_URL as string;
  const apple_client_id = process.env.REACT_APP_APPLE_CLIENT_ID as string;

  const navigate = useNavigate()

  const requestToken = async (code: string) => {

    const headers =  {
      'Content-Type': 'application/x-www-form-urlencoded',
      "Access-Control-Allow-Origin": "*"
    };

    const response = await axios.post('https://appleid.apple.com/auth/token', {
      grant_type: 'authorization_code',
      code: code,
      redirect_uri: apple_callback_url,
      client_id: apple_client_id,
      client_secret: 'eyJraWQiOiJCMjJVQjNLNkFCIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJIUDhKWTdYVjg0IiwiaWF0IjoxNjEzNjQwMDc1LCJleHAiOjE2MjkxOTIwNzUsImF1ZCI6Imh0dHBzOi8vYXBwbGVpZC5hcHBsZS5jb20iLCJzdWIiOiJjb20ubWVkZ2F0ZS5NZWRnYXRlLmNsaWVudCJ9.Jf418St5Daw9x1ZYfgwZHWjaw9WR_aa-G4dhm2-Iu8kE08KVeRlr4XsaZjqQMfJIE_Fg8g4XKx0KUeOKMU1bKA',
    }, {
      headers: headers
    });

    console.log(response.data);
  }
  
  const processRequest = async (code: string) => {

    let tokenRequest = await requestToken(code);

    let device_token: any;
    let firebase_token = localStorage.getItem('firebase_token');
    if (firebase_token != null && firebase_token != '') {
      device_token = JSON.parse(firebase_token);
    } else {
      device_token = '';
    }

    const response = await user.socialLogin({device_token: device_token, device_type: "web", token: code, login_type: "apple"})
    console.log("apple response", response);

    if (response.message === 'success') {
      localStorage.removeItem('firebase_token');
      console.log(response.data);
      if (response.data.user.email_verified_at != null && response.data.user.email_verified_at != '') {
        navigate('/dashboard')
      } else {
        navigate('/otp')
      }
    }

  } 

  useEffect( () => {
    const url = new URL(window.location.href);
    console.log(url.searchParams.toString());
    let code = url.searchParams.toString().split('=')[1];

    processRequest(code);
    return () => {
      // window.history.pushState({}, document.title, '/');
    }
  }, [])
  

  return (

    <div className='h-[100vh] min-h-[90vh] flex flex-col bg-center bg-gray-100 w-full bg-cover bg-no-repeat justify-center items-center'
      style={{ backgroundImage: `url(${backgroundImage})` }}>

    <CustomCard className='flex flex-col bg-white w-1/4 rounded-[4rem] p-5 customCard'>
      <div className='flex flex-col justify-center items-center mt-5'>
        <span>Processing..</span>
      </div>
    </CustomCard>
  </div>
)
}

export default AppleAuth