import React, { MouseEventHandler, useEffect, useState } from 'react'

interface Props {
    hotlines : any
}

const SCHotlines = ({ hotlines
    } : Props) => {

    return (
        <div className="flex flex-row lg:flex-row md:flex-col sm:flex-col bg-gray-100 rounded-2xl px-10 py-5 w-full">
            <div className="flex flex-col w-full pr-5 lg:pr-5 md:pr-0 sm:pr-0">
                {hotlines?.landlines?.map((item : any) => (
                  <div className='flex flex-row text-blue-500 w-full'>
                    <p className='flex flex-[4] w-full font-semibold text-lg '>{item.label}:</p>
                    <div className="flex flex-[2] w-content flex-col justify-end">
                    {item.numbers.map((number : any) => (
                        <div className='ml-auto w-[150px] flex text-lg w-full text-left'>
                            <a className='flex border-b border-blue-500 whitespace-nowrap' href={`tel:+63${number.slice(1)}`} >{number}</a>
                        </div>
                    ))}
                    </div>
                  </div>
                ))}
            </div>

            <div className="flex flex-col w-full pl-5 lg:pl-5 md:pl-0 sm:pl-0">
            {hotlines?.mobile?.map((item : any) => (
              <div className='flex flex-row text-blue-500 w-full'>
                <p className='flex flex-[4] w-full font-semibold text-lg '>{item.label}:</p>
                <div className="flex flex-[2] w-content flex-col justify-end">
                {item.numbers.map((number : any) => (
                    <div className='ml-auto w-[150px] flex text-lg w-full text-left'>
                        <a className='flex border-b border-blue-500 whitespace-nowrap' href={`tel:+63${number.slice(1)}`} >{number}</a>
                    </div>
                ))}
                </div>
              </div>
            ))}
            </div>
        </div>
    )
}

export default SCHotlines
