import React, { useState } from 'react'
import blueTriangle from '../assets/blue-triangle.png'
import appointment_blue from '../assets/Appointments- blue.png'
import { Appointment } from '../classes/requests/case/Appointment'
import { Case } from '../classes/model/Case'
import medical_documents from '../assets/Medical Documents.png'
import { Requests } from '../classes/requests/Requests'
import { OtherRequest } from '../classes/model/OtherRequest'
import { Others } from '../classes/requests/Others'
import Spinner from './spinner/Spinner'
import FileViewer from './modals/FileViewer'
import triangleDown from '../assets/blue-triangle-down.png'
import triangleUp from '../assets/blue-triangle-up.png'

import { config } from "../classes/Config";

interface Props {

    posts : any

}

const AppointmentPosts = ({ posts } : Props) => {

    console.log('posts', posts)

    const caseRequest = new Case();
    const others = new Others();

    const [isPastCaseCollapsed, setIsPastCaseCollapsed] = useState(false)
    const [currentCase, setCurrentCase] = useState<any>()
    const [caseDetails, setCaseDetails] = useState<any>()
    const [pdfUrls, setPdfUrls] = useState<any>()
    const [expandedRecordId, setExpandedRecordId] = useState(null);
    const [isShowSpinner, setIsShowSpinner] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState<any>(0)
    const [caseImages, setCaseImages] = useState<any>()
    const [isShowFile, setIsShowFile] = useState(false)
    const [selectedFile, setSelectedFile] = useState<any>()
    const [filename, setFilename] = useState<any>('')
    const [isImage, setIsImage] = useState(false)
    const [openTrigger, setOpenTrigger] = useState(false)

    console.log("pdfUrls: ", pdfUrls);

    const handleOnclickRequest = async (item : any, index : any) => {

        console.log('caseDetails', caseDetails)
        setSelectedIndex(index)
        console.log('item', item)

        setOpenTrigger(!openTrigger)

        setIsShowSpinner(true)
        const response = await caseRequest.getCaseDetails({caseId : item.case.id}, "getCase_details_endpoint");

        console.log('response', response)
        setCaseDetails(response)
        setCurrentCase(item)
        setIsPastCaseCollapsed(!isPastCaseCollapsed)

        const consultations = response.consultations[response.consultations.length -1]

        console.log('consultations', consultations)

        const request = consultations.requests[consultations.requests.length -1]
        console.log('request', request)
        var images = response.case_images.json.case_images

        setCaseImages(images)
        setPdfUrls(request)
        setExpandedRecordId((prevId) => (prevId === index ? null : index));
        setIsShowSpinner(false)

    }

    const getFilename = (url : any) => {

        const parts = url.split('/');
        const filename = parts[parts.length - 1];


        console.log(filename)
        return filename;
    }

    const isRecordVisible = (index : any) => {
        // Hide the second record when the first is expanded, or the third record when the second is expanded
        return !(index === 1 && expandedRecordId === 0) && !(index === 2 && expandedRecordId === 1) && !(index === 0 && expandedRecordId === 2);
      };

    const subArrow = (index:any) => {
        if(isShowSpinner && index === selectedIndex) {
            return <div className='h-6 w-6 ml-auto mr-[5%]'><Spinner /></div>
        } else if(index === selectedIndex && openTrigger) {
            return <img src={triangleUp} className='w-6 h-4 ml-auto mr-[5%]'/>
        } else {
            return <img src={triangleDown} className='w-6 h-4 ml-auto mr-[5%]'/>
        }
    }

  return (
        <>
            {posts !== undefined ? (
                posts.length !== 0 ? (
                    posts?.map((item : any, index : any) => (
                        isRecordVisible(index) ? (
                          <div className='past-appointment-instance bg-gray-100 rounded-xl w-full mt-3 py-[3%] pl-[5%]' key={index}>
                            <p className='text-xs text-gray-400 font-semibold'>{item.case_number}</p>
                            <div className='flex flex-row cursor-pointer items-center' onClick={() => handleOnclickRequest(item, index)}>
                                <>
                                <p className='text-2xl font-semibold text-blue-600'>{item.case.health_concern}</p>
                                {subArrow(index)}
                                </>
                            </div>
                            <p className='text-xs text-gray-400'>{new Date(item.parsed_date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})} | {item.parsed_time} | {item.preferred_method}</p>

                            { isPastCaseCollapsed && item.case_number == currentCase.case_number ?

                                <div className='flex flex-col mt-3'>

                                   {/*<div className='flex flex-row items-center'>
                                        <img src={appointment_blue} className='h-6 w-6'/>
                                        <div className='flex flex-col ml-3'>
                                            <p className='text-gray-400 text-xs'>Schedule Appointment</p>
                                            <p className='text-xs font-bold'>{new Date(item.parsed_date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})} | {item.parsed_time} | {item.preferred_method}</p>
                                        </div>
                                   </div>*/}

                                   <div className='flex flex-row mt-3'>
                                        <img src={medical_documents} className='h-6 w-6'/>
                                        <div className='flex flex-col ml-3'>
                                            <p className='font-semibold'>MEDICAL DOCUMENTS</p>

                                            {pdfUrls !== undefined ?
                                            <div className='grid grid-cols-2 gap-x-20 gap-y-3 text-sm'>

                                                {pdfUrls.file_careplan !== null ? (
                                                  <div>
                                                    <p>Care Plan</p>
                                                    <a
                                                      href={`${process.env.REACT_APP_RDP_API_BASE_URL}/api/v2/force_download/documents/${pdfUrls.file_careplan.substring(pdfUrls.file_careplan.lastIndexOf('/') + 1)}`}
                                                      className="cursor-pointer text-blue-500 text-xs"
                                                    >
                                                      View Careplan
                                                    </a>
                                                  </div>
                                                ) : null}

                                                {pdfUrls.file_laboratory && (
                                                  <div>
                                                    <p>Laboratory Request</p>
                                                    <a
                                                      href={`${process.env.REACT_APP_RDP_API_BASE_URL}/api/v2/force_download/documents/${pdfUrls.file_laboratory.substring(pdfUrls.file_laboratory.lastIndexOf('/') + 1)}`}
                                                      className="cursor-pointer text-blue-500 text-xs"
                                                    >
                                                      View Laboratory Request
                                                    </a>
                                                  </div>
                                                )}

                                                {pdfUrls.file_prescription && (
                                                  <div>
                                                    <p>Prescription</p>
                                                    <a
                                                      href={`${process.env.REACT_APP_RDP_API_BASE_URL}/api/v2/force_download/documents/${pdfUrls.file_prescription.substring(pdfUrls.file_prescription.lastIndexOf('/') + 1)}`}
                                                      className="cursor-pointer text-blue-500 text-xs"
                                                    >
                                                      View Complete Prescription
                                                    </a>
                                                  </div>
                                                )}

                                                {pdfUrls.file_medical_certificate && (
                                                  <div>
                                                    <p>Medical Certificate</p>
                                                    <a
                                                      href={`${process.env.REACT_APP_RDP_API_BASE_URL}/api/v2/force_download/documents/${pdfUrls.file_medical_certificate.substring(pdfUrls.file_medical_certificate.lastIndexOf('/') + 1)}`}
                                                      className="cursor-pointer text-blue-500 text-xs"
                                                    >
                                                      View Medical Certificate
                                                    </a>
                                                  </div>
                                                )}

                                            </div>

                                            : <p className='mt-5 font-semibold'>No Document Attached</p>      }
                                        </div>
                                   </div>
                                   { (caseImages !== undefined && caseImages != '') ?
                                   <div className='flex flex-col ml-3 text-xs font-bold mt-3'>
                                       <p>Case Images</p>
                                       <div className='flex flex-row'>
                                         {caseImages.map((caseImage:any, index:number) => (

                                          console.log("preview", caseImage),
                                             <div>
                                               {caseImage.filelink != null ?
                                                 ( caseImage.filename.split('.').pop() === 'pdf' ? <a href={`${config.CASE_IMAGES_URL}/api/case_images/pdf?file_name=${caseImage.filename}`} style={{ width: '80px', height: '70px', objectFit: 'cover', margin: '5px', cursor: 'pointer', border: '1px solid gray', borderRadius : '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> Pdf {index + 1}</a>:
                                                  <img key={index} src={caseImage.filelink} alt={`caseImage ${index}`} style={{ width: '80px', height: '70px', objectFit: 'cover', margin: '5px', cursor: 'pointer' }} onClick={() => {setSelectedFile(caseImage.filelink); setIsShowFile(true); setFilename(caseImage.filename); setIsImage(true)}}/>) :
                                                <img key={index} src={caseImage} alt={`caseImage ${index}`} style={{ width: '80px', height: '70px', objectFit: 'cover', margin: '5px', cursor: 'pointer' }} onClick={() => {setSelectedFile(caseImage); setIsShowFile(true)}}/> }
                                             </div>
                                         ))}
                                       </div>
                                   </div>
                                   : null }

                                </div> : null
                            }
                            {isShowFile ? <FileViewer file={selectedFile} closeModal={() => setIsShowFile(false)} filename={filename} isImage={isImage}/> : null}
                          </div>
                        ) : null
                        ))
                ) : (<div className='bg-gray-100 h-7'><p className='text-gray-500 font-semibold text-xl ml-[2%] '>No Past Appointments</p></div>)

            ) : <div className='bg-gray-100 h-7'><p className='text-gray-500 font-semibold text-xl ml-[2%] '>No Past Appointments</p></div>}
        </>
  )
}

export default AppointmentPosts
