import { useEffect, useState } from 'react';
import BackButton from '../BackButton'
import CustomCard from '../container/CustomCard'
import StandardContainer from '../container/StandardContainer'

const Tutorial = () => {
    {/**<BackButton route='/dashboard'/> */}

     const [dimensions, setDimensions] = useState({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        
          // Function to update dimensions
      const updateDimensions = () => {
        setDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
    
      useEffect(() => {
    
        window.addEventListener('resize', updateDimensions);
        return () => {
          window.removeEventListener('resize', updateDimensions);
        };
      }, []);

      console.log("dimension", dimensions)
    
  return (
    <StandardContainer>

      <BackButton route='/account'/>

        <div className={`xl:w-[90%] lg:w-[90%] md:w-[90%] sm:w-[90%] w-[90%] ${dimensions.width <= 1600 ? 'w-[90%] h-[90%]' : null}`}>
              <video className={`xl:w-[100%] lg:w-[100%] md:w-[100%] ${dimensions.width <= 1600 ? 'w-[90%] h-[90%]' : null}`} controls disablePictureInPicture controlsList="nodownload noplaybackrate">
                  <source src='/Tutorial.mp4' type="video/mp4" />
              </video>
        </div>

    </StandardContainer>
  )
}

export default Tutorial