import React, { useEffect, useRef, useState } from 'react'
import medgate_logo_small from '../../assets/medgate_logo_small.png';
import FacebookLoginButton from '../buttons/FacebookLoginButton';
import { Others } from '../../classes/requests/Others';
import { IAssets } from '../../classes/interface/IAssets';

interface Props {
  onCancel: () => void,
  type: string,
  onFacebookSuccess: (res: any) => void,
  onFacebookError: (message: any) => void,
  onGoogleContinue: (skipToken: boolean) => void,
  onEmailContinue: (skipToken: boolean) => void,
  onLoginContinue: (skipToken: boolean) => void,
}

const NotificationPermission = ({
      onCancel,
      type,
      onFacebookSuccess,
      onFacebookError,
      onGoogleContinue,
      onEmailContinue,
      onLoginContinue }: Props) => {

  const [step, setStep] = useState<number>(1);
  const [instructions, setInstructions] = useState<[]>([]);

  const modalRef = useRef<HTMLDivElement>(null);


  const getNotificationRequest = async () => {
    let others = new Others;
    let res = await others.getAssets( {category: 'notification_request', full_data: true}, 'getAssets_endpoint');
    setInstructions(res);
  }

  const renderInstructions = (category: string) => {

    let data = [];
    for (let i in instructions) {
      let row = instructions[i] as IAssets;
      if (category == row.title) {
        data.push(row);
      }
    }

    return (
      <div>
        {
          data.map((item: IAssets, index: number) => (
            <div className='flex flex-row items-center mb-5' key={index}>
              <img className="w-72 h-50" src={item.filename} alt="" />
              <p className='text-xs ml-5'>{item.description}</p>
            </div>
          ))
        }
      </div>
    )
  }

  const renderTitle = () => {
    let title = '';
    let description = '';
    let subDescription = '';
    for (let i in instructions) {
      let row = instructions[i] as IAssets;
      if (row.title != 'chrome' && row.title != 'firefox' && row.title != 'edge') {
        if (row.title == 'notification_request_sub') {
          subDescription = row.description;
        } else {
          title = row.title;
          description = row.description;
        }
      }
    }

    return (
      <div className='text-left ml-5'>
        <h3 className='font-bold text-lg mb-2'>{title}</h3>
        <p className='text-xs'>{description}</p>
        <p className='text-xs mt-2'>{subDescription}</p>
      </div>
    )
  }

  useEffect(() => {
    if (instructions.length <= 0) {
      getNotificationRequest()
    }
  }, [instructions])

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onCancel();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  return (
    <div className='flex items-center justify-center fixed inset-0 z-10 overflow-y-auto bg-gray-600 bg-opacity-70 h-full w-full'>
      <div className='flex items-center w-1/2'>
        <div className='relative p-5 mx-auto bg-white rounded-md shadow-lg shadow-gray-700'>
        <div ref={modalRef}>

          <div className='flex mb-5'>
            <img className="mx-auto w-24 h-20" src={medgate_logo_small} alt="" />
            {renderTitle()}
          </div>

          <div className='flex flex-row space-x-4 mb-5'>
            <button onClick={() => setStep(1)} className={`flex-1 rounded rounded-md border py-2 px-4 hover:bg-blue-500 hover:text-white ${step == 1 ? 'bg-blue-500 text-white' : 'bg-white'}` }>Chrome</button>
            <button onClick={() => setStep(2)} className={`flex-1 rounded rounded-md border py-2 px-4 hover:bg-blue-500 hover:text-white ${step == 2 ? 'bg-blue-500 text-white' : 'bg-white'}` }>Firefox</button>
            <button onClick={() => setStep(3)} className={`flex-1 rounded rounded-md border py-2 px-4 hover:bg-blue-500 hover:text-white ${step == 3 ? 'bg-blue-500 text-white' : 'bg-white'}` }>Edge</button>
          </div>
          <div className='flex overflow-y-auto h-80'>
            {
              step == 1 ? renderInstructions('chrome'): null
            }

            {
              step == 2 ? renderInstructions('firefox'): null
            }

            {
              step == 3 ? renderInstructions('edge'): null
            }
          </div>
          <div className='flex flex-row space-x-4 mt-5'>
            <button onClick={onCancel} className='flex-1 rounded rounded-md py-2 px-4 hover:bg-gray-600 hover:text-white items-center border border-gray-600 cursor-pointer'>
              Cancel
            </button>

            <button className='rounded-full bg-custom-blue text-white flex-1 rounded rounded-md py-2 px-4 hover:bg-gray-600 hover:text-white items-center border border-gray-600 cursor-pointer'
              onClick={() => { onLoginContinue(true) } }>
                <p className='text-white'>Refresh</p>
            </button>

            {/* {
              type == 'facebook' ?
              <FacebookLoginButton
                cssClass='relative flex-1 flex-row items-center justify-center bg-blue-600 border border-blue-600 h-12 w-[20rem] rounded-md cursor-pointer text-white'
                onLogin={onFacebookSuccess}
                onError={onFacebookError}
                children={<div><svg className="absolute left-3 h-6 w-6 mr-2" fill="#ffffff" height="200px" width="200px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 310 310" stroke="#ffffff"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="XMLID_834_"> <path id="XMLID_835_" d="M81.703,165.106h33.981V305c0,2.762,2.238,5,5,5h57.616c2.762,0,5-2.238,5-5V165.765h39.064 c2.54,0,4.677-1.906,4.967-4.429l5.933-51.502c0.163-1.417-0.286-2.836-1.234-3.899c-0.949-1.064-2.307-1.673-3.732-1.673h-44.996 V71.978c0-9.732,5.24-14.667,15.576-14.667c1.473,0,29.42,0,29.42,0c2.762,0,5-2.239,5-5V5.037c0-2.762-2.238-5-5-5h-40.545 C187.467,0.023,186.832,0,185.896,0c-7.035,0-31.488,1.381-50.804,19.151c-21.402,19.692-18.427,43.27-17.716,47.358v37.752H81.703 c-2.762,0-5,2.238-5,5v50.844C76.703,162.867,78.941,165.106,81.703,165.106z"></path> </g> </g></svg> <span>Facebook</span></div>}
                /> : null
            }

            {
              type == 'google' ?
              <button className='relative flex-1 flex-row items-center justify-center border border-gray-600 h-12 w-[20rem] rounded-md cursor-pointer' onClick={() => { onGoogleContinue(true) }}>
                <svg className='absolute left-3 h-6 w-6 mr-2' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="800px" height="800px" viewBox="-0.5 0 48 48" version="1.1"> <title>Google-color</title> <desc>Created with Sketch.</desc> <defs> </defs> <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"> <g id="Color-" transform="translate(-401.000000, -860.000000)"> <g id="Google" transform="translate(401.000000, 860.000000)"> <path d="M9.82727273,24 C9.82727273,22.4757333 10.0804318,21.0144 10.5322727,19.6437333 L2.62345455,13.6042667 C1.08206818,16.7338667 0.213636364,20.2602667 0.213636364,24 C0.213636364,27.7365333 1.081,31.2608 2.62025,34.3882667 L10.5247955,28.3370667 C10.0772273,26.9728 9.82727273,25.5168 9.82727273,24" id="Fill-1" fill="#FBBC05"> </path> <path d="M23.7136364,10.1333333 C27.025,10.1333333 30.0159091,11.3066667 32.3659091,13.2266667 L39.2022727,6.4 C35.0363636,2.77333333 29.6954545,0.533333333 23.7136364,0.533333333 C14.4268636,0.533333333 6.44540909,5.84426667 2.62345455,13.6042667 L10.5322727,19.6437333 C12.3545909,14.112 17.5491591,10.1333333 23.7136364,10.1333333" id="Fill-2" fill="#EB4335"> </path> <path d="M23.7136364,37.8666667 C17.5491591,37.8666667 12.3545909,33.888 10.5322727,28.3562667 L2.62345455,34.3946667 C6.44540909,42.1557333 14.4268636,47.4666667 23.7136364,47.4666667 C29.4455,47.4666667 34.9177955,45.4314667 39.0249545,41.6181333 L31.5177727,35.8144 C29.3995682,37.1488 26.7323182,37.8666667 23.7136364,37.8666667" id="Fill-3" fill="#34A853"> </path> <path d="M46.1454545,24 C46.1454545,22.6133333 45.9318182,21.12 45.6113636,19.7333333 L23.7136364,19.7333333 L23.7136364,28.8 L36.3181818,28.8 C35.6879545,31.8912 33.9724545,34.2677333 31.5177727,35.8144 L39.0249545,41.6181333 C43.3393409,37.6138667 46.1454545,31.6490667 46.1454545,24" id="Fill-4" fill="#4285F4"> </path> </g> </g> </g> </svg>
                Gmail
              </button> : null
            }

            {
              type == 'apple' ?
              <button className='relative flex-1 flex-row items-center justify-center border border-gray-600 h-12 w-[20rem] rounded-md cursor-pointer'>
                <svg className='absolute left-3 h-6 w-6 mr-2' viewBox="-56.24 0 608.728 608.728" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M273.81 52.973C313.806.257 369.41 0 369.41 0s8.271 49.562-31.463 97.306c-42.426 50.98-90.649 42.638-90.649 42.638s-9.055-40.094 26.512-86.971zM252.385 174.662c20.576 0 58.764-28.284 108.471-28.284 85.562 0 119.222 60.883 119.222 60.883s-65.833 33.659-65.833 115.331c0 92.133 82.01 123.885 82.01 123.885s-57.328 161.357-134.762 161.357c-35.565 0-63.215-23.967-100.688-23.967-38.188 0-76.084 24.861-100.766 24.861C89.33 608.73 0 455.666 0 332.628c0-121.052 75.612-184.554 146.533-184.554 46.105 0 81.883 26.588 105.852 26.588z" fill="#999"></path></g></svg>
                Apple
              </button> : null
            }

            {
              type == 'email' ?
              <button className='relative flex flex-row items-center justify-center border border-blue-500 h-12 w-[20rem] rounded-md cursor-pointer'
              onClick={() => { onEmailContinue(true) } }>
                <svg className='absolute left-3 h-6 w-6 mr-2' fill="#3b82f6" height="200px" width="200px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512.001 512.001" xmlSpace="preserve" stroke="#3b82f6"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M511.646,126.634c-0.021-14.449-11.782-26.189-26.227-26.189c-0.012,0-0.024,0-0.034,0L26.19,101.076 c-7.005,0.009-13.588,2.746-18.535,7.706C2.709,113.743-0.009,120.334,0,127.337l0.355,258.029 c0.009,7.005,2.746,13.588,7.706,18.535c4.951,4.938,11.526,7.655,18.519,7.655c0.012,0,0.025,0,0.035,0l459.194-0.631 c14.458-0.02,26.207-11.799,26.19-26.261L511.646,126.634z M456.611,126.71L256.02,266.154L55.04,127.262L456.611,126.71z M340.846,292.471l118.971,92.265l-407.972,0.56l119.696-92.825c5.722-4.439,6.764-12.675,2.326-18.399 c-4.439-5.722-12.675-6.764-18.399-2.326L26.561,371.715l-0.32-232.475l222.344,153.657c2.244,1.551,4.849,2.325,7.455,2.325 c2.617,0,5.236-0.783,7.485-2.346l221.912-154.264l0.336,233.066l-128.856-99.931c-5.719-4.437-13.959-3.396-18.397,2.326 C334.081,279.795,335.122,288.033,340.846,292.471z"></path> </g> </g> </g></svg>
                <p className='text-blue-500'>Register</p>
            </button> : null
            }

            {
              type == 'login' ?
              <button className='rounded-full bg-custom-blue text-white flex-1 rounded rounded-md py-2 px-4 hover:bg-gray-600 hover:text-white items-center border border-gray-600 cursor-pointer'
              onClick={() => { onLoginContinue(true) } }>
                <p className='text-white'>Login</p>
            </button> : null
            } */}

          </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotificationPermission
