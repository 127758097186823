import { IProviders } from "../../interface/IProviders";
import { requests } from "../Api";
import { endpoints } from "../Endpoint";

const ELIGIBILITY_PROVIDERS_ENDPOINT = process.env.REACT_APP_RDP_API_ELIGIBILITY_PROVIDERS_ENDPOINT as string
const ELIGIBILITY_CHECK_ENDPOINT = process.env.REACT_APP_RDP_API_ELIGIBILITY_CHECK_ENDPOINT as string

export class Providers extends requests {

    //token = JSON.parse(localStorage.getItem('api_token') ?? '')

    async getProviders(request : any) {

        const endpoint = endpoints(request, "GET", true, null);

        const response = await this.fetchRequest(endpoint, request)
        return response;
    }

    async validateProvider({ provider, member_id, user }: IProviders, request : any) {

        const endpoint = endpoints(request, "POST", true, null);

        const data: IProviders = {
            provider: provider,
            member_id: member_id,
            user: user
        }

        const response = await this.postRequest(endpoint, data, request)

        return response;
    }

    async mergePatientDetails (guid: string, request: any) {
        const endpoint = endpoints(request, "POST", true, null);
        const response = await this.postRequest(endpoint, {patient_guid: guid}, request)
        return response;
    }
}
