import React from 'react'
import CustomCard from '../container/CustomCard'

const Health = () => {

  const user = JSON.parse(localStorage.getItem('user') || '{}')

  return (

    <CustomCard className='w-[60%] mt-5 flex flex-col bg-gray-50 rounded-xl border border-gray-200 shadow-lg'>
        <div className='flex flex-col shadow-lg w-full pl-5 pr-5 pb-5'>
      <p className='text-xl font-semibold text-blue-600 mt-5'>Health Profile</p>

      <p className='text-blue-600 font-semibold'>Basic Information</p>

      <div className='flex flex-row mt-3'>

        <div className='flex flex-col flex-1 mr-[10%]'>
          <p>Height</p>
            <div className='flex flex-col w-full h-full pb-1 border-b-2 border-b-gray-300'>
              <div className='flex flex-row items-center'>
                <p className=' mt-2 ml-2'>{user?.patient?.height}</p>
              </div>
            </div>
        </div>

        <div className='flex flex-col mx-auto flex-1'>
          <p>Weight</p>
            <div className='flex flex-col w-full h-full pb-1 border-b-2 border-b-gray-300'>
              <div className='flex flex-row items-center'>
                <p className='mt-2 ml-2'>{user?.patient?.weight}</p>
              </div>
            </div>
        </div>
      </div>

      </div>
  </CustomCard>
  )
}

export default Health
