import React, { useEffect, useState } from 'react'
import Spinner from '../spinner/Spinner'
import { User } from '../../classes/model/User'
import { useNavigate } from 'react-router-dom'

interface Props {
    message : any
    email : any
}

const RegistrationModal = ({message, email} : Props) => {

    const [isShowSendingOTPMessage, setIsShowSendingOTPMessage] = useState(false)
    const [isShowSpinner, setIsShowSpinner] = useState(false)

     const user = new User()
     const navigate = useNavigate()

     useEffect(() => {


      
     })


    const handleOnclickRequest = async () => {
        setIsShowSendingOTPMessage(true)
        setIsShowSpinner(true)

        const response: any = await user.forgotPassword({
            email: email,
            source: 'Web',
          }, "forgotPassword_endpoint")
    
          console.log('forgot password', response)

          if (response.message === 'success') {
            localStorage.setItem('email', JSON.stringify(email))
            console.log('success')
            navigate('/code-verification')

          } else {
            message(response.message)
          }
          
          setIsShowSendingOTPMessage(false)
          setIsShowSpinner(false)
    }

   

  return (
    <div className='flex items-center pl-[30%] fixed inset-0 z-10 overflow-y-auto bg-gray-600 bg-opacity-70 h-full w-full'>
      <div className='flex items-center justify-center px-4 py-8 w-2/3 h-1/3'>
        <div className='w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg shadow-gray-700'>
          <div className='flex items-center justify-center mt-3'>
            <div className='flex flex-col justify-center items-center'>
              {/**modal content */}

              
              { isShowSendingOTPMessage ?
                    <div>
                        <p className='text-lg text-blue-500 text-center'>Please hold on while we send the OTP to your registered email address.</p>
                    </div> : <div className='text-blue-600 text-xl text-center'>{message}</div>
                    
               }

            {isShowSpinner ? <Spinner /> : 
              <button className='h-10 w-64 bg-custom-blue text-white rounded-xl hover:bg-custom-blue-dark mt-10' onClick={handleOnclickRequest}>Retrieve Account</button>
            }
            </div>
          </div>
        </div>
      </div>
  </div>
  )
}

export default RegistrationModal