type PasswordStrengthBarProps = {
  className: any
  countTrueValues: number
}

const PasswordStrengthBar = ({
  className,
  countTrueValues,
}: PasswordStrengthBarProps) => {
  return (
    <div className={`flex flex-row gap-x-1 w-full h-1 ${className}`}>
      <div
        className={`basis-1/3 rounded-lg ${
          countTrueValues > 1 ? 'bg-custom-devider' : 'bg-gray-500'
        } `}></div>
      <div
        className={`basis-1/3 rounded-lg ${
          countTrueValues > 3 ? 'bg-custom-devider' : 'bg-gray-500'
        } `}></div>
      <div
        className={`basis-1/3 rounded-lg ${
          countTrueValues > 4 ? 'bg-custom-devider' : 'bg-gray-500'
        } `}></div>
      {/* <div
        className={`basis-1/5 rounded-lg ${
          countTrueValues > 3 ? 'bg-green-500' : 'bg-gray-500'
        } `}></div>
      <div
        className={`basis-1/5 rounded-lg ${
          countTrueValues > 4 ? 'bg-green-500' : 'bg-gray-500'
        } `}></div> */}
    </div>
  )
}

export default PasswordStrengthBar
