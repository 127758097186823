import React, { ChangeEvent, useState } from 'react'
import backArrow from '../../assets/back-arrow.png'
import { useNavigate } from 'react-router-dom'
import { User } from '../../classes/model/User';
import StandardContainer from '../container/StandardContainer'
import CustomCard from '../container/CustomCard'
import { belongsTo } from '../../classes/data/belongsTo';
import { Categories } from '../../classes/data/Categories';
import Spinner from '../spinner/Spinner';
import Index from '../modals/Index';

const ContactNumber = () => {

    const navigate = useNavigate();
    const userRequest = new User()
    const user = JSON.parse(localStorage.getItem('user') || '{}')
    const numbers = JSON.parse(user.patient.patient_contact_numbers)
    console.log(user)
    console.log('user', user)
    console.log('numbers', numbers)

    const [phoneNumberOptions, setPhoneNumberOptions] = useState<any[]>(numbers)
    const [contactNumber, setContactNumber] = useState<any>(user.patient.mobile_number)
    const [showSpinner, setShowSpinner] = useState(false)
    const [showShowModal, setShowModal] = useState(false)
    const [message, setMessage] = useState<any>('')
    const [page, setPage] = useState<any>('')

    const handleUpdatePhoneNumber = async () => {

      setShowSpinner(true)

        const response = await userRequest.updateContactNumber({mobile_number : contactNumber,  phonenumber_options : phoneNumberOptions }, "updateContactNumber_endpoint");
        console.log('handleUpdatePhoneNumber', response)
        console.log(response.data.patient.phonenumber_options[0])
        console.log('response.message.data', response.message.data)

        if(response.message === 'success'){
          localStorage.setItem("user", JSON.stringify(response.data))
          setMessage('Phone numbers successfully saved')
          setPage('/profile')
        }else{
          setPage('/numbers')
          if(response.message.data === undefined){
            setMessage(response?.message?.phonenumber_options[0])
          }else{
            setMessage(response.message.data)
          }
        }

        console.log('update phone number response', response)
        setShowModal(true)
        setShowSpinner(false)

      }

      const  handleInputOnchangeRequest = (index: number, newValue: string) => {
        const updatedOptions = [...phoneNumberOptions];
        updatedOptions[index].contact_number = newValue;
        setPhoneNumberOptions(updatedOptions);
      }

      const handleSetAsPrimaryRequest = (index: number,  event : ChangeEvent<HTMLInputElement>) => {

        setContactNumber(phoneNumberOptions[index].contact_number)
        const isChecked = event.target.checked

        setPhoneNumberOptions(
            phoneNumberOptions.map((item, idx) => {
              if (idx === index && isChecked) {
                return { ...item, is_primary: isChecked ? '1'  : '0'}
              } else {
                // Unchecks other checkboxes
                return { ...item, is_primary: '0' }
              }
            })
          )
      }

      const handleUpdateDropdownRequest = (event : any, index : any, type : any) => {

        //every time the user change the belong to, need to find in the array and update the state
        console.log('before', phoneNumberOptions)
        if(type === 'category'){
          const updatedOptions = [...phoneNumberOptions];
          updatedOptions[index].contact_category = event.target.value;
          setPhoneNumberOptions(updatedOptions);

        }else{
          const updatedOptions = [...phoneNumberOptions];
          updatedOptions[index].belongs_to = event.target.value;
          setPhoneNumberOptions(updatedOptions);
        }

        console.log('after', phoneNumberOptions )

      }

  return (
    <>
    <StandardContainer className='flex flex-col items-center bg-gray-100 rounded-xl border border-gray-200 shadow-lg w-full'>

        <div className='flex flex-row items-center rounded-xl dark-blue-bg text-white w-[60%]'>
            <img src={backArrow} className='ml-5 cursor-pointer' onClick={() => navigate('/profile')}/>
            <p className='text-xl font-semibold ml-5'>Contact Information</p>
            { showSpinner ? <div className='ml-auto mr-5 pb-2'><Spinner /></div> :
            <p className='text-white text-lg font-semibold ml-auto mr-5 cursor-pointer' onClick={handleUpdatePhoneNumber}>Done</p> }
        </div>

        <CustomCard className='flex flex-col shadow-lg w-[60%] border border-gray-200 rounded-xl mt-5 mb-10 bg-gray-50'>

            <div className='flex flex-col w-full mx-[10%]'>

                <p className='text-xl font-semibold text-blue-600 mt-5'>Current Phone Number Settings</p>
                <div className='flex flex-row mt-3 w-[80%]'>
                    <div className='flex flex-row space-x-[5%] w-full'>
                        <div className='flex flex-col w-full pb-1 border-b-2 border-b-gray-300'>
                            <p>Primary Contact Number</p>
                            <p className='mt-2 ml-2'>{contactNumber}</p>
                        </div>
                    </div>
                </div>


              <div className='flex flex-col w-[80%]'>
                <p className='text-xl font-semibold text-blue-600 mt-5'>Phone Number Options</p>

                <div className='mb-5'>

                    {
                        phoneNumberOptions != null ? (
                            phoneNumberOptions.map((item : any, index : any) => (

                                <div className='flex flex-col w-full'>
                                <div className={`flex flex-col w-full h-full mt-5 ${item.is_primary === '1' ? 'pointer-events-none opacity-50' : null}`}> {/**className='flex flex-col w-full h-full mt-5' */}
                                    <div className='pb-1 border-b-2 border-b-gray-300'>
                                        <p>Phone Number</p>
                                        {/*<p className='ml-2 text-gray-500'>{item.contact_number}</p> */}
                                        <input type='text' className='!outline-none w-full bg-gray-50 placeholder:text-black' value={item.contact_number}  onChange={(e) => handleInputOnchangeRequest(index, e.target.value)}/>
                                    </div>

                                    <div className='mt-2'>
                                        <div className='flex flex-row space-x-[5%] w-full'>

                                            <div className='pb-1 border-b-2 border-b-gray-300 w-full'>
                                                <p>Contact Category</p>
                                                <select className='w-full bg-gray-50 outline-none border-none' value={item.contact_category} onChange={(e) => handleUpdateDropdownRequest(e, index, 'category')}>
                                                  {Categories.map(( category : any ) => (
                                                    <option key={"category"+category.category}>
                                                      {category.category}
                                                    </option>
                                                  ))}
                                                </select>
                                            </div>

                                            <div className='pb-1 border-b-2 border-b-gray-300 w-full'>
                                                <p>Belong To</p>
                                                <select className='w-full bg-gray-50 outline-none border-none' value={item.belongs_to} onChange={(e) => handleUpdateDropdownRequest(e, index, 'belong')}>
                                                  {belongsTo.map(( belongTo : any ) => (
                                                    <option key={"belongto"+belongTo.belong}>
                                                      {belongTo.belong}
                                                    </option>
                                                  ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex flex-row items-center space-x-2 mt-2'>
                                       <div className='flex flex-row mr-3 items-center'>
                                       <input className='rounded-full mr-1 w-4 h-4' type='checkbox' checked={item.is_primary == 1 ? true : false} onChange={(e) => handleSetAsPrimaryRequest(index, e)}></input>
                                            <p>Set as Primary Contact Number</p>
                                       </div>
                                    </div>
                                </div>
                            </div>
                            ))
                        ) : null
                    }

                </div>
              </div>
            </div>
            {showShowModal ? <Index message={message} status={undefined} closeModal={() => setShowModal(false)} page={page} email=''/> : null }

        </CustomCard>
    </StandardContainer>
    </>
  )
}

export default ContactNumber
