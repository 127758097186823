import React from "react";
import {IImage} from "../classes/interface/IImage"

const ImageVariables = [
    {
        filename: "",
        base64string: "",
    }
];

export default ImageVariables;
