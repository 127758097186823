import React, { useEffect, useState } from 'react'
import arrow from '../../assets/white_arrow.png'
import back_arrow from '../../assets/Back.png'
import { useNavigate } from 'react-router-dom'
import  Tutorial  from '../../assets/Tutorial.png'
import  TermsAndCondition  from '../../assets/Terms and Conditions.png'
import  Settings  from '../../assets/Settings.png'
import  CodeOfConduct  from '../../assets/Code of Conduct.png'
import  MedgateLogo  from '../../assets/Medgate-green.png'
import Privacy from '../../assets/Privacy Policy.png'
import StandardContainer from '../container/StandardContainer'
import CustomCard from '../container/CustomCard'
import BackButton from '../BackButton'

const Account = () => {

  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user') || '{}')
  const [provider, setProvider] = useState<any>('')

  useEffect(() => {

    const formatText = () => {

      let formattedText = user?.insurance_provider
        .replace(/_/g, ' ')
        .toLowerCase()
        .replace(/\b\w/g, (match : any) => match.toUpperCase());
  
      formattedText = formattedText.replace(/\band\b/g, 'and');
      setProvider(formattedText);
    };

    formatText();

  }, [])

 

  return (
    <StandardContainer>

      <BackButton route='/dashboard'/>

      <div className='flex flex-row items-center rounded-xl bg-[#1665CE] text-white xl:ml-3 xl:w-[76%] lg:w-[75%] md:w-[75%] p-2 p-y-2'>
          <p className='flex items-center justify-center font-semibold  w-16 h-16 md:w-12 md:h-12 lg:w-16 md:w-16 rounded-full text-3xl text-blue-600 bg-white ml-3 mr-5'>{user?.patient?.first_name.charAt(0)}{user?.patient?.last_name.charAt(0)}</p>

          <div className='mr-auto xl:text-xl lg:text-xl'>
            <p className='font-semibold'>{user?.patient?.full_name}</p>
            <p>Active</p>
            <p>{provider} |  {user?.insurance_number}</p>
          </div>

          <img src={arrow} className='h-5 w-5 mr-5 cursor-pointer' onClick={() => navigate('/profile')}/>
      </div>

      <div className='xl:ml-[5%] lg:ml-[5%] md:ml-[7%] grid grid-cols-2 gap-x-4 gap-y-5 mt-7 xl:w-[80%] lg:w-[80%] md:w-[82%] h-[50%] auto-cols-max'>

        <CustomCard className='account-grid' onClick={() => navigate('/tutorials')}>
          <img src={Tutorial} className='w-16 xl:h-16 lg:h-16 md:h-12 lg:w-16 md:w-16 ml-6'/>
          <div className='flex flex-col mx-8 items-start'>
            <p className='account-grid-title'>View Tutorial</p>
            <p className='account-grid-text'>Discover step-by-step guides and tips on making the most of our features</p>
          </div>
        </CustomCard>

        <CustomCard className='account-grid' onClick={(e) => { e.preventDefault()
          window.open('https://medgate.ph/terms-and-conditions/')}}>
          <img src={TermsAndCondition} className='w-16 xl:h-16 lg:h-16 md:h-12 lg:w-16 md:w-16 ml-6'/>
          <div className='flex flex-col mx-8 items-start'>
            <p className='account-grid-title'>Terms and Conditions</p>
            <p className='account-grid-text'>Read our terms and conditions and know what to expect.</p>
          </div>
        </CustomCard>

        <CustomCard className='account-grid' onClick={() => navigate('/password')}>
          <img src={Settings} className='w-16 xl:h-16 lg:h-16 md:h-12 lg:w-16 md:w-16 ml-6'/>
          <div className='flex flex-col mx-8 items-start'>
            <p className='account-grid-title'>Settings</p>
            <p className='account-grid-text'>Manage your preferences and secure your account.</p>
          </div>
        </CustomCard>

        <CustomCard className='account-grid'  onClick={(e) => {
              e.preventDefault()
              window.open(
                'https://medgate.ph/medgate-code-of-conduct/')
            }}>
          <img src={CodeOfConduct} className='w-16 xl:h-16 lg:h-16 md:h-12 lg:w-16 md:w-16 ml-6'/>
          <div className='flex flex-col mx-8 items-start'>
            <p className='account-grid-title'>Patient Code of Conduct</p>
            <p className='account-grid-text'>View our guidelines and understand how to engage responsibly.</p>
          </div>
        </CustomCard>

        <CustomCard className='account-grid' onClick={(e) => {
              e.preventDefault()
              window.open('https://medgate.ph/about/')
            }}>
          <img src={MedgateLogo} className='xl:h-10 lg:h-10 md:h-8 xl:w-20 lg:w-20 md:w-12 ml-6'/>
          <div className='flex flex-col items-start ml-3'>
            <p className='account-grid-title'>About Medgate</p>
            <span className='account-grid-text'><p>Get to know our story and discover</p> <p>more about Medgate.</p></span>

          </div>
        </CustomCard>

        <CustomCard className='account-grid' onClick={(e) => {
              e.preventDefault()
              window.open('https://medgate.ph/data-privacy-policy/')
            }}>
          <img src={Privacy} className=' xl:h-16 lg:h-16 md:h-14 lg:w-16 md:w-14 ml-6'/>
          <div className='flex flex-col mx-6 items-start'>
            <p className='account-grid-title'>Privacy Policy</p>
            <p className='account-grid-text'>Learn how we protect your information.</p>
          </div>
        </CustomCard>
      </div>
    </StandardContainer>
  )
}

export default Account
