import { IChat, IChatSlug } from "../../interface/IChat";
import { requests } from "../Api";
import { endpoints } from "../Endpoint";

const CHAT_ROOM_ENDPOINT = process.env.REACT_APP_RDP_API_CHAT_ROOM_ENDPOINT as string
const CHAT_ROOM_SLUG_ENDPOINT = process.env.REACT_APP_RDP_API_CHAT_ROOM_SLUG_ENDPOINT as string
const CHAT_ENDPOINT = process.env.REACT_APP_RDP_API_CHAT_ENDPOINT as string

export class Chat extends requests {

    //token = JSON.parse(localStorage.getItem('api_token') ?? '')

    async getChatRoom(request : any) {

        const endpoint = endpoints(request, "GET", true, null);

        const response = await this.fetchRequest(endpoint, request);

        if(response.data.length !== 0){

            localStorage.setItem('chat_slug', JSON.stringify(response.data[0].slug))
        }

        return response.data[0];
    }

    async createChatroom(request : any) {

        const endpoint = endpoints(request, "POST", true, null);

        const response = await this.postRequest(endpoint, [], request);

        return response;
    }

    async getChatHistory({ slug }: IChatSlug, request : any) {

        const endpoint = endpoints(request, "GET", true, `${slug}&per_page=20`);

        //const response = await this.fetchRequest(`${CHAT_ROOM_SLUG_ENDPOINT}${slug}&per_page=20`, this.token);
        const response = await this.fetchRequest(endpoint, request);

        return response;
    }

    async sendMessage({ message, chat_room_slug }: IChat, request : any) {

        //const endpoint = process.env.REACT_APP_RDP_API_CHAT_ENDPOINT
        const endpoint = endpoints(request, "POST", true, null);

        const data: IChat = {
            message: message,
            chat_room_slug: chat_room_slug
        }

        const response = await this.postRequest(endpoint, data, request);

        return response;
    }
}
