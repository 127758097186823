import React from 'react'
import medgate_logo from '../assets/medgate_logo.png'
import medgate_logo_small from '../assets/medgate_logo_small.png'
import notification_icon from'../assets/Notification.png'
import logout_icon from '../assets/Logout- blue.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { User } from '../classes/model/User'
import '../App.css'
import { getFirebaseToken } from '../hooks/getFirebaseToken'

import hamburgerIcon from '../assets/Hamburger.png'
import collapseLeftBlue from '../assets/collapse_left_blue.png'
import collapseRightBlue from '../assets/collapse_right_blue.png'
import { deleteAllFiles } from './appointment/db'

interface Props {
    isSidebarCollapsed: boolean,
    setIsSidebarCollapsed: (value: boolean) => void,
}

const Header = ({
    setIsSidebarCollapsed,
    isSidebarCollapsed
    } : Props) => {
  const navigate = useNavigate();

  const location = useLocation()

  if (location.pathname === '/') return null
  if (location.pathname === '/login') return null
  if (location.pathname === '/forgot-password') return null
  if (location.pathname === '/registration') return null
  if (location.pathname === '/otp') return null
  if (location.pathname === '/verification') return null
  if (location.pathname === '/code-verification') return null
  if (location.pathname === '/update-password') return null
  if (location.pathname === '/apple/auth') return null


  const user = new User();

  const handleLogoutRequest = async () => {

    let firebaseToken = await getFirebaseToken()
    if (firebaseToken) {
      await user.logout(firebaseToken);
    }

    localStorage.clear();
    await deleteAllFiles();
    navigate('/')

  }

  return (
    <div className='flex flex-row bg-gray-100 border-b border-gray-300 shadow-xl py-3 h-[4rem]'>
        {isSidebarCollapsed == false ? <img src={medgate_logo} className='h-10 w-22 ml-5'/>
        : <img src={medgate_logo_small} className='h-10 w-22 ml-3 hidden'/> }
        {isSidebarCollapsed == false ?
        <div className="ml-[220px] absolute" onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}>
            <img src={collapseLeftBlue} className='h-8 w-8'/>
        </div>
        : <div className="ml-[15px] absolute" onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}>
            <img src={collapseRightBlue} className='h-8 w-8'/>
        </div>}

        {/**<img src={notification_icon} className='h-8 w-8 ml-auto mr-5 cursor-pointer'/>*/}
        <div className='flex flex-row space-x-2 cursor-pointer ml-auto mr-[3%]' onClick={handleLogoutRequest}>
          <img src={logout_icon} className='h-7 w-7'/>
          <p className='text-sky-500 text-lg font-semibold Gilroy'>Log Out</p>
        </div>
    </div>
  )
}

export default Header
