import { AnyNaptrRecord } from 'dns';
import React from 'react'

interface Props {

    postsPerPage : any
    length  : any
    currentPage : any
    setCurrentPage : any

}

const Pagination = ({ postsPerPage, length,  currentPage, setCurrentPage} : Props) => {

    const paginationNumbers = [];

    for (let i = 1; i <= Math.ceil(length / postsPerPage); i++) {
      paginationNumbers.push(i);
    }

    const handleOnlickRequest = (page : any) => {

        setCurrentPage(page)
    }

  return (
    <div className='flex flex-row space-x-5 justify-center pt-5 mb-5'>
        {paginationNumbers.map((data) => (
                <button key={data} onClick={() => handleOnlickRequest(data)} className={currentPage === data ? 'bg-blue-400 text-white text-lg rounded-xl w-7' : 'text-xl text-gray-500'}>
                    {data}
                </button>
      ))}
    </div>
  )
}

export default Pagination