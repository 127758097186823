import { SCRequests } from "../../../utils/SCRequests";

//todo: maybe get from local storage if it already exists?
export class ChiefComplaint extends SCRequests {

    async get(data: string) {
        const response = await this.fetchRequest('/sub_path/path?path_id=' + data);

        return response.data;
    }
}
