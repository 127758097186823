import { SCRequests } from "../../../utils/SCRequests"
import { IQa } from "../../interface/symptom_checker/IQa"
import { ICaseHistory } from "../../interface/symptom_checker/ICaseHistory"
import { endpoints } from "../Endpoint";

//todo: maybe get from local storage if it already exists?
export class LinearPath extends SCRequests {

    async initialize(data: any, request : any) {

        const endpoint = endpoints(request, "GET", false, data.path_id + "&session_id=" + data.session_id);
        //const response = await this.fetchRequest('/inquiry/init_linear?path_id=' + data.path_id + "&session_id=" + data.session_id);
        const response = await this.fetchRequests(endpoint, request);
        return response;
    }

    async submit(params: any, request : any) {
        const data: IQa = {
            questions : params.questions,
            answers : params.answers,
            sub_path_id: params.sub_path_id,
            session_id: params.session_id
        }

        //const response: any = await this.postRequest('/inquiry/submit_linear', data);

        const endpoint = endpoints(request, "POST", false, null);
        const response = await this.postRequests(endpoint, data, request);

        return response;
    }

    async updateCaseHistory(params: any, request : any) {
        const data: ICaseHistory = {
            session_id: params.session_id,
            case_consultation_id: params.case_consultation_id
        }

        //const response: any = await this.postRequest('/inquiry/update_history_case', data);

        const endpoint = endpoints(request, "POST", false, null);
        const response = await this.postRequests(endpoint, data, request);

        return response;
    }
}
