import { requests } from "../Api";
import { endpoints } from "../Endpoint";

export class Details extends requests {

    async getUserDetails(userId : any, request : any) {

        var endpoint = endpoints(request, "GET", true, userId);
    
        const response = await this.fetchRequest(endpoint, request)
        return response;
    }

}
