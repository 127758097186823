import React from 'react'

interface Props {
    defaultText?: string,
    data: dataArray[]
    onSelect: (value: string) => void
}

interface dataArray {
    id: string,
    name: string
}

const SCSelect = ({ defaultText,
    data,
    onSelect
    } : Props) => {
    return (
        <select className="border h-9 w-full mt-3 border-b-4 border-b-green-300"
        onChange={(e) => {onSelect(e.target.value)}}  defaultValue={""}>
            {
                defaultText != null ?
                     <option className='text-gray-500' value="" disabled>{defaultText}</option>
                : null
            }
            {
                data.length > 0 ?
                    data.map((item, index) => {
                      return <option value={item.id} key={item.id}>{item.name}</option>
                    })
                : null
            }
        </select>
    )
}

export default SCSelect
