import { IEmailRequest } from "../../interface/IEmail";
import { requests } from "../Api";
import { endpoints } from "../Endpoint";

const USER_UPDATE_EMAILS_ENDPOINT = process.env.REACT_APP_RDP_API_USER_UPDATE_EMAILS_ENDPOINT as string

export class Email extends requests {
    //token = JSON.parse(localStorage.getItem('api_token') ?? '')

    async updateEmail({ alternate_email, email, email_options }: IEmailRequest, request : any) {

        const endpoint = endpoints(request, "POST", true, null);

        let data: IEmailRequest = {
            alternate_email: alternate_email,
            email: email,
            email_options: email_options
        }

        //let response = await this.postRequest(USER_UPDATE_EMAILS_ENDPOINT, emails, this.token);
        let response = await this.postRequest(endpoint, data, request);
        return response;
    }
}