import React, { useEffect, useState, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import homeGreen from '../assets/Home-green.png'
import appointmentGreen from '../assets/Appointments- green.png'
import shopGreen from '../assets/Shop Cart- green.png'
import accountGreen from '../assets/User- green.png'

import homeWhite from '../assets/Home-white.png'
import appointmentWhite from '../assets/Appointments- white.png'
import shopWhite from '../assets/Shop Cart- white.png'
import accountWhite from '../assets/User- white.png'

interface Props {
    isSidebarCollapsed: boolean
    setIsSidebarCollapsed: (value: boolean) => void,
}

const Sidebar = ({
    isSidebarCollapsed,
    setIsSidebarCollapsed
} : Props) => {

  const navigate = useNavigate();
  const location = useLocation()

  const [selectedMenu, setSelectedMenu] = useState('dashboard')

  useEffect(() => {
    tabletResize()
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getSize = () => {
      return {
        width: window.innerWidth,
        height: window.innerHeight,
      };
  };

  const tabletResize = () => {
      var currentSize = getSize()
      console.log(currentSize.width)
      if(currentSize.width <= 1024 && currentSize.width >= 481) {
          setIsSidebarCollapsed(true)
      }
      console.log('tabletTest')
      console.log(isSidebarCollapsed)
  }

  const isTablet = () => {
      var currentSize = getSize()
      console.log(currentSize.width)
      console.log(isSidebarCollapsed)
      if(currentSize.width <= 1024 && currentSize.width >= 481) {
          return true
      } else {
          return false
      }
  }

  const handleResize = useCallback(() => {
    let ticking = false;
    if (!ticking) {
      window.requestAnimationFrame(() => {
        if(isTablet()) {
            setIsSidebarCollapsed(true)
        } else {
            setIsSidebarCollapsed(false)
        }
        ticking = false;
      });
      ticking = true;
    }
  }, []);

  if (location.pathname === '/') return null
  if (location.pathname === '/login') return null
  if (location.pathname === '/forgot-password') return null
  if (location.pathname === '/registration') return null
  if (location.pathname === '/otp') return null
  if (location.pathname === '/verification') return null
  if (location.pathname === '/code-verification') return null
  if (location.pathname === '/update-password') return null
  if (location.pathname === '/apple/auth') return null

  const handleSelectMenuRequest = (menu : any) => {
      setSelectedMenu(menu)
      navigate(`/${menu}`)
  }

//todo need to check the location and then change the height to full or auto
  return (
    <div className={isSidebarCollapsed ? `bg-[#1665CE] w-[75px] transition-[width] self-stretch flex justify-center ${location.pathname === 'emails' || 'numbers' ? 'min-h-screen' : 'h-[93vh]'}` : `bg-[#1665CE] w-[300px] transition-[width]  self-stretch ${location.pathname === 'emails' || 'numbers' ? 'min-h-screen' : 'h-[93vh]' }`}>
        <div className='flex flex-col space-y-5 absolute top-[7rem]'>
          <div className='sidebar' onClick={() => handleSelectMenuRequest('dashboard')} id='dashboard'>
            {selectedMenu === 'dashboard' ? <img src={homeGreen} className='sidebar-icons'/> : <img src={homeWhite} className='sidebar-icons'/>}
            {isSidebarCollapsed === false ?
                selectedMenu === 'dashboard' ? <p className="text-[#40ECB8]">Home</p>
                : <p>Home</p>
            : null}
          </div>

          <div className='sidebar' onClick={() => handleSelectMenuRequest('appointments')} id='appointments'>
            {selectedMenu === 'appointments' ? <img src={appointmentGreen} className='sidebar-icons'/> : <img src={appointmentWhite} className='sidebar-icons'/> }
            {isSidebarCollapsed === false ?
                selectedMenu === 'appointments' ? <p className="text-[#40ECB8]">Appointments</p>
                : <p>Appointments</p>
            : null}
          </div>

          <div className='sidebar' onClick={() => handleSelectMenuRequest('shop')} id='shop'>
            {selectedMenu === 'shop' ? <img src={shopGreen} className='sidebar-icons'/> : <img src={shopWhite} className='sidebar-icons'/> }
            {isSidebarCollapsed === false ?
                selectedMenu === 'shop' ? <p className="text-[#40ECB8]">Shop</p>
                : <p>Shop</p>
            : null}
          </div>

          <div className='sidebar' onClick={() => handleSelectMenuRequest('account')} id='account'>
          {selectedMenu === 'account' ? <img src={accountGreen} className='sidebar-icons'/> : <img src={accountWhite} className='sidebar-icons'/> }
            {isSidebarCollapsed === false ?
                selectedMenu === 'account' ? <p className="text-[#40ECB8]">Account</p>
                : <p>Account</p>
            : null}
          </div>

        </div>
  </div>
  )
}

export default Sidebar
