import { ICodeRequest } from "../../interface/ICode";
import { IOTP } from "../../interface/IOTP";
import { requests } from "../Api";
import { endpoints } from "../Endpoint";

const REACT_APP_RDP_API_CODE_VALIDATION_ENDPOINT = process.env.REACT_APP_RDP_API_CODE_VALIDATION_ENDPOINT as string


export class CodeValidation extends requests {

    //token = JSON.parse(localStorage.getItem('api_token') ?? '')

    async validateCode(code : any, request : any): Promise<any> {

        const endpoint = endpoints(request, "POST", false, null);

        const data: ICodeRequest = {
            password_reset_code: code,
        }

        //const response = await this.postRequest(REACT_APP_RDP_API_CODE_VALIDATION_ENDPOINT, data);
        
        const response = await this.postRequest(endpoint, data, request);
        return response
       
    }

}