import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { addEmitHelper } from 'typescript'

interface Props {

    message : any,
    status : any
    closeModal: () => void
    page : any
    email : any
}

const Index = ({message, status, closeModal, page, email} : Props) => {

  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);


  const handleOnclickRequest = () => {
    closeModal()
    navigate(page)

    if(email !== ''){
      return navigate(page, {state : {email : email}})
      //localStorage.setItem("email", JSON.stringify(email))
    }
  }


  // Close modal if a click happens outside the modal
  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <div className='flex items-center pl-[35%] fixed inset-0 z-10 overflow-y-auto bg-gray-600 bg-opacity-70 h-full w-full'>
      <div className='flex items-center justify-center px-4 py-8 w-2/3 h-1/3'>
        <div className='w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg shadow-gray-700'>
          <div ref={modalRef}>
            <div className='flex items-center justify-center mt-3'>
              <div className='flex flex-col justify-center items-center'>
                {/**modal content */}

                <div className='text-blue-600 text-xl text-center whitespace-pre-line'>{message}</div>

                <button className='h-10 w-64 bg-custom-blue text-white rounded-xl hover:bg-custom-blue-dark mt-8 mb-3' onClick={handleOnclickRequest}>Continue</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  )
}

export default Index
