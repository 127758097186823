import React, { useState } from 'react'
import backgroundImage from '../../assets/home-banner.png'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CustomButton from '../buttons/CustomButton'
import { User } from '../../classes/model/User'
import  Modal  from '../modals/Index'
import CustomCard from '../container/CustomCard'
import Spinner from '../spinner/Spinner'
import useEnterKey from '../../classes/EnterFunction'

const ForgotPassword = () => {
    const user = new User()

    const [email, setEmail] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [response, setResponse] = useState('')
    const [message, setMessage] = useState<any>('')
    const [isShowAnimation, setIsShowAnimation] = useState(false)
    const [page, setPage] = useState('')

    const handleForgotPasswordRequest = async () => {
      setIsShowAnimation(true)
       if(!validateEmail()){
        console.log('valid')
        const response: any = await user.forgotPassword({
          email: email,
          source: 'Web',
        }, "forgotPassword_endpoint")

        console.log('forgot password', response)

        if (response.message === 'success') {
            localStorage.setItem('email', JSON.stringify(email))
            console.log('success')
            setPage('/code-verification')
            setMessage(response.data)
            setResponse(response)
            setShowModal(true)

          } else {
            setMessage(response.message)
            setShowModal(true)
          }
       }else{
        setMessage('Email is required')
        setShowModal(true)
       }

      setIsShowAnimation(false)

    }


    const validateEmail = (): boolean => {
      return email.trim() === '';
    };
    
    useEnterKey(() => handleForgotPasswordRequest());

  return (
    <>
    <div className='h-screen flex flex-col bg-cover bg-center w-full' style={{backgroundImage: `url(${backgroundImage})`}}>
      <CustomCard className='flex flex-col items-center mt-[10%] ml-[20%] w-[25%] bg-white rounded-3xl pb-5'>
        <p className='text-4xl text-blue-500 font-bold mt-[7%]'>Forgot Password</p>

        <div className='flex flex-col space-y-7 w-[80%] mt-[12%]'>
            <input className='input_design pl-2' placeholder='Email' onChange={(e) => setEmail(e.target.value)} onKeyDown={(e) => { if (e.key === "Enter") { handleForgotPasswordRequest(); } }}/>
            <p>Enter your registered email and we will send you instructions to get you back on your feet.</p>
        </div>

       {isShowAnimation ? <Spinner /> :
         <div className='flex flex-row items-center justify-center space-x-5 mt-10 w-[80%]'>
            <button className={`${email ? 'rounded-2xl bg-blue-600 h-12 w-full text-white font-semibold hover:bg-blue-700' : 'rounded-2xl bg-gray-200 h-12 w-full text-gray-400 font-semibold cursor-not-allowed'}`} onClick={() => email ? handleForgotPasswordRequest() : null}>Reset Password</button>
        </div>}

      </CustomCard>
      <div className='bg-blue-600 h-16 w-full mt-auto'></div>
    </div>

    {showModal ? <Modal message={message} status={undefined} closeModal={() => setShowModal(false)} page={page} email={email}/>  : null}
    </>
  )
}

export default ForgotPassword
