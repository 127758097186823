import { IOTP } from "../interface/IOTP";
import { IAssetsRequest } from "../interface/IAssetsRequest";
import { endpoints } from "./Endpoint";
import { requests } from "./Api";

const HOTLINES_ENDPOINT = process.env.REACT_APP_RDP_API_GET_HOTLINES_ENDPOINT as string
const OTP_VALIDATION_ENDPOINT = process.env.REACT_APP_RDP_API_VALIDATE_OTP_ENDPOINT as string
const ASSETS_ENDPOINT = process.env.REACT_APP_RDP_ASSETS_SOCIAL_LOGIN as string
const BASE_URL = process.env.REACT_APP_RDP_API_BASE_URL as string


export class Others extends requests {

    token = '';

    constructor () {
        super();
        if (localStorage.getItem('api_token')  != null) {
            this.token = JSON.parse(localStorage.getItem('api_token') ?? '')
        }
    }

    async getHotlines(request : any) {

        const endpoint = endpoints(request, "GET", true, null);

        let response = await this.fetchRequest(endpoint, request)

        return response;
    }

    async verifyOTP(otp : any, request : any){
        const endpoint = endpoints(request, "POST", true, null);

        const data : IOTP = {
            otp : otp,
            type : "web"
        }

        console.log('verifyOTP', data)

        let response = await this.postRequest(endpoint, data, request)
        return response;
    }

    async getAssets ( {category, full_data}: IAssetsRequest, request : any ) {

        const endpoint = endpoints(request, "POST", false, category);
        
        //let url = `${BASE_URL}${ASSETS_ENDPOINT}?category=${category}&full_data=${full_data}`
        //let response = await this.getRequestWithParams(url, [])


        let response = await this.fetchRequest(endpoint, request)
        return response;
    }

    async downloadPdf(path  : string, request : any){

        const endpoint = endpoints(request, "GET", true, path);

        //let url = `${path}`

        let response = await this.fetchRequest(endpoint, request);
        return response;

    }

    async forceDownload(filename : string, request : any){

        const endpoint = endpoints(request, "GET", true, filename);
      

        //let url = `${path}`

        let response = await this.fetchRequest(endpoint, request);
        return response;

    }

}