import React, { useEffect, useState } from 'react'
import backgroundImage from '../../assets/home-banner.png'
import Password from './Password'
import { User } from '../../classes/model/User'
import { useLocation, useNavigate } from 'react-router-dom'
import Index from '../modals/Index'
import ChangePasswordModal from '../modals/ChangePasswordModal'
import Spinner from '../spinner/Spinner'
import useEnterKey from '../../classes/EnterFunction'

interface Props {
  isComplete : boolean
}

const UpdatePassword = ({isComplete} : Props) => {

    const location = useLocation()
    //console.log('combine code', location?.state?.combinedCode)

    const user = new User()
    //const [email, setEmail] = useState(localStorage.getItem('email') ?? '')
    const [email, setEmail] = useState('')
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [isModalShow, setIsModalShow] = useState(false)
    const [status, setStatus] = useState(false)
    const [isShowAnimation, setIsShowAnimation] = useState(false)
    const [message, setMessage] = useState<any>('')
    const [code, setCode] = useState<any>(location?.state?.combinedCode)


    const navigate = useNavigate();

    useEffect(() => {
        
      if(!isComplete){
          
          navigate('/code-verification')
      }
      
    }, [])

    const handleUpdatePasswordRequest = async () => {

      console.log("on enter called")

      setIsShowAnimation(true)

      const response: any = await user.resetPassword({
          email: email.replace(/"/g, ''),
          new_password: newPassword,
          password_reset_code: code,
          new_password_confirmation: confirmNewPassword,
      }, "changePassword_endpoint")

      if(response.message === 'success'){

        setMessage(response.data)
        setStatus(true)
        setIsModalShow(true)

      }else{
        setMessage(response.message)
        setIsModalShow(true)
      }

      console.log(response)
      setIsShowAnimation(false)

    }

    //useEnterKey(() => handleUpdatePasswordRequest());

  return (
    <>
    <div className='h-screen flex flex-col bg-cover bg-center w-full' style={{backgroundImage: `url(${backgroundImage})`}}>
      
      <div className='flex flex-col mt-[10%] ml-[20%] w-[30%] h-[55%] space-x-2 bg-white rounded-xl'>

            <div className='flex flex-col items-center ml-[7%] mt-[5%] w-[80%]'>
                <p className=' text-blue-600 text-2xl font-bold mb-[5%]'>Forgot Password</p>
                <Password setCurrentPassword={setCurrentPassword} setNewPassword={setNewPassword} setConfirmNewPassword={setConfirmNewPassword} isEmail={true} setEmail={setEmail} onEnter={handleUpdatePasswordRequest}/>
            </div>
    
            {isShowAnimation ? <div className='flex justify-center items-center'><Spinner /></div> :
              <div className='flex flex-row mt-[5%] justify-center space-x-5'>
                  {/**<button className='h-10 w-[30%] rounded-xl bg-blue-600 text-white hover:bg-blue-700' onClick={handleUpdatePasswordRequest}>Submit</button> */}
                  <button className={`${newPassword && confirmNewPassword ? 'h-10 w-[30%] rounded-xl bg-blue-600 text-white hover:bg-blue-700' : 'rounded-xl bg-gray-200 h-12 w-40 text-gray-400 font-semibold cursor-not-allowed'}`} onClick={handleUpdatePasswordRequest}>Submit</button>
                  <button className='h-12 w-[30%] rounded-xl bg-blue-200 text-blue-600 hover:bg-blue-300' onClick={() => navigate('/login')}>Cancel</button>
              </div>
            }
        </div>
        <div className='bg-blue-600 h-16 w-full mt-auto'></div>
        {isModalShow ? <ChangePasswordModal closeModal={() => setIsModalShow(false)} page='/login' status={status} isForgot={true} message={message}/> : null}
    </div>
    </>
  )
}

export default UpdatePassword