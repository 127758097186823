import React, { useEffect, useRef, useState } from 'react'
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.min.mjs',
//     import.meta.url,
//   ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.mjs';

const fileUrl = `${window.location.origin}/image_req_infographic.pdf`

interface Props {
  closeModal: () => void
}

const PictureGuidelines = ({closeModal} : Props) => {

  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);

  const modalRef = useRef<HTMLDivElement>(null);


  const nextPage = () => {
      setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages ?? 1));
  };

  const prevPage = () => {
      setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1));
  };


  const handleLoadError = (error: Error) => {
      console.error('Error loading PDF:', error.message);
      // display error message to the user here
  };

  const onLoadSuccess = ({ numPages }: { numPages: number }) => {
      setNumPages(numPages);
    };

      // Close modal if a click happens outside the modal
  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  return (

      <div className='flex items-center justify-center fixed inset-0 z-10 overflow-y-auto bg-gray-600 bg-opacity-50 h-full w-full'>
        <div className='flex justify-center items-center py-8 px-5 mt-[10%]'>
          <div className='relative p-4 bg-white shadow-lg shadow-gray-700 rounded-xl w-[100%] h-max'>
          <div ref={modalRef}>

            <div className='flex flex-col items-center justify-center w-full h-full'>
            <p className='ml-auto mr-[5%] px-2 pt-1 cursor-pointer text-white bg-gray-400 w-7 h-7 rounded-full font-semibold' onClick={closeModal}>X</p>


             <Document  file={fileUrl} onLoadSuccess={onLoadSuccess} onLoadError={handleLoadError}>
                    <Page pageNumber={pageNumber} />
                </Document>

              <div className='flex flex-row mt-5 w-[50%] justify-center'>
                  <button  className='border-2 bg-custom-blue h-10 w-20 cursor-pointer text-white rounded-xl mr-5' onClick={prevPage} disabled={pageNumber <= 1}>Previous</button>
                    <span className='mt-2'>Page {pageNumber} of {numPages}</span>
                    {pageNumber === numPages ? null : <button className='border-2 bg-custom-blue h-10 w-20 cursor-pointer text-white rounded-xl ml-5' onClick={nextPage} disabled={pageNumber >= (numPages ?? 1)}>Next</button> }
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PictureGuidelines
