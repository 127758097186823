import { ICallbackRequest, ICaseDetails } from "../../interface/ICallbackRequest";
import { requests } from "../Api";
import { endpoints } from "../Endpoint";

const ELIGIBILITY_HEALTH_CONCERNS_ENDPOINT = process.env.REACT_APP_RDP_API_ELIGIBILITY_HEALTH_CONCERNS_ENDPOINT as string;
const ELIGIBILITY_CASE_ENDPOINT = process.env.REACT_APP_RDP_API_ELIGIBILITY_CASE_ENDPOINT as string;
const CASE_ENDPOINT = process.env.REACT_APP_RDP_API_CASE_ENDPOINT as string;
const CASE_LIST_ENDPOINT = process.env.REACT_APP_RDP_API_CASE_LIST_ENDPOINT as string;
const REACT_APP_RDP_API_CASE_DETAILS_ENDPOINT = process.env.REACT_APP_RDP_API_CASE_DETAILS_ENDPOINT as string;
const REACT_APP_RDP_API_ELIGIBILITY_CASE_LIST_ENDPOINT = process.env.REACT_APP_RDP_API_ELIGIBILITY_CASE_LIST_ENDPOINT as string
const REACT_APP_RDP_API_UPLOAD_IMAGE_ENDPOINT = process.env.REACT_APP_RDP_API_UPLOAD_IMAGE_ENDPOINT as string

export class Appointment extends requests {
    //token = JSON.parse(localStorage.getItem('api_token') || '')

    async getConcerns(request : any) {

        const endpoint = endpoints(request, "GET", true, null);
        console.log('endpoint', endpoint)

        let response = await this.fetchRequest(endpoint, request)

        console.log(response)
        return response;
    }

    async setAppointment(data : FormData, request : any) {

        //console.log('get form data image 222 setAppointment', data?.get("file[]"))

        const endpoint = endpoints(request, "POST", true, null);
        console.log('endpoint', endpoint)

        //const response = await this.postRequest(ELIGIBILITY_CASE_ENDPOINT, data, this.token)
        const response = await this.postRequest(endpoint, data, request)
        return response
    }


    async getAppointmentList(request : any){
        
        const endpoint = endpoints(request, "GET", true, null);
        console.log('endpoint', endpoint)

        let response = await this.fetchRequest(endpoint, request)

        return response
    }

    /*async getPastAppointment() {

        let response = await this.fetchRequest(`${CASE_LIST_ENDPOINT}`, this.token)

        return response;
    }*/

    async getCaseDetails({caseId} : ICaseDetails, request : any) {

        //let response = await this.fetchRequest(`${REACT_APP_RDP_API_CASE_DETAILS_ENDPOINT}${caseId}`, this.token)
        const endpoint = endpoints(request, "GET", true, caseId);
        console.log('endpoint', endpoint)

        let response = await this.fetchRequest(endpoint, request)
        return response;
    }

    async uploadImage(data : FormData, request : any){

        const endpoint = endpoints(request, "POST", true, null);
        console.log('endpoint', endpoint)

        /*console.log('REACT_APP_RDP_API_UPLOAD_IMAGE_ENDPOINT', REACT_APP_RDP_API_UPLOAD_IMAGE_ENDPOINT)
        const response = await this.postRequest(REACT_APP_RDP_API_UPLOAD_IMAGE_ENDPOINT, data, this.token)*/

        const response = await this.postRequest(endpoint, data, request)
        return response;

    }
}
