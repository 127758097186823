import React, { useState } from 'react'
import { getParsedDataFromLocalStorage } from '../../classes/DataParser'
import { useNavigate } from 'react-router-dom'
import Index from '../modals/Index'

interface Props {

    stage : number
    request : any
    coc : boolean
}

const ProgressBar = ({stage, request, coc} : Props) => {

  const navigate = useNavigate();

  const type = getParsedDataFromLocalStorage('callbackRequest_type');
  const concern = getParsedDataFromLocalStorage('callbackRequest_concern');
  const isOthers = getParsedDataFromLocalStorage('isOthers')
  const isCheckboxChecked = getParsedDataFromLocalStorage('callbackRequest_checkbox')


  const [errorMessage, setErrorMessage] = useState('');
  const [isShowModal, setIsShowModal] = useState(false)




  const handleButtonRequest = (page : any) => {

    console.log('stage', stage)
    console.log('page', page)

      if(stage === 1){

        if(page == 2){

          if(type !== null){
            navigate('/concern')
          }else{
            setErrorMessage('Method is required.')
            setIsShowModal(true)
          }
        }

        if(page == 3){
          if(concern !== null){
            navigate('/set-appointment')
          }else{
            setErrorMessage('Health Concern and confirmation of information is required.')
            setIsShowModal(true)
          }
        }
      }

      if(stage === 2){

        if(page === 1){
            navigate('/type')
        }

        if(page == 3){

          console.log("isCheckboxChecked", isCheckboxChecked)

          if(isCheckboxChecked === null || (!isCheckboxChecked)){

              console.log('empty or false checkbox')
              setErrorMessage("Confirmation of information is required")
              return setIsShowModal(true)
          }

          if(concern !== null){
            console.log('empty or false checkbox')

            if(isOthers){
              localStorage.setItem("callbackRequest_concern", JSON.stringify(request))
            }
            navigate('/set-appointment')
          }else{
            setErrorMessage('Health Concern and confirmation of information is required.')
            setIsShowModal(true)
          }
        }
      }

      if(stage === 3){

        if(page === 1){
          navigate('/type')
      }

      if(page == 2){
        navigate('/concern')
      }
    }
  }


  return (
    <div className="relative flex items-center justify-center w-full mb-[5%]">
    {/* Long horizontal line */}
    <div className="relative w-full flex items-center">
      <div className="absolute inset-0 flex h-[.25rem]">
        {stage === 2 || stage === 3 ? <div className="border-2 bg-[#40ECB8] border-t-2 border-[#40ECB8] ml-[3rem] w-[45%]"></div> : <div className="border-2 border-t-2 bg-[#3b82f6] border-blue-500 ml-[3rem] w-[45%]"></div>}
        {stage === 3 ? <div className="border-2 border-t-2 bg-[#40ECB8] border-[#40ECB8] mr-[4rem] lg:mr-[4rem] md:mr-[3.5rem] w-[42%]"></div> : <div className="border-2 bg-[#3b82f6] border-t-2 border-blue-500 mr-[4rem] lg:mr-[4rem] md:mr-[3.5rem] lg:w-[42%] md:w-[35%]"></div> }
      </div>

      {/* Circles with text */}
      <div className="flex justify-between w-[98%]">
        {/* Step 1 */}
        <div className="flex flex-col">
          <div className="relative flex">
            <div className={`ml-[2.9rem] absolute top-1/2 transform -translate-y-1/2 rounded-full ${stage >= 1 ? 'bg-[#40ECB8]' : 'bg-blue-600'} h-6 w-6 z-10 cursor-pointer`} onClick={() => handleButtonRequest(1)}></div>
          </div>
          <p className="w-[70%] mt-3 text-center text-blue-600">Choose your <span className="ml-2">platform</span></p>
        </div>

        {/* Step 2 */}
        <div className="flex flex-col items-center" id='stepTwo'>
          <div className="relative flex items-center w-[1rem]">
            <div className={`absolute top-1/2 transform -translate-y-1/2 rounded-full ${stage >= 2 ? 'bg-[#40ECB8]' : 'bg-blue-500'} h-6 w-6 z-10 cursor-pointer`} onClick={() => handleButtonRequest(2)}></div>
          </div>
          <p className="w-[70%] mt-3 text-center text-blue-500">Choose your <span className="ml-2">concern</span></p>
        </div>

        {/* Step 3 */}
        <div className="flex flex-col" id='stepThree'>
          <div className="relative flex">
            <div className={`absolute self-end ml-[6rem] top-1/2 transform -translate-y-1/2 rounded-full ${stage === 3? 'bg-[#40ECB8]' : 'bg-blue-500'} h-6 w-6 z-10 cursor-pointer`} onClick={() => handleButtonRequest(3)}></div>
          </div>
          <p className="w-[70%] self-end mt-3 text-center text-blue-500">Choose your schedule</p>
        </div>
      </div>
    </div>
    {isShowModal ? <Index message={errorMessage} status={undefined} closeModal={() => setIsShowModal(false)} page={undefined} email={undefined} /> : null}
  </div>

  )
}

export default ProgressBar
