import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import callback_icon from '../assets/Callback.png'
import callback_icon_white from '../assets/Call_ Triage- white.png'
import call_icon from '../assets/Call_ Triage- green.png'
import symptomChecker_icon from '../assets/Symptom Checker.png'
import chat_icon from '../assets/Chat copy.png'
import Hotline from './modals/Hotline'
import { OtherRequest } from '../classes/model/OtherRequest'
import { IHotlineResponse } from '../classes/interface/IHotlines'

import StandardContainer from './container/StandardContainer'
import CustomButton from './buttons/CustomButton'
import CustomCard from './container/CustomCard'
import { Case } from '../classes/model/Case'
import Index from './modals/Index'
import { deleteAllFiles } from './appointment/db'

const Dashboard = () => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false)
  const [hotlines, setHotlines] = useState<any>()
  const [message, setMessage] = useState<any>('');
  const [showModal, setShowModal] = useState(false)
  const [isShowAnimationDots, setIsShowAnimationDots] = useState(false)
  const caseRequest = new Case()
  const others = new OtherRequest()

  const user = JSON.parse(localStorage.getItem('user') || '{}')

  useEffect(() => {
     const getHotlineRequest = async () => {

      const response: IHotlineResponse = await others.getHotlines('getHotlines_endpoint')

       setHotlines(response)
     }

    getHotlineRequest()
  }, [])

  const removeCallbackLocalStorage = async () => {
    localStorage.removeItem("isOthers");
    localStorage.removeItem("callbackRequest_callDate");
    localStorage.removeItem("callbackRequest_callTime");
    localStorage.removeItem("callbackRequest_concern");
    localStorage.removeItem("callbackRequest_type");
    localStorage.removeItem("callbackRequest_callSection");
    localStorage.removeItem("callbackRequest_checkbox");
    await deleteAllFiles();
  }

  const handleCallbackRequest = async () => {
    removeCallbackLocalStorage();
    setIsShowAnimationDots(true)

    const response = await caseRequest.getAppointmentList('getAppointment_list_endpoint');

    if(response.length !== 0){

     const result =  response?.data?.filter((record: { status: string }) => ('new' === record.status || 'ongoing' === record.status))
     console.log('result', result)

      if(result.length !== 0){
          setMessage('You still have an active consultation on queue. One of our agents will get in touch with you soon. Please be patient')
          setShowModal(true)
      }else{
        navigate('/type')
      }
    }
    setIsShowAnimationDots(false)

  }

  const handleMouseEnter = () => {
    setIsHovered(true);
    setIsModalOpen(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setTimeout(() => {
      if (!isHovered) {
        setIsModalOpen(false); // Only close if not hovering back
      }
    }, 200); // Delay for smoother transitions
  };

  const handleSymptomChecker = async () => {
    //setIsShowAnimationDots(true)

    const response = await caseRequest.getAppointmentList('getAppointment_list_endpoint');

    if(response.length !== 0){

     const result =  response?.data?.filter((record: { status: string }) => ('new' === record.status || 'ongoing' === record.status))
     console.log('result', result)

      if(result.length !== 0){
          setMessage('You still have an active consultation on queue. One of our agents will get in touch with you soon. Please be patient')
          setShowModal(true)
      }else{
        navigate('/symptom')
      }
    }
    //setIsShowAnimationDots(false)

  }

  return (
      <>
    <StandardContainer>
      {/*<CustomButton
        title='Go to Temp VoIP Page'
        style='rounded-full bg-blue-500 h-12 w-40 text-white font-semibold hover:bg-blue-600'
        handleOnclick={() => navigate('/tempvoippage')}
      />*/}

      <p className='text-left text-3xl text-blue-500 font-bold min-w-[43rem] md:min-w-[83%] lg:min-w-[53rem] sm:min-w-[27rem] w-[50%]'>
        Hi {user?.patient?.first_name}! How can we help you today?
      </p>

      <p className='text-left text-lg text-blue-500 font-semibold min-w-[43rem] md:min-w-[83%] lg:min-w-[53rem] sm:min-w-[27rem] w-[50%] mb-10'>
        We are here to provide confidential and innovative healthcare.
      </p>

      <div className='grid md:grid-cols-2 sm:grid-cols-1 gap-8 mt-5 min-w-[45rem] md:min-w-[85%] lg:min-w-[55rem] sm:min-w-[30rem] w-[50%] h-[35%]'>

        <CustomCard className='dashboard-grid justify-center mx-5 min-h-[11rem]' onClick={handleCallbackRequest}> {/**() => navigate('/type') */}
          <div className='flex flex-column items-center ml-3'>
            <img src={callback_icon} className='flex h-24 w-24 mr-2' />
            <div className='flex flex-col ml-3 text-left'>
                <div className='flex flex-row items-center'>
                  <p className='dashboard-grid-text'>Callback</p>
                    { isShowAnimationDots ?
                      <div className='flex flex-row ml-3 mt-2 space-x-1'>
                        <div className='h-2 w-2 bg-blue-500 rounded-full animate-bounce [animation-delay:-0.3s]'></div>
                        <div className='h-2 w-2 bg-blue-500 rounded-full animate-bounce [animation-delay:-0.15s]'></div>
                        <div className='h-2 w-2 bg-blue-500 rounded-full animate-bounce'></div>
                      </div>
                      : null
                    }
                </div>
                <p className='text-blue-500 text-lg'>
                Schedule a triage with a Telemedical Assistant.
                </p>
            </div>
          </div>
        </CustomCard>

        <CustomCard
          className={`flex flex-col border border-gray-200 shadow-md rounded-xl w-full h-full cursor-pointer mx-5 justify-center min-h-[11rem] ${isHovered ? 'text-white bg-custom-dashboard-menu-bg' : 'text-blue-500 bg-gray-50'}`}
          onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>

          <div className={`flex flex-column items-center ml-3`}>
            {isHovered ? <img src={callback_icon_white} className='h-24 w-24 mr-2' /> : <img src={call_icon} className='h-24 w-24 mr-2' />}
            <div className='flex flex-col text-left'>
                <p className='lg:text-3xl font-semibold md:text-xl'>Call</p>
                <p className='text-lg mr-3'>
                  Talk to a Telemedical Assistant.<br/>&nbsp;
                </p>
            </div>
          </div>
        </CustomCard>

        <CustomCard
          className='dashboard-grid mx-5 justify-center'
          onClick={() => handleSymptomChecker()}>
          <div className='flex flex-column items-center ml-3'>
            <img src={symptomChecker_icon} className='h-24 w-24 mr-2' />
            <div className='flex flex-col text-left'>
                <p className='dashboard-grid-text'>Symptom Checker</p>
                <p className='text-blue-500 text-lg'>Perform self triage</p>
            </div>
          </div>
        </CustomCard>

        <CustomCard className='dashboard-grid mx-5 justify-center min-h-[11rem]' onClick={() => navigate('/chat')}>
          <div className='flex flex-column items-center ml-3'>
            <img src={chat_icon} className='h-24 w-24 mr-2' />
            <div className='flex flex-col text-left'>
                <p className='dashboard-grid-text'>Chat</p>
                <p className='text-blue-500 text-lg mr-3'>
                  Chat with a Telemedical Assistant.
                </p>
            </div>
          </div>
        </CustomCard>

      </div>

    </StandardContainer>

        {isHovered ? <Hotline hotlines={hotlines} closeModal={handleMouseLeave} onMouseEnter={handleMouseEnter}/> : null}
        {showModal ? <Index message={message} status={undefined} closeModal={() => setShowModal(false)} page='' email=''/> : null}

    </>
  )
}

export default Dashboard
