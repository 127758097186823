import Dexie from 'dexie';

interface FileData {
    id?: number; // id is auto-incremented
    fileName: string;
    fileData: Blob;
  }
  
  // Set up the Dexie database class
  class FileDatabase extends Dexie {
    filesTable: Dexie.Table<FileData, number>; // Define the table schema
  
    constructor() {
      super('FileDatabase'); // Set database name
  
      // Version and schema definition
      this.version(1).stores({
        filesTable: '++id, fileName, fileData', // id is auto-incremented
      });
  
      this.filesTable = this.table('filesTable');
    }
  }

  export const getAllFiles = async () => {
    try {
      const files = await db.filesTable.toArray();
      return files;
    } catch (error) {
      console.error('Error retrieving files:', error);
      return [];
    }
  };

  export const deleteAllFiles = async () => {
    try {
      await db.filesTable.clear(); // Clear all records from the 'filesTable'
      console.log('All files have been deleted.');
    } catch (error) {
      console.error('Error deleting files:', error);
    }
  };

  export const deleteFileByFileName = async (fileName: string) => {
    try {
      // Delete the file from the database
      await db.filesTable.where('fileName').equals(fileName).delete();
      console.log(`File with name ${fileName} deleted`);
     
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };
  
  const db = new FileDatabase(); // Instantiate the DB class
  export default db;